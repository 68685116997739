<template>
	<div class="row access-groups">
		<div class="col">

			<!-- top -->
			<div class="row section-top">
				<div class="col-md-12 pad0">
					<p class="section-lead">Preencha os campos abaixo</p>
					<a href="#" @click="saveData()" class="btn btn-primary float-right">SALVAR</a>
					<a href="#" @click="type = 'menu'" class="btn btn-secondary float-right" style="margin-right: 15px;" v-if="type != 'menu'">VER MENU</a>
				</div>
			</div>
			<!-- end top -->

			<!-- name -->
			<div class="card">
				<div class="card-body">
					<div class="form-group">
						<label>Nome do Grupo de Acesso</label>
						<input class="form-control" v-model="name" id="name"/>
					</div>
				</div>
			</div>
			<!-- end name -->

			<!-- menu -->
			<div class="row menu" v-if="type == 'menu'">
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-desktop"></i>
						</div>
						<div class="card-body">
							<h4>Padrão</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'default'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-building"></i>
						</div>
						<div class="card-body">
							<h4>Corporativo</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'corporate'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-cogs"></i>
						</div>
						<div class="card-body">
							<h4>Configurações</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'configurations'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="far fa-chart-bar"></i>
						</div>
						<div class="card-body">
							<h4>Relatórios</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'reports'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="far fa-bookmark"></i>
						</div>
						<div class="card-body">
							<h4>Auxiliares</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'system'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-lock"></i>
						</div>
						<div class="card-body">
							<h4>Restrito</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'admin'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
				
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-mobile-alt"></i>
						</div>
						<div class="card-body">
							<h4>Aplicativo</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'app'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
			</div>
			<!-- end menu -->

			<!-- itens admin -->
			<div class="row list" v-if="type == 'admin'">

				<!-- list all admin permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.admin" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all admin permissions -->

			</div>

			<!-- itens default -->
			<div class="row list" v-if="type == 'default'">

				<!-- list all default permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.default" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all default permissions -->

			</div>

			<!-- itens corporate -->
			<div class="row list" v-if="type == 'corporate'">

				<!-- list all app permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.corporate" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all app permissions -->

			</div>

			<!-- itens configurations -->
			<div class="row list" v-if="type == 'configurations'">

				<!-- list all configurations permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.configurations" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all configurations permissions -->

			</div>

			<!-- itens reports -->
			<div class="row list" v-if="type == 'reports'">

				<!-- list all reports permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.reports" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all reports permissions -->

				</div>

			<!-- itens system system -->
			<div class="row list" v-if="type == 'system'">

				<!-- list all system permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.system" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all system permissions -->

			</div>

		</div>

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ApiService from "../../apiservice.js";
	export default {
		mixins: [ApiService],
		props: ['info','role'],
		data() {
			return {
				loading: false,
				name: this.getDataRole("name"),
				permission: {
					admin: [
						{
							title: "Grupos de Acesso",
							items: [
								{
									title: "Listar",
									name: "admin-permissions-list",
									value: this.getData("admin-permissions-list")
								},{
									title: "Criar",
									name: "admin-permissions-create",
									value: this.getData("admin-permissions-create")
								},{
									title: "Editar",
									name: "admin-permissions-edit",
									value: this.getData("admin-permissions-edit")
								},{
									title: "Deletar",
									name: "admin-permissions-delete",
									value: this.getData("admin-permissions-delete")
								}
							]
						},{
							title: "Usuários",
							items: [
								{
									title: "Listar",
									name: "admin-users-list",
									value: this.getData("admin-users-list")
								},{
									title: "Criar",
									name: "admin-users-create",
									value: this.getData("admin-users-create")
								},{
									title: "Editar",
									name: "admin-users-edit",
									value: this.getData("admin-users-edit")
								},{
									title: "Deletar",
									name: "admin-users-delete",
									value: this.getData("admin-users-delete")
								},{
									title: "Desativar",
									name: "admin-users-disable",
									value: this.getData("admin-users-disable")
								},{
									title: "Resetar Senha",
									name: "admin-users-resetpassword",
									value: this.getData("admin-users-resetpassword")
								}
							]
						},{
							title: "Logs",
							items: [
								{
									title: "Log Acesso",
									name: "admin-logs-access",
									value: this.getData("admin-logs-access")
								},{
									title: "Log API",
									name: "admin-logs-api",
									value: this.getData("admin-logs-api")
								},{
									title: "Log Atividade",
									name: "admin-logs-activity",
									value: this.getData("admin-logs-activity")
								},{
									title: "Log Erro",
									name: "admin-logs-error",
									value: this.getData("admin-logs-error")
								},{
									title: "Log de Falhas",
									name: "admin-logs-failedjobs",
									value: this.getData("admin-logs-failedjobs")
								}
							]
						}
					],
					app: [],
					configurations: [
					{
							title: "Checklists",
							items: [
								{
									title: "Listar",
									name: "admin-checklists-list",
									value: this.getData("admin-checklists-list")
								},{
									title: "Criar",
									name: "admin-checklists-create",
									value: this.getData("admin-checklists-create")
								},{
									title: "Editar",
									name: "admin-checklists-edit",
									value: this.getData("admin-checklists-edit")
								},{
									title: "Deletar",
									name: "admin-checklists-delete",
									value: this.getData("admin-checklists-delete")
								}
							]
						},
						{
							title: "Notificações",
							items: [
								{
									title: "Listar",
									name: "admin-companynotifications-list",
									value: this.getData("admin-companynotifications-list")
								},{
									title: "Criar",
									name: "admin-companynotifications-create",
									value: this.getData("admin-companynotifications-create")
								},{
									title: "Editar",
									name: "admin-companynotifications-edit",
									value: this.getData("admin-companynotifications-edit")
								},{
									title: "Deletar",
									name: "admin-companynotifications-delete",
									value: this.getData("admin-companynotifications-delete")
								}
							]
						},
						{
							title: "Templates",
							items: [
								{
									title: "Listar",
									name: "admin-templates-list",
									value: this.getData("admin-templates-list")
								},{
									title: "Criar",
									name: "admin-templates-create",
									value: this.getData("admin-templates-create")
								},{
									title: "Editar",
									name: "admin-templates-edit",
									value: this.getData("admin-templates-edit")
								},{
									title: "Deletar",
									name: "admin-templates-delete",
									value: this.getData("admin-templates-delete")
								}
							]
						},
						{
							title: "Vínculo Template / Checklist",
							items: [
								{
									title: "Listar",
									name: "admin-templatechecklists-list",
									value: this.getData("admin-templatechecklists-list")
								},{
									title: "Criar",
									name: "admin-templatechecklists-create",
									value: this.getData("admin-templatechecklists-create")
								},{
									title: "Editar",
									name: "admin-templatechecklists-edit",
									value: this.getData("admin-templatechecklists-edit")
								},{
									title: "Deletar",
									name: "admin-templatechecklists-delete",
									value: this.getData("admin-templatechecklists-delete")
								}
							]
						},
						{
							title: "Vínculo Template / EPIs",
							items: [
								{
									title: "Listar",
									name: "admin-templateepis-list",
									value: this.getData("admin-templateepis-list")
								},{
									title: "Criar",
									name: "admin-templateepis-create",
									value: this.getData("admin-templateepis-create")
								},{
									title: "Editar",
									name: "admin-templateepis-edit",
									value: this.getData("admin-templateepis-edit")
								},{
									title: "Deletar",
									name: "admin-templateepis-delete",
									value: this.getData("admin-templateepis-delete")
								}
							]
						},
						{
							title: "Vínculo Template / Notificações",
							items: [
								{
									title: "Listar",
									name: "admin-templatenotifications-list",
									value: this.getData("admin-templatenotifications-list")
								},{
									title: "Criar",
									name: "admin-templatenotifications-create",
									value: this.getData("admin-templatenotifications-create")
								},{
									title: "Editar",
									name: "admin-templatenotifications-edit",
									value: this.getData("admin-templatenotifications-edit")
								},{
									title: "Deletar",
									name: "admin-templatenotifications-delete",
									value: this.getData("admin-templatenotifications-delete")
								}
							]
						},
						{
							title: "Vínculo Template / Mensagens",
							items: [
								{
									title: "Listar",
									name: "admin-templatemessages-list",
									value: this.getData("admin-templatemessages-list")
								},{
									title: "Criar",
									name: "admin-templatemessages-create",
									value: this.getData("admin-templatemessages-create")
								},{
									title: "Editar",
									name: "admin-templatemessages-edit",
									value: this.getData("admin-templatemessages-edit")
								},{
									title: "Deletar",
									name: "admin-templatemessages-delete",
									value: this.getData("admin-templatemessages-delete")
								}
							]
						},
					],
					corporate: [
						{
							title: "Empresas",
							items: [
								{
									title: "Listar",
									name: "admin-companies-list",
									value: this.getData("admin-companies-list")
								},{
									title: "Criar",
									name: "admin-companies-create",
									value: this.getData("admin-companies-create")
								},{
									title: "Editar",
									name: "admin-companies-edit",
									value: this.getData("admin-companies-edit")
								},{
									title: "Deletar",
									name: "admin-companies-delete",
									value: this.getData("admin-companies-delete")
								}
							]
						},
						{
							title: "Colaboradores",
							items: [
								{
									title: "Listar",
									name: "admin-colaborators-list",
									value: this.getData("admin-colaborators-list")
								},{
									title: "Criar",
									name: "admin-colaborators-create",
									value: this.getData("admin-colaborators-create")
								},{
									title: "Editar",
									name: "admin-colaborators-edit",
									value: this.getData("admin-colaborators-edit")
								},{
									title: "Deletar",
									name: "admin-colaborators-delete",
									value: this.getData("admin-colaborators-delete")
								}
							]
						},
						{
							title: "Contratos",
							items: [
								{
									title: "Listar",
									name: "admin-contracts-list",
									value: this.getData("admin-contracts-list")
								},{
									title: "Criar",
									name: "admin-contracts-create",
									value: this.getData("admin-contracts-create")
								},{
									title: "Editar",
									name: "admin-contracts-edit",
									value: this.getData("admin-contracts-edit")
								},{
									title: "Deletar",
									name: "admin-contracts-delete",
									value: this.getData("admin-contracts-delete")
								}
							]
						},
						{
							title: "Vínculo Empresa / Colaboradores",
							items: [
								{
									title: "Listar",
									name: "admin-companycolaborators-list",
									value: this.getData("admin-companycolaborators-list")
								},{
									title: "Criar",
									name: "admin-companycolaborators-create",
									value: this.getData("admin-companycolaborators-create")
								},{
									title: "Editar",
									name: "admin-companycolaborators-edit",
									value: this.getData("admin-companycolaborators-edit")
								},{
									title: "Deletar",
									name: "admin-companycolaborators-delete",
									value: this.getData("admin-companycolaborators-delete")
								}
							]
						},
						{
							title: "Locais de Trabalho",
							items: [
								{
									title: "Listar",
									name: "admin-workplaces-list",
									value: this.getData("admin-workplaces-list")
								},{
									title: "Criar",
									name: "admin-workplaces-create",
									value: this.getData("admin-workplaces-create")
								},{
									title: "Editar",
									name: "admin-workplaces-edit",
									value: this.getData("admin-workplaces-edit")
								},{
									title: "Deletar",
									name: "admin-workplaces-delete",
									value: this.getData("admin-workplaces-delete")
								}
							]
						},
						{
							title: "Vínculo Local de Trabalho / Colaborador",
							items: [
								{
									title: "Listar",
									name: "admin-workplacecolaborators-list",
									value: this.getData("admin-workplacecolaborators-list")
								},{
									title: "Criar",
									name: "admin-workplacecolaborators-create",
									value: this.getData("admin-workplacecolaborators-create")
								},{
									title: "Editar",
									name: "admin-workplacecolaborators-edit",
									value: this.getData("admin-workplacecolaborators-edit")
								},{
									title: "Deletar",
									name: "admin-workplacecolaborators-delete",
									value: this.getData("admin-workplacecolaborators-delete")
								}
							]
						},
						{
							title: "Vínculo Local de Trabalho / Responsável",
							items: [
								{
									title: "Listar",
									name: "admin-workplaceresponsibles-list",
									value: this.getData("admin-workplaceresponsibles-list")
								},{
									title: "Criar",
									name: "admin-workplaceresponsibles-create",
									value: this.getData("admin-workplaceresponsibles-create")
								},{
									title: "Editar",
									name: "admin-workplaceresponsibles-edit",
									value: this.getData("admin-workplaceresponsibles-edit")
								},{
									title: "Deletar",
									name: "admin-workplaceresponsibles-delete",
									value: this.getData("admin-workplaceresponsibles-delete")
								}
							]
						},
						{
							title: "Sublocais de Trabalho",
							items: [
								{
									title: "Listar",
									name: "admin-sublocal-list",
									value: this.getData("admin-sublocal-list")
								},{
									title: "Criar",
									name: "admin-sublocal-create",
									value: this.getData("admin-sublocal-create")
								},{
									title: "Editar",
									name: "admin-sublocal-edit",
									value: this.getData("admin-sublocal-edit")
								},{
									title: "Deletar",
									name: "admin-sublocal-delete",
									value: this.getData("admin-sublocal-delete")
								}
							]
						},
						{
							title: "Setores",
							items: [
								{
									title: "Listar",
									name: "admin-sectors-list",
									value: this.getData("admin-sectors-list")
								},{
									title: "Criar",
									name: "admin-sectors-create",
									value: this.getData("admin-sectors-create")
								},{
									title: "Editar",
									name: "admin-sectors-edit",
									value: this.getData("admin-sectors-edit")
								},{
									title: "Deletar",
									name: "admin-sectors-delete",
									value: this.getData("admin-sectors-delete")
								}
							]
						},
						{
							title: "Subsetores",
							items: [
								{
									title: "Listar",
									name: "admin-subsectors-list",
									value: this.getData("admin-subsectors-list")
								},{
									title: "Criar",
									name: "admin-subsectors-create",
									value: this.getData("admin-subsectors-create")
								},{
									title: "Editar",
									name: "admin-subsectors-edit",
									value: this.getData("admin-subsectors-edit")
								},{
									title: "Deletar",
									name: "admin-subsectors-delete",
									value: this.getData("admin-subsectors-delete")
								}
							]
						},
						{
							title: "Vínculo Sublocal de Trabalho / Setor",
							items: [
								{
									title: "Listar",
									name: "admin-sublocalsector-list",
									value: this.getData("admin-sublocalsector-list")
								},{
									title: "Criar",
									name: "admin-sublocalsector-create",
									value: this.getData("admin-sublocalsector-create")
								},{
									title: "Editar",
									name: "admin-sublocalsector-edit",
									value: this.getData("admin-sublocalsector-edit")
								},{
									title: "Deletar",
									name: "admin-sublocalsector-delete",
									value: this.getData("admin-sublocalsector-delete")
								}
							]
						},
						{
							title: "Vínculo Sublocal de Trabalho / Subsetor",
							items: [
								{
									title: "Listar",
									name: "admin-sublocalsubsector-list",
									value: this.getData("admin-sublocalsubsector-list")
								},{
									title: "Criar",
									name: "admin-sublocalsubsector-create",
									value: this.getData("admin-sublocalsubsector-create")
								},{
									title: "Editar",
									name: "admin-sublocalsubsector-edit",
									value: this.getData("admin-sublocalsubsector-edit")
								},{
									title: "Deletar",
									name: "admin-sublocalsubsector-delete",
									value: this.getData("admin-sublocalsubsector-delete")
								}
							]
						},
					],
					default: [
						{
							title: "Atividades",
							items: [
								{
									title: "Listar",
									name: "admin-activities-list",
									value: this.getData("admin-activities-list")
								},{
									title: "Criar",
									name: "admin-activities-create",
									value: this.getData("admin-activities-create")
								},{
									title: "Editar",
									name: "admin-activities-edit",
									value: this.getData("admin-activities-edit")
								},{
									title: "Deletar",
									name: "admin-activities-delete",
									value: this.getData("admin-activities-delete")
								}
							]
						},
						{
							title: "Ocorrências",
							items: [
								{
									title: "Listar",
									name: "admin-occurrences-list",
									value: this.getData("admin-occurrences-list")
								},{
									title: "Criar",
									name: "admin-occurrences-create",
									value: this.getData("admin-occurrences-create")
								},{
									title: "Editar",
									name: "admin-occurrences-edit",
									value: this.getData("admin-occurrences-edit")
								},{
									title: "Deletar",
									name: "admin-occurrences-delete",
									value: this.getData("admin-occurrences-delete")
								}
							]
						},
						{
							title: "Itens",
							items: [
								{
									title: "Listar",
									name: "admin-items-list",
									value: this.getData("admin-items-list")
								},{
									title: "Criar",
									name: "admin-items-create",
									value: this.getData("admin-items-create")
								},{
									title: "Editar",
									name: "admin-items-edit",
									value: this.getData("admin-items-edit")
								},{
									title: "Deletar",
									name: "admin-items-delete",
									value: this.getData("admin-items-delete")
								}
							]
						},
						{
							title: "Inventário de Item",
							items: [
								{
									title: "Listar",
									name: "admin-iteminventories-list",
									value: this.getData("admin-iteminventories-list")
								},{
									title: "Criar",
									name: "admin-iteminventories-create",
									value: this.getData("admin-iteminventories-create")
								},{
									title: "Editar",
									name: "admin-iteminventories-edit",
									value: this.getData("admin-iteminventories-edit")
								},{
									title: "Deletar",
									name: "admin-iteminventories-delete",
									value: this.getData("admin-iteminventories-delete")
								}
							]
						},
						{
							title: "Anexos de Itens",
							items: [
								{
									title: "Listar",
									name: "admin-itemattachments-list",
									value: this.getData("admin-itemattachments-list")
								},{
									title: "Criar",
									name: "admin-itemattachments-create",
									value: this.getData("admin-itemattachments-create")
								},{
									title: "Editar",
									name: "admin-itemattachments-edit",
									value: this.getData("admin-itemattachments-edit")
								},{
									title: "Deletar",
									name: "admin-itemattachments-delete",
									value: this.getData("admin-itemattachments-delete")
								}
							]
						},
						{
							title: "Romaneio",
							items: [
								{
									title: "Listar",
									name: "admin-inventory-list",
									value: this.getData("admin-inventory-list")
								},{
									title: "Criar",
									name: "admin-inventory-create",
									value: this.getData("admin-inventory-create")
								},{
									title: "Editar",
									name: "admin-inventory-edit",
									value: this.getData("admin-inventory-edit")
								},{
									title: "Deletar",
									name: "admin-inventory-delete",
									value: this.getData("admin-inventory-delete")
								}
							]
						},
					],
					reports: [
					{
							title: "Relatório de Ocorrências",
							items: [
								{
									title: "Gerar Relatório",
									name: "admin-reportoccurrences-list",
									value: this.getData("admin-reportoccurrences-list")
								}
							]
						},
					],
					system: [
						{
							title: "Fabricantes",
							items: [
								{
									title: "Listar",
									name: "admin-manufacturers-list",
									value: this.getData("admin-manufacturers-list")
								},{
									title: "Criar",
									name: "admin-manufacturers-create",
									value: this.getData("admin-manufacturers-create")
								},{
									title: "Editar",
									name: "admin-manufacturers-edit",
									value: this.getData("admin-manufacturers-edit")
								},{
									title: "Deletar",
									name: "admin-manufacturers-delete",
									value: this.getData("admin-manufacturers-delete")
								}
							]
						},
						{
							title: "Tipos de Carga",
							items: [
								{
									title: "Listar",
									name: "admin-loadtypes-list",
									value: this.getData("admin-loadtypes-list")
								},{
									title: "Criar",
									name: "admin-loadtypes-create",
									value: this.getData("admin-loadtypes-create")
								},{
									title: "Editar",
									name: "admin-loadtypes-edit",
									value: this.getData("admin-loadtypes-edit")
								},{
									title: "Deletar",
									name: "admin-loadtypes-delete",
									value: this.getData("admin-loadtypes-delete")
								}
							]
						},
						{
							title: "Tipos de Consumo",
							items: [
								{
									title: "Listar",
									name: "admin-consumptiontypes-list",
									value: this.getData("admin-consumptiontypes-list")
								},{
									title: "Criar",
									name: "admin-consumptiontypes-create",
									value: this.getData("admin-consumptiontypes-create")
								},{
									title: "Editar",
									name: "admin-consumptiontypes-edit",
									value: this.getData("admin-consumptiontypes-edit")
								},{
									title: "Deletar",
									name: "admin-consumptiontypes-delete",
									value: this.getData("admin-consumptiontypes-delete")
								}
							]
						},
						{
							title: "Tipos de EPI",
							items: [
								{
									title: "Listar",
									name: "admin-epitypes-list",
									value: this.getData("admin-epitypes-list")
								},{
									title: "Criar",
									name: "admin-epitypes-create",
									value: this.getData("admin-epitypes-create")
								},{
									title: "Editar",
									name: "admin-epitypes-edit",
									value: this.getData("admin-epitypes-edit")
								},{
									title: "Deletar",
									name: "admin-epitypes-delete",
									value: this.getData("admin-epitypes-delete")
								}
							]
						},
						{
							title: "Mensagens",
							items: [
								{
									title: "Listar",
									name: "admin-messages-list",
									value: this.getData("admin-messages-list")
								},{
									title: "Criar",
									name: "admin-messages-create",
									value: this.getData("admin-messages-create")
								},{
									title: "Editar",
									name: "admin-messages-edit",
									value: this.getData("admin-messages-edit")
								},{
									title: "Deletar",
									name: "admin-messages-delete",
									value: this.getData("admin-messages-delete")
								}
							]
						},
						{
							title: "Status de Incidente",
							items: [
								{
									title: "Listar",
									name: "admin-incidentstatus-list",
									value: this.getData("admin-incidentstatus-list")
								},{
									title: "Criar",
									name: "admin-incidentstatus-create",
									value: this.getData("admin-incidentstatus-create")
								},{
									title: "Editar",
									name: "admin-incidentstatus-edit",
									value: this.getData("admin-incidentstatus-edit")
								},{
									title: "Deletar",
									name: "admin-incidentstatus-delete",
									value: this.getData("admin-incidentstatus-delete")
								}
							]
						},
						{
							title: "Status de Atividade",
							items: [
								{
									title: "Listar",
									name: "admin-activitystatus-list",
									value: this.getData("admin-activitystatus-list")
								},{
									title: "Criar",
									name: "admin-activitystatus-create",
									value: this.getData("admin-activitystatus-create")
								},{
									title: "Editar",
									name: "admin-activitystatus-edit",
									value: this.getData("admin-activitystatus-edit")
								},{
									title: "Deletar",
									name: "admin-activitystatus-delete",
									value: this.getData("admin-activitystatus-delete")
								}
							]
						},
						{
							title: "Periodicidade",
							items: [
								{
									title: "Listar",
									name: "admin-frequencies-list",
									value: this.getData("admin-frequencies-list")
								},{
									title: "Criar",
									name: "admin-frequencies-create",
									value: this.getData("admin-frequencies-create")
								},{
									title: "Editar",
									name: "admin-frequencies-edit",
									value: this.getData("admin-frequencies-edit")
								},{
									title: "Deletar",
									name: "admin-frequencies-delete",
									value: this.getData("admin-frequencies-delete")
								}
							]
						},
						{
							title: "Permitir Atraso",
							items: [
								{
									title: "Listar",
									name: "admin-allowdelays-list",
									value: this.getData("admin-allowdelays-list")
								},{
									title: "Criar",
									name: "admin-allowdelays-create",
									value: this.getData("admin-allowdelays-create")
								},{
									title: "Editar",
									name: "admin-allowdelays-edit",
									value: this.getData("admin-allowdelays-edit")
								},{
									title: "Deletar",
									name: "admin-allowdelays-delete",
									value: this.getData("admin-allowdelays-delete")
								}
							]
						},
						{
							title: "Vínculo Atraso / Periodicidade",
							items: [
								{
									title: "Listar",
									name: "admin-allowdelayfrequency-list",
									value: this.getData("admin-allowdelayfrequency-list")
								},{
									title: "Criar",
									name: "admin-allowdelayfrequency-create",
									value: this.getData("admin-allowdelayfrequency-create")
								},{
									title: "Editar",
									name: "admin-allowdelayfrequency-edit",
									value: this.getData("admin-allowdelayfrequency-edit")
								},{
									title: "Deletar",
									name: "admin-allowdelayfrequency-delete",
									value: this.getData("admin-allowdelayfrequency-delete")
								}
							]
						},
						{
							title: "Tipos de Problemas",
							items: [
								{
									title: "Listar",
									name: "admin-typeproblems-list",
									value: this.getData("admin-typeproblems-list")
								},{
									title: "Criar",
									name: "admin-typeproblems-create",
									value: this.getData("admin-typeproblems-create")
								},{
									title: "Editar",
									name: "admin-typeproblems-edit",
									value: this.getData("admin-typeproblems-edit")
								},{
									title: "Deletar",
									name: "admin-typeproblems-delete",
									value: this.getData("admin-typeproblems-delete")
								}
							]
						},
						{
							title: "Categorias",
							items: [
								{
									title: "Listar",
									name: "admin-categories-list",
									value: this.getData("admin-categories-list")
								},{
									title: "Criar",
									name: "admin-categories-create",
									value: this.getData("admin-categories-create")
								},{
									title: "Editar",
									name: "admin-categories-edit",
									value: this.getData("admin-categories-edit")
								},{
									title: "Deletar",
									name: "admin-categories-delete",
									value: this.getData("admin-categories-delete")
								}
							]
						},
						{
							title: "Subcategorias",
							items: [
								{
									title: "Listar",
									name: "admin-subcategories-list",
									value: this.getData("admin-subcategories-list")
								},{
									title: "Criar",
									name: "admin-subcategories-create",
									value: this.getData("admin-subcategories-create")
								},{
									title: "Editar",
									name: "admin-subcategories-edit",
									value: this.getData("admin-subcategories-edit")
								},{
									title: "Deletar",
									name: "admin-subcategories-delete",
									value: this.getData("admin-subcategories-delete")
								}
							]
						},
						{
							title: "Grupo de Colaboradores",
							items: [
								{
									title: "Listar",
									name: "admin-colaboratorgroups-list",
									value: this.getData("admin-colaboratorgroups-list")
								},{
									title: "Criar",
									name: "admin-colaboratorgroups-create",
									value: this.getData("admin-colaboratorgroups-create")
								},{
									title: "Editar",
									name: "admin-colaboratorgroups-edit",
									value: this.getData("admin-colaboratorgroups-edit")
								},{
									title: "Deletar",
									name: "admin-colaboratorgroups-delete",
									value: this.getData("admin-colaboratorgroups-delete")
								}
							]
						},
						{
							title: "Profissões / Cargos",
							items: [
								{
									title: "Listar",
									name: "admin-professions-list",
									value: this.getData("admin-professions-list")
								},{
									title: "Criar",
									name: "admin-professions-create",
									value: this.getData("admin-professions-create")
								},{
									title: "Editar",
									name: "admin-professions-edit",
									value: this.getData("admin-professions-edit")
								},{
									title: "Deletar",
									name: "admin-professions-delete",
									value: this.getData("admin-professions-delete")
								}
							]
						},
						{
							title: "Tipos de Empresa",
							items: [
								{
									title: "Listar",
									name: "admin-companytypes-list",
									value: this.getData("admin-companytypes-list")
								},{
									title: "Criar",
									name: "admin-companytypes-create",
									value: this.getData("admin-companytypes-create")
								},{
									title: "Editar",
									name: "admin-companytypes-edit",
									value: this.getData("admin-companytypes-edit")
								},{
									title: "Deletar",
									name: "admin-companytypes-delete",
									value: this.getData("admin-companytypes-delete")
								}
							]
						},{
							title: "Certificações",
							items: [
								{
									title: "Listar",
									name: "admin-certifications-list",
									value: this.getData("admin-certifications-list")
								},{
									title: "Criar",
									name: "admin-certifications-create",
									value: this.getData("admin-certifications-create")
								},{
									title: "Editar",
									name: "admin-certifications-edit",
									value: this.getData("admin-certifications-edit")
								},{
									title: "Deletar",
									name: "admin-certifications-delete",
									value: this.getData("admin-certifications-delete")
								}
							]
						},
					]
				},
				permissions: [],
				permissionsRemoved: [],
				type: "menu",
			}
		},
		mounted() {
		},
		methods: {
			getData(key) {

				var temp = JSON.parse(this.info);
				var check = temp.includes(key);

				//validate check
				if (check){

					return true;

				} else {

					return false;

				}
				//end if validate check

			},
			getDataRole(key){
				return JSON.parse(this.role)[key];
			},
			changeToggle(toggle) {

				const index = this.permissions.indexOf(toggle)

				if (index == -1) {

					this.permissions.push(toggle)

					const indexRemoved = this.permissionsRemoved.indexOf(toggle)

					if (indexRemoved != -1) {
						this.permissionsRemoved.splice(indexRemoved, 1)
					}

				} else {

					this.permissions.splice(index, 1)

					const indexRemoved = this.permissionsRemoved.indexOf(toggle)

					if (indexRemoved == -1) {
						this.permissionsRemoved.push(toggle)
					}
				}

			},
			saveData() {
				if (this.name != ""){

					let _this = this;
					_this.errors = [];
					_this.message = "";
					_this.loading = true;

					let dataPermission = {
						"name": _this.name,
						"permission": _this.permission,
						"permissions": _this.permissions,
						"removed": _this.permissionsRemoved,
						"_method": "PATCH"
					};
					axios.post("/admin/permissions/"+_this.getDataRole('id'), dataPermission).then((res) => {

						_this.loading = false;

						//call function in ApiService to validate success";
						_this._api_validateSuccess(res);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			}
		}
	}
</script>