import { render, staticRenderFns } from "./generateactivity.vue?vue&type=template&id=a6d67a82&"
import script from "./generateactivity.vue?vue&type=script&lang=js&"
export * from "./generateactivity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports