<template>
  <div class="tab-pane fade" id="tab-geral" role="tabpanel" aria-labelledby="geral-tab">
    <div class="row">
      <div class="col-12">
        <div class="card">

          <!-- card header -->
          <!-- card header -->
          <div class="card-header">
            <h4>Verifique os dados abaixo</h4>
          </div>
          <!-- end card header -->

          <!-- card body -->
          <div class="card-body">

            <!-- form -->
            <h6 class="heading-small text-muted mb-4">Informações</h6>
            <div class="">
              <div class="row">

                <!-- title -->
                <div class="col-md-8">
                  <div class="form-group">
                    <label class="form-control-label" for="input-title">Título</label>
                    <input type="text" id="input-title" v-bind:class="{'is-invalid': errors.title}"  class="form-control" placeholder="Título" v-model="title" required="false" :disabled="true">
                    <div class="invalid-feedback d-block" v-if="errors.title">
                      <p>{{ errors.title[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end title -->

                <!-- activity_status_id -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-control-label" for="input-activity_status_id">Status</label>
                    <v-select :options="activitystatus" label="name" v-bind:class="{'is-invalid': errors.activity_status_id}" :reduce="name => name.id" v-model="activity_status_id" required :disabled="true"></v-select>
                    <div class="invalid-feedback d-block" v-if="errors.activity_status_id">
                      <p>{{ errors.activity_status_id[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end activity_status_id -->

                <!-- description -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="input-description">Descrição</label>
                    <textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" class="form-control" placeholder="Descrição" v-model="description" rows="8" required="false" :disabled="true"></textarea>
                    <div class="invalid-feedback d-block" v-if="errors.description">
                      <p>{{ errors.description[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end description -->

                <!-- details -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="input-details">Detalhes</label>
                    <textarea type="text" id="input-details" v-bind:class="{'is-invalid': errors.details}" class="form-control" placeholder="Detalhes" v-model="details" rows="8" required="true" :disabled="true"></textarea>
                    <div class="invalid-feedback d-block" v-if="errors.details">
                      <p>{{ errors.details[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end details -->

                <!-- expected_date -->
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form-control-label" for="input-expected_date">Data Prevista</label>
                    <input type="date" id="input-expected_date" v-bind:class="{'is-invalid': errors.expected_date}"   class="form-control" placeholder="Data prevista" v-model="expected_date" required="false" :disabled="true">
                    <div class="invalid-feedback d-block" v-if="errors.expected_date">
                      <p>{{ errors.expected_date[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end expected_date -->

                <!-- expected_time -->
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form-control-label" for="input-expected_time">Hora Prevista</label>
                    <input type="time" id="input-expected_time" v-bind:class="{'is-invalid': errors.expected_time}"   class="form-control" placeholder="Hora Prevista" v-model="expected_time" required="false" :disabled="true">
                    <div class="invalid-feedback d-block" v-if="errors.expected_time">
                      <p>{{ errors.expected_time[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end expected_time -->

                <!-- time -->
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form-control-label" for="input-time">Tempo</label>
                    <input type="tel" id="input-time" v-bind:class="{'is-invalid': errors.time}"   class="form-control" placeholder="Tempo" v-model="time" required="false"  :disabled="true">
                    <div class="invalid-feedback d-block" v-if="errors.time">
                      <p>{{ errors.time[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end time -->

                <!-- duration -->
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="form-control-label" for="input-duration">Duração</label>
                    <input type="time" id="input-duration" v-bind:class="{'is-invalid': errors.duration}"   class="form-control" placeholder="Duração" v-model="duration" required="false" :disabled="true">
                    <div class="invalid-feedback d-block" v-if="errors.duration">
                      <p>{{ errors.duration[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end duration -->

                <!-- template_id -->
                <div class="col-md-8">
                  <div class="form-group">
                    <label class="form-control-label" for="input-template_id">Template</label>
                    <v-select :options="templates" label="name" v-bind:class="{'is-invalid': errors.template_id}" :reduce="name => name.id" v-model="template_id" @input="validateDelay()" required :disabled="true"></v-select>
                    <div class="invalid-feedback d-block" v-if="errors.template_id">
                      <p>{{ errors.template_id[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end template_id -->

                <!-- allow delay -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-control-label" for="input-allow_delay">Permitir Atraso?</label>
                    <v-select :options="allowDelays" v-bind:class="{'is-invalid': errors.allow_delay}" v-model="allow_delay" required :disabled="true"></v-select>
                    <div class="invalid-feedback d-block" v-if="errors.allow_delay">
                      <p>{{ errors.allow_delay[0] }}</p>
                    </div>
                  </div>
                </div>
                <!-- end allow delay -->

                <!-- companY -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-work_place_id">Empresa</label>
										<v-select :options="companies" label="corporate_name" v-bind:class="{'is-invalid': errors.company_id}" :reduce="corporate_name => corporate_name.id" v-model="company_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.company_id">
											<p>{{ errors.company_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end company -->

								<!-- contracts -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-contract_id">Contrato</label>
										<v-select :options="contracts" label="corporate_name" v-bind:class="{'is-invalid': errors.contract_id}" :reduce="corporate_name => corporate_name.id" v-model="contract_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.contract_id">
											<p>{{ errors.contract_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end contracts -->

								<!-- work_place_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
										<v-select :options="workplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.work_place_id}" :reduce="corporate_name => corporate_name.work_place_id" v-model="work_place_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.work_place_id">
											<p>{{ errors.work_place_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end work_place_id -->

								<!-- sublocal -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-sublocal_id">Sublocal de Trabalho</label>
										<v-select :options="sublocal" label="corporate_name" v-bind:class="{'is-invalid': errors.work_place_id}" :reduce="corporate_name => corporate_name.id" v-model="sublocal_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.sublocal_id">
											<p>{{ errors.sublocal_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end sublocal -->

								<!-- colaborator_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-colaborator_id">Colaborador</label>
										<v-select :options="colaborators" multiple label="name" v-bind:class="{'is-invalid': errors.colaborator_id}" :reduce="name => name.colaborator_id" v-model="colaborator_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.colaborator_id">
											<p>{{ errors.colaborator_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end colaborator_id -->

								<!-- colaborator_group_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-colaborator_group_id">Grupo de Colaboradores</label>
										<v-select :options="colaboratorgroups" multiple label="name" v-bind:class="{'is-invalid': errors.colaborator_group_id}" :reduce="name => name.id" v-model="colaborator_group_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.colaborator_group_id">
											<p>{{ errors.colaborator_group_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end colaborator_group_id -->

								<!-- certification -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-certification_id">Certificações Necessárias</label>
									<v-select :options="certifications" multiple label="name" v-bind:class="{'is-invalid': errors.certification_id}" :reduce="name => name.id" v-model="certification_id" :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.certification_id">
										<p>{{ errors.certification_id[0] }}</p>
									</div>
								</div>
							</div>

								<!-- type -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-type">Tipo de Atividade</label>
										<v-select v-bind:class="{'is-invalid': errors.type}" v-model="type" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.type">
											<p>{{ errors.type[0] }}</p>
										</div>
									</div>
								</div>

								<!-- item_id -->
								<div class="col-md-8" v-if="type == 'item'">
									<div class="form-group">
										<label class="form-control-label" for="input-item_id">Item</label>
										<v-select :options="items" label="name" v-bind:class="{'is-invalid': errors.item_id}" :reduce="name => name.id" v-model="item_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.item_id">
											<p>{{ errors.item_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end item_id -->

								<!-- sectors -->
								<div class="col-md-4" v-if="type == 'local'">
									<div class="form-group">
										<label class="form-control-label" for="input-sector_id">Setor</label>
										<v-select :options="sectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="sector_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.sector_id">
											<p>{{ errors.sector_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end sectors -->

								<!-- subsectors -->
								<div class="col-md-4" v-if="type == 'local'">
									<div class="form-group">
										<label class="form-control-label" for="input-subsector_id">Subsetor</label>
										<v-select :options="subsectors" label="name" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" v-model="subsector_id" required :disabled="true"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.subsector_id">
											<p>{{ errors.subsector_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end subsectors -->

              </div>
            </div>

          </div><!-- card body -->
        </div><!-- card -->
      </div><!-- col-12 -->

      <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
    </div><!-- row -->
  </div>
</template>

<script>
	export default {
	props: ["activities"],
		data() {
			return {
				activitystatus: [],
				activity_status_id: this.getData("activity_status_id"),
				allowDelays: [],
				allow_delay: this.getData("allow_delay"),
				allowDelayDisabled: false,
				certifications: [],
				certification_id: this.validateCertification(),
				companies: [],
				company_id: this.getData("company_id"),
				contracts: [],
				contract_id: this.getData("contract_id"),
				title: this.getData("title"),
				description: this.getData("description"),
				details: this.getData("details"),
				expected_date: this.getData("expected_date"),
				expected_time: this.getData("expected_time"),
				time: this.getData("time"),
				duration: this.getData("duration"),
				item_id: this.getData("item_id"),
				items: [],
				sublocal: [],
				sublocal_id: this.getData("sublocal_id"),
				work_place_id: this.getData("work_place_id"),
				workplaces: [],
				template_id: this.getData("template_id"),
				templates: [],
				colaborator_id: this.validateColaborator(),
				colaborators: [],
				colaborator_group_id: this.validateColaboratorGroup(),
				colaboratorgroups: [],
				tempColaborators: [],
				tempColaboratorsGroups: [],
				errors: [],
				sectors: [],
				sector_id: this.getData('sector_id'),
				subsectors: [],
				subsector_id: this.getData('subsector_id'),
				type: this.getData('type'),
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadItems();
			this.loadCompanies();
			this.loadTemplates();
			this.loadActivityStatus();
			this.loadContracts();
			this.validateStatus();

			this.validateRelationships();

			setTimeout(() => {
				this.validateWorkplace();
			}, 2000);
		},
		methods: {
			getData(key) {
				return JSON.parse(this.activities)[key];
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/items").then((res) => {
					_this.items = _this.items.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadCertifications() {
				let _this = this;
				axios.get("/admin/certifications").then((res) => {
					_this.certifications = _this.certifications.concat(res.data);
				}).catch((err) => {
				});
			},
			loadCompanies() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/companies").then((res) => {
					_this.companies = _this.companies.concat(res.data);
					_this.loadWorkPlaces();
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadContracts() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/contracts").then((res) => {
					_this.contracts = _this.contracts.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSublocal() {
				let _this = this;
				_this.loading = true;
				_this.sublocal = [];
				axios.get("/admin/workplacesublocal/"+this.work_place_id+"/search").then((res) => {
					res.data.map((rowSublocal) => {
						_this.sublocal.push({
							corporate_name: rowSublocal.sublocal.corporate_name,
							id: rowSublocal.sublocal_id
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadActivityStatus() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/activitystatus").then((res) => {
					_this.activitystatus = _this.activitystatus.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadWorkPlaces() {
				let _this = this;
				_this.loading = true;
				_this.workplaces = [];
				axios.get("/admin/companyworkplaces/"+_this.company_id+"/search").then((res) => {
					res.data.map((row) => {
						row.corporate_name = row.work_places.corporate_name;
						_this.workplaces.push(row);
					});
					_this.loadSublocal();
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadTemplates() {
				let _this = this;
				_this.templates = [];
				_this.loading = true;
				axios.get("/admin/templates").then((res) => {
					_this.templates = _this.templates.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadColaborators() {
				let _this = this;
				_this.colaborators = [];
				_this.tempColaborators = [];
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+_this.company_id+"/search").then((res) => {
					res.data.map((row) => {
						row.name = row.colaborators.users.profile.name;
						_this.tempColaborators.push(row);
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadColaboratorGroups() {
				let _this = this;
				_this.colaboratorgroups = [];
				_this.tempColaboratorsGroups = [];
				_this.loading = true;
				axios.get("/admin/colaboratorgroups/"+_this.company_id+"/search").then((res) => {
					_this.tempColaboratorsGroups = _this.tempColaboratorsGroups.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			validateDelay(){
				let _this = this;
				_this.allowDelays = [];
				
				//read all templates to get info of the selected
				_this.templates.map((itemTemplate) => {
					if(itemTemplate.id == _this.template_id){

						switch (itemTemplate.frequencies.type) {
							case 'day':
								if (itemTemplate.frequencies.time == 1){
									_this.allowDelayDisabled = true;
								} else {
									for(var i = 1; i < itemTemplate.frequencies.time; i++){
										_this.allowDelays.push(i);
									}
								}								
								break;

							case 'month':
								for(var i = 1; i <= 31; i++){
									_this.allowDelays.push(i);
								}
								break;

							case 'year':
								for(var i = 1; i <= 12; i++){
									_this.allowDelays.push(i);
								}
								break;
						
							default:
								_this.allowDelayDisabled = true;
								break;
						}

					}
				});
			},
			validateRelationships(){
				this.loadColaboratorGroups();
				this.loadColaborators();
				this.loadWorkPlaces();
			},
			validateStatus(){
				if(this.activity_status_id === 6 || this.activity_status_id === 8 || this.activity_status_id === 9){
					this.disableStatus = true;
				}
			},
			validateWorkplace(){
				let _this = this;
				_this.loading = true;
				_this.colaborators = [];
				_this.colaboratorgroups = [];

				axios.get("/admin/companyworkplacecolaborators/"+_this.company_id+"/search").then((res) => {
					
					res.data.map((itemWorkplaceColaborator) => {
						if(itemWorkplaceColaborator.work_place_id == _this.work_place_id){

							//read all colaborators
							_this.tempColaborators.map((itemColaborator) => {
								if(itemColaborator.colaborator_id == itemWorkplaceColaborator.colaborator_id){
									_this.colaborators.push(itemColaborator);
								}
							});

							//read all colaborator groups
							_this.tempColaboratorsGroups.map((itemColaboratorGroup) => {
								if(itemColaboratorGroup.id == itemWorkplaceColaborator.colaborator_group_id){
									_this.colaboratorgroups.push(itemColaboratorGroup);
								}
							});

						}
					});

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});

				//load sectors
				_this.sectors = [];
				_this.loading = true;
				axios.get("/admin/sectors/"+_this.sublocal_id+"/search").then((res) => {
					_this.sectors = _this.sectors.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});

				_this.subsectors = [];
				_this.loading = true;
				axios.get("/admin/subsectors/"+_this.sublocal_id+"/search").then((res) => {
					_this.subsectors = res.data;
					_this.validateSector();
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			validateCertification(){
				var certifications = this.getData('certifications');
				var ids = [];

				if(certifications){
					certifications.map((rowCertification) => {
						ids.push({
							id: rowCertification.certification_id,
							name: rowCertification.info.name
						});
					});
				}
				return ids;
			},
			validateColaborator(){
				var colaborators = this.getData('colaborators');
				var ids = [];

				if(colaborators){
					colaborators.map((rowColaborator) => {
						ids.push({
							id: rowColaborator.colaborator_id,
							name: rowColaborator.colaborator.users.profile.name
						});
					});
				}

				return ids;

			},
			validateColaboratorGroup(){
				var colaboratorGroups = this.getData('colaborator_groups');
				var ids = [];

				if(colaboratorGroups){
					colaboratorGroups.map((rowColaboratorGroup) => {
						ids.push({
							id: rowColaboratorGroup.colaborator_group_id,
							name: rowColaboratorGroup.group.name
						});
					});
				}

				return ids;

			},
			validateSector(){
				var subsector = this.getData('subsector_id');

				if(subsector){
					this.subsectors.map((rowSubsector) => {
						if(rowSubsector.id == subsector){

							console.log(rowSubsector.sectors.id);
							
							//save sector
							_this.sector_id = rowSubsector.sectors.id;
							// _this.sector_id = {
							// 	id: rowSubsector.sectors.id,
							// 	name: rowSubsector.sectors.name
							// };

							//save subsector
							// _this.subsector_id = {
							// 	id: rowSubsector.id,
							// 	name: rowSubsector.name
							// };

						}
					});
				}
			},
			validateSubsector(){

			}
		}
	}
</script>
