<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Colaboradores cadastrados</p>
				<a href="/admin/colaborators/create" class="btn btn-outline-success float-right"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<div class="dropdown float-right">
					<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-download"></i>
						Exportar
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#" @click="exportData('pdf')">PDF</a>
						<a class="dropdown-item" href="#" @click="exportData('csv')">Excel</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="#" @click="$refs.gridfullexport.showModal('colaborators','colaborators')">Completo</a>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<template>
						<div class="search-container">
							<i class="search-icon fas fa-search"></i>
							<input
								v-model="searchQuery"
								@input="debounce(loadItems, 500)"
								placeholder="Buscar.."
								class="search-input"
							/>
						</div>
					</template>
					<vue-good-table
						v-if="(!loading && colaboratorsItems.length) || (!loading && searchQuery)"
						:columns="tableColumns"
						:rows="colaboratorsItems"
						:pagination-options="{
                            enabled: true,
                            perPage: 500
                        }"
						styleClass="vgt-table"
					>
						<template slot="pagination-bottom">
                            <div
                                style="display: flex; justify-content: space-between; align-items: center;"
                            >
                                <div
                                    style="display: flex; align-items: center; justify-content: center; margin-top: 8px;"
                                >
                                    <label
                                        style="font-size: 12px; margin-top: 8px"
                                    >
                                        {{ tablePagination.rowsPerPageLabel }}:
                                    </label>
                                    <select
                                        v-model="currentPerPage"
                                        @change="
                                            event =>
                                                changePerPage(
                                                    event.target.value
                                                )
                                        "
                                        style="margin-left: 8px; font-size: 12px; font-weight: bold; border: none;"
                                    >
                                        <option
                                            v-for="option in perPageOptions"
                                            :key="option"
                                            :value="option"
                                            >{{ option }}</option
                                        >
                                    </select>
                                </div>

                                <div
                                    style="display: flex; align-items: center; margin-top: 8px;"
                                >
                                    <span style="font-size: 12px;"
                                        >{{ from }} - {{ to }} de
                                        {{ total }}</span
                                    >

                                    <div
                                        style="display: flex; gap: 8px; margin-left: 16px;"
                                    >
                                        <div
                                            @click="prevPage"
                                            :style="{
                                                cursor: canGoPrev
                                                    ? 'pointer'
                                                    : 'not-allowed',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                color: canGoPrev
                                                    ? 'inherit'
                                                    : '#ccc'
                                            }"
                                            :disabled="!canGoPrev"
                                        >
                                            ◀
                                            {{ tablePagination.prevLabel }}
                                        </div>
                                        <div
                                            @click="nextPage"
                                            :style="{
                                                cursor: canGoNext
                                                    ? 'pointer'
                                                    : 'not-allowed',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                color: canGoNext
                                                    ? 'inherit'
                                                    : '#ccc'
                                            }"
                                            :disabled="!canGoNext"
                                        >
                                            {{ tablePagination.nextLabel }}
                                            ▶
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_status'">
								<p class="alert-table alert alert-success" v-if="props.row.status == 'active'">Ativo</p>
								<p class="alert-table alert alert-danger" v-if="props.row.status == 'inactive'">Inativo</p>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-warning" @click="rememberData(props.row)" title="Enviar Dados por E-mail"><i class="far fa-envelope"></i></button>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else-if="!loading && !searchQuery" class="text-center p-3 text-muted">
						<h5>Nenhum colaboradores cadastrado(a)!</h5>
					</div>
				</div>

				<div
					class="text-center p-4 text-muted"
					v-if="loading"
				>
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>
			</div>

			<gridfullexport-component ref="gridfullexport"></gridfullexport-component>
			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		props: ['permissions'],
		data() {
			return {
				users: [],
				professions: [],
				colaboratorsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				perPageOptions: [10, 50, 100, 500],
				currentPage: 1,
				currentPerPage: 10,
				total: null,
				from: 0,
				to: 0,
				searchQuery: null,
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'users.profile.name',
					},{
						label: 'Empresa',
						field: 'company.corporate_name',
					},{
						label: 'Local de Trabalho',
						field: 'workplace.corporate_name',
					},{
						label: 'Status',
						field: '_status',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '120px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		watch: {
      	  	currentPage: "loadItems",
        	currentPerPage: "loadItems",
    	},
		computed: {
			canGoNext() {
				return this.currentPage * this.currentPerPage < this.total;
			},
			canGoPrev() {
				return this.currentPage > 1;
			}
    	},
		methods: {
			debounce(func, wait) {
				clearTimeout(this.debounceTimeout);
				this.debounceTimeout = setTimeout(() => {
					func();
				}, wait);
			},
			changePerPage(value) {
            	this.currentPerPage = value;
				this.currentPage = 1;
			},
			nextPage() {
				if (this.canGoNext) {
					this.currentPage++;
				}
			},
			prevPage() {
				if (this.canGoPrev) {
					this.currentPage--;
				}
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/colaborators", {
					params: {
						search: _this.searchQuery,
                        page: _this.currentPage,
                        perPage: _this.currentPerPage
                    }
				}).then((res) => {
					const { data } = res.data;
                    const { total, from, to } = res.data.pagination;
					_this.colaboratorsItems = [...data];
					_this.total = total;
                    _this.from = from;
                    _this.to = to;

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			exportData(type){
				var tempForm = $('#vgt-table');
				if(type == 'pdf'){
					this._exportPDF('Colaboradores', [6], tempForm, ['ID','Nome','Empresa','Local de Trabalho', 'Status', 'Data e Hora']);
				} else {
					this._exportExcel('Colaboradores', [6], tempForm, ['ID','Nome','Empresa','Local de Trabalho', 'Status', 'Data e Hora']);
				}
    	},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Colaborador",
					text: "Tem certeza que deseja excluir esse Colaborador?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/colaborators/"+ID).then((res) => {

							//_this.colaborators.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){
				location.href="/admin/colaborators/"+item.id+"/edit";
			},
			rememberData(colaborator){
				let _this = this;
				_this.errors = [];
				_this.loading = true;
				axios.get("/admin/colaborators/"+colaborator.id+"/send").then((res) => {

					_this.loading = false;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,false);

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);
					
				});
			},
			resetForm(){

				this.colaborator = {
					cpf: '',
					phone: '',
					country: '',
					status: '',
					user_id: '',
					profession_id: '',
				};

			}
		}
	}
</script>

<style scoped>
.search-container {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid #ddd;
	margin-bottom: 15px;
}

.search-input {
    border: none;
    background: transparent;
    padding: 5px;
    font-size: 14px;
    width: 100%;
    outline: none;
}

.search-icon {
    color: #888;
    font-size: 16px;
    margin-right: 8px;
    margin-left: 8px;
}
</style>