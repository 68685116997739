<template>
  <div class="tab-pane fade" id="tab-courses" role="tabpanel" aria-labelledby="courses-tab">
    <div>
      <div class="row section-top">
        <div class="col-md-12 pad0">
          <p class="section-lead">Lista de todos Cursos cadastrados para o Colaborador</p>
          <a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalNewCourse"><i class="fas fa-plus"></i>&nbsp;Novo</a>
        </div>
      </div>

      <div class="row">
        <div class="col">

          <!-- table -->
          <div class="card p-4">
            <vue-good-table v-if="colaboratorCoursesItems.length"
              :columns="tableColumns"
              :rows="colaboratorCoursesItems"
              :pagination-options="tablePagination"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar..',
              }"
              styleClass="vgt-table"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == '_validity'">
                  {{props.row.validity}} meses
                </span>
                <span v-if="props.column.field == 'actions'">
                  <div>
                    <button class="btn btn-sm btn-outline-secondary" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
            <div v-else class="text-center p-3 text-muted">
              <h5>Nenhum curso cadastrado!</h5>
            </div>
          </div>

          <div class="text-center p-4 text-muted" v-if="loading">
            <h5>Carregando...</h5>
            <p>Aguarde até que os dados sejam carregados...</p>
          </div>

        </div>

        <!-- modal -->
        <div class="modal fade" id="modalNewCourse" tabindex="-1" aria-labelledby="modalNewCourseLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalNewCourseLabel">{{modalCourseTitle}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form v-on:submit.prevent="saveData">

                  <div class="row">

                    <!-- name -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-name">Nome</label>
                        <input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" maxlength="60" class="form-control" placeholder="Nome" v-model="modalCourse.name" required="true" >
                        <div class="invalid-feedback d-block" v-if="errors.name">
                          <p>{{ errors.name[0] }}</p>
                        </div>
                      </div>
                    </div>
                    

                    <!-- date -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-date">Data Realização</label>
                        <input type="date" id="input-name" v-bind:class="{'is-invalid': errors.date}" maxlength="60" class="form-control" placeholder="Data" v-model="modalCourse.date" required="true" >
                        <div class="invalid-feedback d-block" v-if="errors.date">
                          <p>{{ errors.date[0] }}</p>
                        </div>
                      </div>
                    </div>

                    <!-- validity -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-validity">Validade (em meses)</label>
                        <input type="number" id="input-name" v-bind:class="{'is-invalid': errors.validity}" maxlength="60" class="form-control" placeholder="Nome" v-model="modalCourse.validity" required="true" >
                        <div class="invalid-feedback d-block" v-if="errors.validity">
                          <p>{{ errors.validity[0] }}</p>
                        </div>
                      </div>
                    </div>

                    <!-- file -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-file">Arquivo (tamanho máximo 5mb)</label>
                        <div class="custom-file">
                          <input type="file" class="custom-file-input" v-bind:class="{'is-invalid': errors.file}" id="file" name="file" ref="file" lang="pt" v-on:change="handleFileUpload()">
                          <label class="custom-file-label" for="file">{{fileName}}</label>
                        </div>
                        <div class="invalid-feedback d-block" v-if="errors.file">
                          <p>{{ errors.file[0] }}</p>
                        </div>
                      </div>
                    </div>
                
                  </div>

                  <div class="text-right">
                    <button type="submit" class="btn btn-success">SALVAR</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- end modal -->

      <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
      </div>
    </div>
  </div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['colaborators'],
		data() {
			return {
				modalCourseTitle: "Novo Curso",
				fileName: '',
        modalCourse: {},
				isEditColaboratorCourse: false,
				colaboratorCourseID: "",
				colaboratorCoursesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Data',
						field: 'date',
            type: 'date',
						dateInputFormat: "yyyy-MM-dd",
						dateOutputFormat: 'dd/MM/yyyy'
					},{
						label: 'Validade',
						field: '_validity',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.loadItems();
		},
		methods: {
      handleFileUpload(){

				//validate size
				if (this.$refs.file.files[0].size > 5000000){

					//define error
					var error = {
						response: {
							status: 500,
							data: {
								message: "Tamanho máximo de arquivo permitido 5mb"
							}
						}
					}

					this._api_validateError(error);

				} else {

					this.modalCourse.file = this.$refs.file.files[0];
					this.fileName = this.$refs.file.files[0].name;

				}

			},
      getData(key) {
				return JSON.parse(this.colaborators)[key];
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/colaboratorcourses/"+_this.getData('id')+"/search").then((res) => {
					_this.colaboratorCoursesItems = _this.colaboratorCoursesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditColaboratorCourse == true){

          let formData = new FormData();
				  formData.append("name", _this.modalCourse.name);
          formData.append("date", _this.modalCourse.date);
          formData.append("validity", _this.modalCourse.validity);
          formData.append("file", _this.modalCourse.file);
          formData.append("colaborator_id", _this.getData('id'));
          formData.append("_method", "PATCH");

					axios.post("/admin/colaboratorcourses/"+_this.colaboratorCourseID, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalNewCourse').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let formData = new FormData();
				  formData.append("name", _this.modalCourse.name);
          formData.append("date", _this.modalCourse.date);
          formData.append("validity", _this.modalCourse.validity);
          formData.append("file", _this.modalCourse.file);
          formData.append("colaborator_id", _this.getData('id'));

					axios.post("/admin/colaboratorcourses", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.colaboratorCoursesItems.push(res.data.data);
						$('#modalNewCourse').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Curso do Colaborador",
					text: "Tem certeza que deseja excluir esse Curso?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/colaboratorcourses/"+ID).then((res) => {

							//_this.modalCourse.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.modalCourse = item;
				this.isEditColaboratorCourse = true;
				this.colaboratorCourseID = item.id;
				this.modalCourseTitle = "Editar Curso";

				$("#modalNewCourse").modal('show');

			},
			resetForm(){

				this.modalCourse = {
					name: '',
					date: '',
          validity: '',
          file: ''
				};
				this.errors = [];

				this.isEditColaboratorCourse = false;
				this.colaboratorCourseID = "";
				this.modalCourseTitle = "Novo Curso";

			}
		}
	}
</script>
