<template>
	<div>

		<!-- filter -->
		<div class="row">
			<div class="col-md-12">
				<div class="card card-body">
					<div class="row">

						<!-- company id -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Empresa</label>
								<v-select :options="filterCompanies" label="corporate_name" v-bind:class="{'is-invalid': errors.company}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.company_id" @input="validateCompany()"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.company" >
									<p>{{ errors.company[0] }}</p>
								</div>
							</div>
						</div>

						<!-- contract -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Contrato</label>
								<v-select :options="filterContracts" label="corporate_name" v-bind:class="{'is-invalid': errors.contract}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.contract_id" @input="validateContract()"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.contract" >
									<p>{{ errors.contract[0] }}</p>
								</div>
							</div>
						</div>

						<!-- work_place_id -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
								<v-select :options="filterWorkplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.workplace}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.work_place_id" @input="validateWorkplace()"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.workplace" >
									<p>{{ errors.workplace[0] }}</p>
								</div>
							</div>
						</div>

            <!-- sublocal -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-sublocal">Sublocal</label>
								<v-select :options="filterSublocal" label="corporate_name" v-bind:class="{'is-invalid': errors.sublocal}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.sublocal_id" @input="validateSublocal()"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.sublocal" >
									<p>{{ errors.sublocal[0] }}</p>
								</div>
							</div>
						</div>

						<!-- certifications -->
						<div class="col-md-12 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-certification_id">Certificações</label>
								<v-select :options="filterCertifications" multiple label="name" v-bind:class="{'is-invalid': errors.certification_id}" :reduce="name => name.id" v-model="filterItems.certification_id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.certification_id" >
									<p>{{ errors.certification_id[0] }}</p>
								</div>
							</div>
						</div>
						
						<!-- colaborators -->
						<div class="col-md-8 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-colaborator_id">Colaboradores</label>
								<v-select :options="filterColaborators" multiple label="name" v-bind:class="{'is-invalid': errors.colaborator_id}" :reduce="name => name.colaborator_id" v-model="filterItems.colaborator_id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.colaborator_id">
									<p>{{ errors.colaborator_id[0] }}</p>
								</div>
							</div>
						</div>

						<!-- status colaborator -->
						<div class="col-md-2 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-colaborator_status">Status do Colaborador:</label>
								<v-select :options="statusColaboratorOptions" label="label" v-bind:class="{'is-invalid': errors.colaborator_status}" :reduce="label => label.id" v-model="filterItems.colaborator_status"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.colaborator_status">
									<p>{{ errors.colaborator_status[0] }}</p>
								</div>
							</div>
						</div>

						<div class="col-md-2 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-status_certification">Dias para expirar:</label>
								<input type="number" min="0" class="form-control" v-bind:class="{'is-invalid': errors.expire_days}" name="expire_days" id="expire_days" v-model="filterItems.expire_days" />
								<div class="invalid-feedback d-block" v-if="errors.expire_days">
									<p>{{ errors.expire_days[0] }}</p>
								</div>
							</div>
						</div>
						
						<div class="col-md-12 mt-2 text-right">
							<button class="btn btn-success" @click="generateReport()">GERAR RELATÓRIO</button>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- end filter -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		data() {
			return {
				filterCompanies: [],
        filterContracts: [],
				filterWorkplaces: [],
				filterSublocal: [],
        filterCertifications: [],
        filterColaborators: [],
				filterItems: {
					type: 'last',
					company_id: null,
					contract_id: null,
					work_place_id: null,
					sublocal_id: null,
					certification_id: [],
          colaborator_id: [],
					colaborator_status: 'all',
					expire_days: null
				},
				statusColaboratorOptions: [{
					id: 'active',
					label: 'Ativo'
				},{
					id: 'all',
					label: 'Todos'
				},{
					id: 'inactive',
					label: 'Inativo'
				}],
				fullPage: true,
				errors: [],
				loading: false,
				tempColaborators: [],
				userCan: ''
			}
		},
		mounted() {
			this.loadCompanies();
			this.loadCertifications();
		},
		methods: {
      generateReport(){
        let _this = this;
				_this.loading = true;
				_this.errors = [];

				let dataFilter = {
					"company" : _this.filterItems.company_id,
					"contract": _this.filterItems.contract_id,
					"workplace" : _this.filterItems.work_place_id,
					"sublocal": _this.filterItems.sublocal_id,
					"certification" : _this.filterItems.certification_id,
					"colaborator": _this.filterItems.colaborator_id,
					"colaborator_status": _this.filterItems.colaborator_status,
					"expire_days": _this.filterItems.expire_days
				};

				//mount url
				var url = "/admin/reports/certifications/full?filters="+JSON.stringify(dataFilter);

				window.open(url,'_blank');

				_this.loading = false;

				_this.$swal({
					title: "Sucesso",
					text: "Relatório gerado com Sucesso",
					icon: "success"
				});

      },
      loadCertifications() {
				let _this = this;
        _this.filterCertifications = [];
				axios.get("/admin/certifications").then((res) => {
					_this.filterCertifications = _this.filterCertifications.concat(res.data);
				}).catch((err) => {
				});
			},
      loadColaborators() {
				let _this = this;
				_this.filterColaborators = [];
				_this.tempColaborators = [];
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((row) => {
						row.name = row.colaborators.users.profile.name;
						_this.tempColaborators.push(row);
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.filterContracts = [];
				axios.get("/admin/companycontracts/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.filterContracts.push({
							id: rowContract.contract_id,
							corporate_name: rowContract.contracts.corporate_name
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					_this.filterCompanies = _this.filterCompanies.concat(res.data);
				}).catch((err) => {
				});
			},
			validateCompany(){
				this.loadColaborators();
				this.loadContracts();
			},
			validateContract(){
				
				let _this = this;
				
				_this.loading = true;

        _this.filterWorkplaces = [];
        axios.get("/admin/companycontracts/"+_this.filterItems.contract_id+'/workplaces').then((res) => {
          res.data.data.map((rowReturn) => {
            _this.filterWorkplaces = _this.filterWorkplaces.concat(rowReturn.work_places);
          });
        }).catch((err) => {
        });
        this.loading = false;

			},
			validateWorkplace(){
				let _this = this;

        _this.filterSublocal = [];
        axios.get("/admin/workplacesublocal/"+_this.filterItems.work_place_id+"/search").then((res) => {
          res.data.map((rowSublocal) => {
            _this.filterSublocal.push({
              corporate_name: rowSublocal.sublocal.corporate_name,
              id: rowSublocal.sublocal_id
            });
          });
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			},
			validateSublocal(){
				let _this = this;
				_this.loading = true;

				axios.get("/admin/companyworkplacecolaborators/"+_this.filterItems.company_id+"/search").then((res) => {		

					res.data.map((itemWorkplaceColaborator) => {

						if(itemWorkplaceColaborator.work_place_id == _this.filterItems.work_place_id){

							//read all colaborators
							_this.tempColaborators.map((itemColaborator) => {
								if(itemColaborator.colaborator_id == itemWorkplaceColaborator.colaborator_id){
									if(!_this.filterColaborators.some(data => data.id === itemColaborator.id)){
										_this.filterColaborators.push(itemColaborator);   
									}
								}
							});

						}

					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});

			}
		}
	}
</script>
