export default {
    methods: {
      _api_validateError(err){
        
        var errorMessage;

        //validate status
        if (err.response.status == 422){

          //set error message
          errorMessage = "Campos inválidos!"

        } else {

          //set error message
          errorMessage = err.response.data.message;

        }
        //end validate status
   
        //show error alert
        this.$swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error'
        });

      },
      _api_validateSuccess(res, redirect = true){

        //show confirm alert
        this.$swal({
          title: 'Sucesso',
          text: res.data.message,
          icon: 'success'
        }).then((button) => {
          if(button.isConfirmed || button.isDismissed){
            if(redirect){
              location.href = res.data.route;
            }
          }
        });

      }
    }  
}