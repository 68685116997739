<template>
    <div>
        <!-- widgets -->
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-secondary">
                        <i class="fas fa-tasks"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4>Em Aberto</h4>
                        </div>
                        <div class="card-body">
                            <div
                                v-if="widgets.open == 0 && loading"
                                class="spinner-border spinner-border spinner-border-sm"
                                role="status"
                            ></div>
                            <div v-else>{{ widgets.open }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-warning">
                        <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4>Em Andamento</h4>
                        </div>
                        <div class="card-body">
                            <div
                                v-if="widgets.opening == 0 && loading"
                                class="spinner-border spinner-border spinner-border-sm"
                                role="status"
                            ></div>
                            <div v-else>{{ widgets.opening }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-danger">
                        <i class="fas fa-times-circle"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4>Não Executada</h4>
                        </div>
                        <div class="card-body">
                            <div
                                v-if="widgets.not == 0 && loading"
                                class="spinner-border spinner-border spinner-border-sm"
                                role="status"
                            ></div>
                            <div v-else>{{ widgets.not }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-success">
                        <i class="fas fa-check"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4>Concluída</h4>
                        </div>
                        <div class="card-body">
                            <div
                                v-if="widgets.done == 0 && loading"
                                class="spinner-border spinner-border spinner-border-sm"
                                role="status"
                            ></div>
                            <div v-else>{{ widgets.done }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end widgets -->

        <div class="row section-top">
            <div class="col-md-12 pad0">
                <p class="section-lead">
                    Lista de todas Atividades cadastradas!
                </p>
                <a
                    href="/admin/activities/create"
                    class="btn btn-outline-success float-right"
                    ><i class="fas fa-plus"></i>&nbsp;Nova</a
                >
                <a
                    class="btn btn-outline-warning float-right"
                    data-toggle="collapse"
                    href="#panelFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="panelFilter"
                    ><i class="fas fa-filter"></i>&nbsp;Filtrar</a
                >
                <div class="dropdown float-right">
                    <button
                        class="btn btn-outline-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i class="fas fa-download"></i>
                        Exportar
                    </button>
                    <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <a
                            class="dropdown-item"
                            href="#"
                            @click="exportData('pdf')"
                            >PDF</a
                        >
                        <a
                            class="dropdown-item"
                            href="#"
                            @click="exportData('csv')"
                            >Excel</a
                        >
                        <div class="dropdown-divider"></div>
                        <a
                            class="dropdown-item"
                            href="#"
                            @click="
                                $refs.gridfullexport.showModal(
                                    'activities',
                                    'activities'
                                )
                            "
                            >Completo</a
                        >
                    </div>
                </div>
                <a
                    class="btn btn-outline-secondary float-right"
                    data-toggle="modal"
                    data-target="#modalImport"
                    ><i class="fas fa-upload"></i>&nbsp;Importar</a
                >
            </div>
        </div>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <div class="collapse" id="panelFilter">
                    <div class="card card-body">
                        <div class="row">
                            <!-- company id -->
                            <div class="col-md-6 mt-2">
                                <div class="form-group">
                                    <label
                                        class="form-control-label"
                                        for="input-work_place_id"
                                        >Empresa</label
                                    >
                                    <v-select
                                        :options="filterCompanies"
                                        label="corporate_name"
                                        v-bind:class="{
                                            'is-invalid': errors.company
                                        }"
                                        :reduce="
                                            corporate_name => corporate_name.id
                                        "
                                        v-model="filterItems.company_id"
                                        @input="validateFilterCompany()"
                                    ></v-select>
                                    <div
                                        class="invalid-feedback d-block"
                                        v-if="errors.company"
                                    >
                                        <p>{{ errors.company[0] }}</p>
                                    </div>
                                </div>
                            </div>

                            <!-- contract -->
                            <div class="col-md-6 mt-2">
                                <div class="form-group">
                                    <label
                                        class="form-control-label"
                                        for="input-work_place_id"
                                        >Contrato</label
                                    >
                                    <v-select
                                        :options="filterContracts"
                                        label="corporate_name"
                                        v-bind:class="{
                                            'is-invalid': errors.contract
                                        }"
                                        :reduce="
                                            corporate_name => corporate_name.id
                                        "
                                        v-model="filterItems.contract_id"
                                    ></v-select>
                                    <div
                                        class="invalid-feedback d-block"
                                        v-if="errors.contract"
                                    >
                                        <p>{{ errors.contract[0] }}</p>
                                    </div>
                                </div>
                            </div>

                            <!-- work_place_id -->
                            <div class="col-md-6 mt-2">
                                <div class="form-group">
                                    <label
                                        class="form-control-label"
                                        for="input-work_place_id"
                                        >Local de Trabalho</label
                                    >
                                    <v-select
                                        :options="filterWorkplaces"
                                        label="corporate_name"
                                        v-bind:class="{
                                            'is-invalid': errors.workplace
                                        }"
                                        :reduce="
                                            corporate_name => corporate_name.id
                                        "
                                        v-model="filterItems.work_place_id"
                                        @input="validateFilterWorkplace()"
                                    ></v-select>
                                    <div
                                        class="invalid-feedback d-block"
                                        v-if="errors.workplace"
                                    >
                                        <p>{{ errors.workplace[0] }}</p>
                                    </div>
                                </div>
                            </div>

                            <!-- sublocal -->
                            <div class="col-md-6 mt-2">
                                <div class="form-group">
                                    <label
                                        class="form-control-label"
                                        for="input-sublocal"
                                        >Sublocal</label
                                    >
                                    <v-select
                                        :options="filterSublocal"
                                        label="corporate_name"
                                        v-bind:class="{
                                            'is-invalid': errors.sublocal
                                        }"
                                        :reduce="
                                            corporate_name => corporate_name.id
                                        "
                                        v-model="filterItems.sublocal_id"
                                        @input="validateFilterSublocal()"
                                    ></v-select>
                                    <div
                                        class="invalid-feedback d-block"
                                        v-if="errors.sublocal"
                                    >
                                        <p>{{ errors.sublocal[0] }}</p>
                                    </div>
                                </div>
                            </div>

                            <!-- colaborator_id -->
                            <div class="col-md-6 mt-2">
                                <label
                                    class="form-control-label"
                                    for="input-colaborator_id"
                                    >Colaborador</label
                                >
                                <v-select
                                    :options="filterColaborators"
                                    label="name"
                                    v-model="filterItems.colaborator_id"
                                    :reduce="name => name.colaborator_id"
                                ></v-select>
                            </div>

                            <!-- colaborator_group_id -->
                            <div class="col-md-6 mt-2">
                                <label
                                    class="form-control-label"
                                    for="input-colaborator_group_id"
                                    >Grupo de Colaboradores</label
                                >
                                <v-select
                                    :options="filterColaboratorGroups"
                                    label="name"
                                    v-model="filterItems.colaborator_group_id"
                                    :reduce="name => name.id"
                                ></v-select>
                            </div>

                            <!-- title -->
                            <div class="col-md-8 mt-4">
                                <label
                                    class="form-control-label"
                                    for="input-title"
                                    >Título</label
                                >
                                <input
                                    type="text"
                                    id="input-title"
                                    class="form-control"
                                    placeholder="Título"
                                    v-model="filterItems.title"
                                    required="false"
                                />
                            </div>

                            <!-- expected_date -->
                            <div class="col-md-2 mt-4">
                                <label
                                    class="form-control-label"
                                    for="input-expected_date"
                                    >Data Prevista</label
                                >
                                <input
                                    type="date"
                                    id="input-expected_date"
                                    class="form-control"
                                    placeholder="Data prevista"
                                    v-model="filterItems.expected_date"
                                    required="false"
                                />
                            </div>

                            <!-- expected_time -->
                            <div class="col-md-2 mt-4">
                                <label
                                    class="form-control-label"
                                    for="input-expected_time"
                                    >Hora Prevista</label
                                >
                                <input
                                    type="time"
                                    id="input-expected_time"
                                    class="form-control"
                                    placeholder="Hora Prevista"
                                    v-model="filterItems.expected_time"
                                    required="false"
                                />
                            </div>

                            <!-- item_id -->
                            <div class="col-md-8 mt-4">
                                <label
                                    class="form-control-label"
                                    for="input-item_id"
                                    >Item</label
                                >
                                <v-select
                                    :options="items"
                                    label="name"
                                    :reduce="name => name.id"
                                    v-model="filterItems.item_id"
                                ></v-select>
                            </div>

                            <!-- status id -->
                            <div class="col-md-4 mt-4">
                                <label
                                    class="form-control-label"
                                    for="input-item_id"
                                    >Status</label
                                >
                                <v-select
                                    :options="filterStatus"
                                    label="name"
                                    :reduce="name => name.id"
                                    v-model="filterItems.status_id"
                                ></v-select>
                            </div>

                            <div class="col-md-12 mt-4 text-right">
                                <button
                                    class="btn btn-success"
                                    @click="loadItems()"
                                >
                                    FILTRAR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end filters -->

        <!-- grid -->
        <div class="row">
            <div class="col">
                <!-- table -->
                <div class="card p-4">
                    <vue-good-table
                        v-if="activitiesItems.length"
                        :columns="tableColumns"
                        :rows="activitiesItems"
                        :pagination-options="{
                            enabled: true,
                            perPage: 500
                        }"
                        :search-options="{
                            enabled: true,
                            placeholder: 'Buscar..'
                        }"
                        styleClass="vgt-table"
                    >
                        <template slot="pagination-bottom">
                            <div
                                style="display: flex; justify-content: space-between; align-items: center;"
                            >
                                <div
                                    style="display: flex; align-items: center; justify-content: center; margin-top: 8px;"
                                >
                                    <label
                                        style="font-size: 12px; margin-top: 8px"
                                    >
                                        {{ tablePagination.rowsPerPageLabel }}:
                                    </label>
                                    <select
                                        v-model="currentPerPage"
                                        @change="
                                            event =>
                                                changePerPage(
                                                    event.target.value
                                                )
                                        "
                                        style="margin-left: 8px; font-size: 12px; font-weight: bold; border: none;"
                                    >
                                        <option
                                            v-for="option in perPageOptions"
                                            :key="option"
                                            :value="option"
                                            >{{ option }}</option
                                        >
                                    </select>
                                </div>

                                <div
                                    style="display: flex; align-items: center; margin-top: 8px;"
                                >
                                    <span style="font-size: 12px;"
                                        >{{ from }} - {{ to }} de
                                        {{ total }}</span
                                    >

                                    <div
                                        style="display: flex; gap: 8px; margin-left: 16px;"
                                    >
                                        <div
                                            @click="prevPage"
                                            :style="{
                                                cursor: canGoPrev
                                                    ? 'pointer'
                                                    : 'not-allowed',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                color: canGoPrev
                                                    ? 'inherit'
                                                    : '#ccc'
                                            }"
                                            :disabled="!canGoPrev"
                                        >
                                            ◀
                                            {{ tablePagination.prevLabel }}
                                        </div>
                                        <div
                                            @click="nextPage"
                                            :style="{
                                                cursor: canGoNext
                                                    ? 'pointer'
                                                    : 'not-allowed',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                color: canGoNext
                                                    ? 'inherit'
                                                    : '#ccc'
                                            }"
                                            :disabled="!canGoNext"
                                        >
                                            {{ tablePagination.nextLabel }}
                                            ▶
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == '_colaborator'">
                                {{ validateGridColaborators(props.row) }}
                            </span>
                            <span
                                v-if="
                                    props.column.field == '_colaborator_groups'
                                "
                            >
                                {{ validateGridGroupColaborators(props.row) }}
                            </span>
                            <span v-if="props.column.field == '_status'">
                                {{ validateGridStatus(props.row) }}
                            </span>
                            <span v-if="props.column.field == '_done'">
                                {{
                                    props.row.done_datetime
                                        | moment("DD/MM/YYYY HH:mm:ss")
                                }}
                            </span>
                            <span v-if="props.column.field == 'actions'">
                                <div>
                                    <button
                                        class="btn btn-sm btn-outline-secondary"
                                        title="Visualizar"
                                        @click="viewData(props.row)"
                                    >
                                        <i class="far fa-eye"></i>
                                    </button>
                                    <button
                                        class="btn btn-sm btn-outline-success"
                                        v-if="userCan.edit"
                                        @click="editData(props.row)"
                                        title="Editar"
                                    >
                                        <i class="far fa-edit"></i>
                                    </button>
                                </div>
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                    <div
                        v-else-if="!activitiesLoading"
                        class="text-center p-3 text-muted"
                    >
                        <h5>Nenhuma atividade cadastrada!</h5>
                    </div>
                </div>

                <div
                    class="text-center p-4 text-muted"
                    v-if="loading || activitiesLoading"
                >
                    <h5>Carregando...</h5>
                    <p>Aguarde até que os dados sejam carregados...</p>
                </div>
            </div>

            <gridfullexport-component
                ref="gridfullexport"
            ></gridfullexport-component>

            <vue-loading
                color="#212121"
                :active.sync="loading"
                :can-cancel="false"
                :is-full-page="fullPage"
            ></vue-loading>
        </div>

        <!-- modal import -->
        <div
            class="modal fade"
            id="modalImport"
            tabindex="-1"
            aria-labelledby="modalImportLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalImportLabel">
                            Importar Atividades
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form v-on:submit.prevent="importItems">
                            <div class="row">
                                <div class="col-md-12">
                                    <a
                                        href="https://mvtecnologia.s3.us-east-1.amazonaws.com/atividades.xlsx"
                                        target="_blank"
                                        class="btn btn-outline-secondary btn-outline-default"
                                        >Baixar Modelo de Formato do Arquivo</a
                                    >
                                </div>

                                <!-- type -->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-icon"
                                            >Selecione o arquivo</label
                                        >
                                        <div class="custom-file">
                                            <input
                                                type="file"
                                                class="custom-file-input"
                                                v-bind:class="{
                                                    'is-invalid': errors.icon
                                                }"
                                                id="icon"
                                                name="icon"
                                                accept="*"
                                                ref="icon"
                                                lang="pt"
                                                v-on:change="
                                                    handleFileTemplateUpload()
                                                "
                                            />
                                            <label
                                                class="custom-file-label"
                                                for="icon"
                                                >{{ fileImportName }}</label
                                            >
                                        </div>
                                        <div
                                            class="invalid-feedback d-block"
                                            v-if="errors.icon"
                                        >
                                            <p>{{ errors.icon[0] }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- end type -->
                            </div>

                            <div class="text-right">
                                <button type="submit" class="btn btn-success">
                                    IMPORTAR
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "../../apiservice.js";
import ExportGrid from "../../exportgrid.js";
export default {
    mixins: [ApiService, ExportGrid],
    props: ["permissions"],
    data() {
        return {
            filterColaborators: [],
            filterColaboratorGroups: [],
            filterCompanies: [],
            filterContracts: [],
            filterWorkplaces: [],
            filterSublocal: [],
            filterStatus: [],
            fileImportName: "",
            importFile: "",
            items: [],
            status: [],
            workplaces: [],
            templates: [],
            tempColaborators: [],
            tempColaboratorsGroups: [],
            tempItems: [],
            activitiesItems: [],
            fullPage: true,
            errors: [],
            loading: false,
            activitiesLoading: false,
            widgets: {
                open: 0,
                opening: 0,
                done: 0,
                not: 0
            },
            tablePagination: {
                enabled: true,
                nextLabel: "próxima",
                prevLabel: "anterior",
                rowsPerPageLabel: "Registros por página",
                tofLabel: "de",
                pageLabel: "página",
                dropdownAllowAll: false
            },
            perPageOptions: [10, 50, 100, 500],
            currentPage: 1,
            currentPerPage: 10,
            total: null,
            from: 0,
            to: 0,
            tableColumns: [
                {
                    label: "ID",
                    field: "id",
                    type: "number"
                },
                {
                    label: "Título",
                    field: "title"
                },
                {
                    label: "Empresa",
                    field: "company.corporate_name"
                },
                {
                    label: "Sublocal de Trabalho",
                    field: "sublocal.corporate_name"
                },
                {
                    label: "Colaborador",
                    field: "_colaborator"
                },
                {
                    label: "Grupo de Colaboradores",
                    field: "_colaborator_groups"
                },
                {
                    label: "Data Prevista",
                    field: "expected_date",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd",
                    dateOutputFormat: "dd/MM/yyyy"
                },
                {
                    label: "Hora Prevista",
                    field: "expected_time"
                },
                {
                    label: "Status",
                    field: "_status"
                },
                {
                    label: "PIN Mensagens",
                    field: "pin_message_validated_at",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
                    dateOutputFormat: "dd/MM/yyyy HH:mm:ss"
                },
                {
                    label: "PIN EPIs",
                    field: "pin_epi_validated_at",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
                    dateOutputFormat: "dd/MM/yyyy HH:mm:ss"
                },
                {
                    label: "Data e Hora (Concluída)",
                    field: "_done"
                },
                {
                    label: "Ações",
                    field: "actions",
                    sortable: false,
                    width: "100px"
                }
            ],
            filterItems: {},
            userCan: ""
        };
    },
    mounted() {
        this.loadFilterCompanies();
        this.loadFilterStatus();
        //this.loadFilterItems();

        this.userCan = JSON.parse(this.permissions);
        if (this.userCan.list) {
            this.loadItems();
        }
    },
    watch: {
        currentPage: "loadItems",
        currentPerPage: "loadItems"
    },
    computed: {
        canGoNext() {
            return this.currentPage * this.currentPerPage < this.total;
        },
        canGoPrev() {
            return this.currentPage > 1;
        }
    },
    methods: {
        changePerPage(value) {
            this.currentPerPage = value;
        },
        nextPage() {
            if (this.canGoNext) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.canGoPrev) {
                this.currentPage--;
            }
        },
        handleFileTemplateUpload() {
            this.importFile = this.$refs.icon.files[0];
            this.fileImportName = this.$refs.icon.files[0].name;
        },
        loadItems() {
            let _this = this;
            _this.activitiesItems = [];
            _this.activitiesLoading = true;

            let dataFilter = {
                company_id: _this.filterItems.company_id,
                contract_id: _this.filterItems.contract_id,
                work_place_id: _this.filterItems.work_place_id,
                sublocal_id: _this.filterItems.sublocal_id,
                title: _this.filterItems.title,
                expected_date: _this.filterItems.expected_date,
                expected_time: _this.filterItems.expected_time,
                time: _this.filterItems.time,
                duration: _this.filterItems.duration,
                item_id: _this.filterItems.item_id,
                status_id: _this.filterItems.status_id,
                colaborator_id: _this.filterItems.colaborator_id,
                colaborator_group_id: _this.filterItems.colaborator_group_id,
                last: ""
            };

            axios
                .post("/admin/activities/filter", dataFilter, {
                    params: {
                        page: _this.currentPage,
                        perPage: _this.currentPerPage
                    }
                })
                .then(res => {
                    const { data, total, from, to } = res.data.data;
                    _this.activitiesItems = [...data];
                    _this.total = total;
                    _this.from = from;
                    _this.to = to;

                    _this.widgets.open = 0;
                    _this.widgets.opening = 0;
                    _this.widgets.done = 0;
                    _this.widgets.not = 0;

                    (data || []).map(row => {
                        if (row.activity_status_id == 2) {
                            _this.widgets.open += 1;
                        } else if (row.activity_status_id == 5) {
                            _this.widgets.opening += 1;
                        } else if (
                            row.activity_status_id == 6 ||
                            row.activity_status_id == 9
                        ) {
                            _this.widgets.done += 1;
                        } else if (row.activity_status_id == 8) {
                            _this.widgets.not += 1;
                        }
                    });
                    _this.activitiesLoading = false;
                })
                .catch(err => {
                    _this.activitiesLoading = false;
                });
        },
        loadFilterItems() {
            let _this = this;
            _this.tempItems = [];
            _this.items = [];
            _this.loading = true;

            let dataFilter = {
                "company_id" : _this.filterItems.company_id,
                "contract_id" : _this.filterItems.contract_id,
                "work_place_id" : _this.filterItems.work_place_id,
                "sublocal_id" : _this.filterItems.sublocal_id
            };

            axios.post("/admin/items/select", dataFilter).then((res) => {
                _this.tempItems = _this.tempItems.concat(res.data.data);
                _this.items.push({
                    name: "Genérico",
                    id: 0
                });
                _this.items.push(...res.data.data);
                _this.loading = false;
            })
            .catch(err => {
                _this.loading = false;
            });
        },
        loadFilterStatus() {
            let _this = this;
            _this.activitiesItems = [];
            _this.loading = true;
            axios
                .get("/admin/activitystatus")
                .then(res => {
                    _this.filterStatus = _this.filterStatus.concat(res.data);
                    _this.loading = false;
                })
                .catch(err => {
                    _this.loading = false;
                });
        },
        loadFilterColaborators() {
            let _this = this;
            _this.filterColaborators = [];
            _this.tempColaborators = [];
            _this.loading = true;
            axios
                .get(
                    "/admin/companycolaborators/" +
                        _this.filterItems.company_id +
                        "/search"
                )
                .then(res => {
                    res.data.map(row => {
                        row.name = row.colaborators.users?.profile?.name;
                        _this.tempColaborators.push(row);
                    });
                    _this.loading = false;
                })
                .catch(err => {
                    _this.loading = false;
                });
        },
        loadFilterColaboratorGroups() {
            let _this = this;
            _this.filterColaboratorGroups = [];
            _this.tempColaboratorsGroups = [];
            _this.loading = true;
            axios
                .get(
                    "/admin/colaboratorgroups/" +
                        _this.filterItems.company_id +
                        "/search"
                )
                .then(res => {
                    _this.tempColaboratorsGroups = _this.tempColaboratorsGroups.concat(
                        res.data
                    );
                    _this.loading = false;
                })
                .catch(err => {
                    _this.loading = false;
                });
        },
        loadFilterContracts() {
            let _this = this;
            _this.filterContracts = [];
            _this.loading = true;
            axios
                .get(
                    "/admin/companycontracts/" +
                        _this.filterItems.company_id +
                        "/search"
                )
                .then(res => {
                    (res.data || []).map(rowContract => {
                        _this.filterContracts.push(rowContract.contracts);
                    });
                    _this.loading = false;
                })
                .catch(err => {
                    _this.loading = false;
                });
        },
        loadFilterCompanies() {
            let _this = this;
            axios
                .get("/admin/companies")
                .then(res => {
                    _this.filterCompanies = _this.filterCompanies.concat(
                        res.data
                    );
                })
                .catch(err => {});
        },
        exportData(type) {
            var tempForm = $("#vgt-table");
            if (type == "pdf") {
                this._exportPDF("Atividades", [12], tempForm, [
                    "ID",
                    "Título",
                    "Empresa",
                    "Sublocal de Trabalho",
                    "Colaborador",
                    "Grupo de Colaboradores",
                    "Data Prevista",
                    "Hora Prevista",
                    "Status",
                    "PIN Mensagens",
                    "PIN EPIs",
                    "Data e Hora (Concluída)"
                ]);
            } else {
                this._exportExcel("Atividades", [12], tempForm, [
                    "ID",
                    "Título",
                    "Empresa",
                    "Sublocal de Trabalho",
                    "Colaborador",
                    "Grupo de Colaboradores",
                    "Data Prevista",
                    "Hora Prevista",
                    "Status",
                    "PIN Mensagens",
                    "PIN EPIs",
                    "Data e Hora (Concluída)"
                ]);
            }
        },
        deleteData(ID, index) {
            let _this = this;

            //show alert confirmation
            _this
                .$swal({
                    title: "Excluir activities",
                    text: "Tem certeza que deseja excluir esse(a) activities?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Excluir",
                    cancelButtonText: "Cancelar"
                })
                .then(alertConfirm => {
                    //validate isConfirmed
                    if (alertConfirm.isConfirmed) {
                        //remove
                        axios
                            .delete("/admin/activities/" + ID)
                            .then(res => {
                                //_this.activities.splice(index, 1);

                                //call function in ApiService to validate success
                                _this._api_validateSuccess(res, true);
                            })
                            .catch(err => {
                                _this.loading = false;

                                //call function in ApiService to validate error
                                _this._api_validateError(err);
                            });
                    } //end validate isConfirmed
                });
            //end alert confirmation
        },
        filter() {
            let _this = this;
            _this.loading = true;
            let dataFilter = {
                company_id: _this.filterItems.company_id,
                contract_id: _this.filterItems.contract_id,
                work_place_id: _this.filterItems.work_place_id,
                sublocal_id: _this.filterItems.sublocal_id,
                title: _this.filterItems.title,
                expected_date: _this.filterItems.expected_date,
                expected_time: _this.filterItems.expected_time,
                time: _this.filterItems.time,
                duration: _this.filterItems.duration,
                item_id: _this.filterItems.item_id,
                status_id: _this.filterItems.status_id,
                colaborator_id: _this.filterItems.colaborator_id,
                colaborator_group_id: _this.filterItems.colaborator_group_id,
                last: ""
            };
            axios
                .post("/admin/activities/filter", dataFilter, {
                    params: {
                        page: _this.currentPage,
                        perPage: _this.currentPerPage
                    }
                })
                .then(res => {
                    _this.loading = false;

                    const { data, total, from, to } = res.data.data;
                    _this.activitiesItems = [...data];
                    _this.total = total;
                    _this.from = from;
                    _this.to = to;

                    _this.activitiesItems = [];
                    _this.activitiesItems = data;
                })
                .catch(err => {
                    _this.loading = false;

                    //call function in ApiService to validate error
                    _this._api_validateError(err);
                });
        },
        editData(item) {
            location.href = "/admin/activities/" + item.id + "/edit";
        },
        viewData(item) {
            location.href = "/admin/activities/" + item.id;
        },
        resetForm() {
            this.activity = {
                title: "",
                description: "",
                details: "",
                expected_date: "",
                expected_time: "",
                time: "",
                duration: "",
                item_id: "",
                work_place_id: "",
                template_id: "",
                colaborator_id: "",
                colaborator_group_id: ""
            };
        },
        validateGridColaborators(item) {
            var returnMessage = "";
            (item.colaborators || []).map(colaborator => {
                returnMessage +=
                    (colaborator?.colaborator?.users?.profile?.name || "") +
                    (item.colaborators.length > 1 ? ", " : "");
            });
            return returnMessage;
        },
        validateGridGroupColaborators(item) {
            let _this = this;
            var returnMessage = "";
            (item.colaborator_groups || []).map(rowColaboratorGroup => {
                returnMessage += rowColaboratorGroup.group.name;
            });
            return returnMessage;
        },
        validateGridStatus(item) {
            let _this = this;
            var returnMessage = "";
            (_this.filterStatus || []).map(row => {
                if (row.id == item.activity_status_id) {
                    returnMessage = row.name;
                }
            });
            return returnMessage;
        },
        validateFilterCompany() {
            let _this = this;
            _this.loading = true;

            _this.filterWorkplaces = [];
            axios
                .get(
                    "/admin/companyworkplaces/" +
                        _this.filterItems.company_id +
                        "/search"
                )
                .then(res => {
                    (res.data || []).map(rowReturn => {
                        _this.filterWorkplaces = _this.filterWorkplaces.concat(
                            rowReturn.work_places
                        );
                    });
                    _this.loadFilterContracts();
                    _this.loadFilterColaborators();
                    _this.loadFilterColaboratorGroups();
                })
                .catch(err => {});
            this.loading = false;
        },
        validateFilterWorkplace() {
            let _this = this;

            _this.filterSublocal = [];
            axios
                .get(
                    "/admin/workplacesublocal/" +
                        _this.filterItems.work_place_id +
                        "/search"
                )
                .then(res => {
                    (res.data || []).map(rowSublocal => {
                        _this.filterSublocal.push({
                            corporate_name: rowSublocal.sublocal.corporate_name,
                            id: rowSublocal.sublocal_id
                        });
                    });
                    _this.loading = false;
                })
                .catch(err => {
                    _this.loading = false;
                });
        },
        validateFilterSublocal() {
            let _this = this;
            _this.loading = true;
            _this.loadFilterItems();

            axios
                .get(
                    "/admin/companyworkplacecolaborators/" +
                        _this.filterItems.company_id +
                        "/search"
                )
                .then(res => {
                    (res.data || []).map(itemWorkplaceColaborator => {
                        if (
                            itemWorkplaceColaborator.work_place_id ==
                            _this.filterItems.work_place_id
                        ) {
                            //read all colaborators
                            (_this.tempColaborators || []).map(
                                itemColaborator => {
                                    if (
                                        itemColaborator.colaborator_id ==
                                        itemWorkplaceColaborator.colaborator_id
                                    ) {
                                        if (
                                            !_this.filterColaborators.some(
                                                data =>
                                                    data.id ===
                                                    itemColaborator.id
                                            )
                                        ) {
                                            _this.filterColaborators.push(
                                                itemColaborator
                                            );
                                        }
                                    }
                                }
                            );

                            //read all colaborator groups
                            (_this.tempColaboratorsGroups || []).map(
                                itemColaboratorGroup => {
                                    if (
                                        itemColaboratorGroup.id ==
                                        itemWorkplaceColaborator.colaborator_group_id
                                    ) {
                                        if (
                                            !_this.filterColaboratorGroups.some(
                                                data =>
                                                    data.id ===
                                                    itemColaboratorGroup.id
                                            )
                                        ) {
                                            _this.filterColaboratorGroups.push(
                                                itemColaboratorGroup
                                            );
                                        }
                                    }
                                }
                            );

                            // (_this.tempItems || []).map(rowItem => {
                            //     if (
                            //         rowItem.inventory !== null &&
                            //         rowItem.inventory !== undefined
                            //     ) {
                            //         if (
                            //             rowItem.inventory.work_place_id ==
                            //             _this.filterItems.sublocal_id
                            //         ) {
                            //             var exists = false;
                            //             (_this.items || []).map(rowNewItem => {
                            //                 if (rowNewItem.id == rowItem.id) {
                            //                     exists = true;
                            //                 }
                            //             });
                            //             if (exists == false) {
                            //                 _this.items.push({
                            //                     name: rowItem.name,
                            //                     id: rowItem.id
                            //                 });
                            //             }
                            //         }
                            //     }
                            // });
                        }
                    });
                    _this.loading = false;
                })
                .catch(err => {
                    _this.loading = false;
                });
        },
        importItems() {
            let _this = this;
            _this.errors = [];
            _this.message = "";
            _this.loading = true;

            let formData = new FormData();
            formData.append("file", _this.importFile);

            axios
                .post("/admin/activities/import", formData, {
                    headers: { "Content-Type": "multipart/form-data" }
                })
                .then(res => {
                    _this.loading = false;
                    _this.resetForm();

                    //call function in ApiService to validate success
                    _this._api_validateSuccess(res, true);
                })
                .catch(err => {
                    _this.loading = false;

                    if (err.response.data.errors) {
                        //set errors
                        _this.errors = err.response.data.errors;
                    }

                    //call function in ApiService to validate error
                    _this._api_validateError(err);
                });
        }
    }
};
</script>
