<template>
  <div class="row dashboard">
    <div class="col-md-12">

      <div class="row section-top">
        <div class="col-md-12 pad0">
          <p class="section-lead">DASHBOARD DE GERENCIAMENTO DAS ATIVIDADES</p>
          <a class="btn btn-outline-warning float-right text-warning" data-toggle="modal" data-target="#modalFilterDashboard"><i class="fas fa-filter"></i>&nbsp;Filtrar</a>
        </div>
      </div>

      <div class="row" style="margin: 5px 0 10px 0; padding: 10px; background-color: #FFFFFF; text-align: center;">
        <div class="col-md-12">
          Mostrando dados de <b>{{startDate | moment('DD/MM/YYYY')}}</b> até <b>{{endDate | moment('DD/MM/YYYY')}}</b>
        </div>
      </div>

      <!-- status -->
      <div class="row">

        <!-- status -->
        <div class="col-md-3">

          <div class="card card-default">
            <div class="card-header">
              STATUS DAS AÇÔES
            </div>
            <div class="card-body">
              <div class="row list-item" v-for="item, index in activityStatus" :key="index">
                <div class="col-md-8">
                  {{item.name}}
                </div>
                <div class="col-md-4">
                  {{item.total}}
                </div>
              </div>
            </div>
          </div>

          <div class="card card-minus">
            <div class="card-header">
              STATUS GERAL DAS AÇÔES
            </div>
            <div class="card-body">
              <div class="row list-item text-warning">
                <div class="col-md-8">
                  Em Aberto
                </div>
                <div class="col-md-4">
                  {{generalActive}}
                </div>
              </div>
              <div class="row list-item text-success">
                <div class="col-md-8">
                  Fechado
                </div>
                <div class="col-md-4">
                  {{generalClosed}}
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- end status -->

        <!-- chart -->
        <div class="col-md-5">
          <div class="card card-chart">
            <div class="card-header text-center d-block">
              Status das Atividades
            </div>
            <div class="card-body">
              <admin-donghnut-component
                class="chart-doughnut"
                v-if="showChartStatus"
                ref="doughnutStatus" 
                :chartdata="chartStatus.chartData" 
                :options="chartStatus.chartOptions"
              ></admin-donghnut-component>
              <p v-else class="chart-empty">
                Não existem dados
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card card-chart">
            <div class="card-header text-center d-block">
              Status Geral das Atividades
            </div>
            <div class="card-body">
              <admin-donghnut-component
              class="chart-doughnut"
                v-if="showChartStatusGeral"
                ref="doughnutStatus" 
                :chartdata="chartStatusGeral.chartData" 
                :options="chartStatusGeral.chartOptions"
              ></admin-donghnut-component>
              <p v-else class="chart-empty">
                Não existem dados
              </p>
            </div>
          </div>
        </div>
        <!-- end chart -->

      </div>
      <!-- end status -->

      <!-- year -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <admin-bar-component
                class="chart-bar"
                v-if="showChartStatusGeralYear"
                ref="barStatus" 
                :chartdata="chartStatusGeralYear.chartData" 
                :options="chartStatusGeralYear.chartOptions"
              ></admin-bar-component>
              <p v-else class="chart-empty">
                Não existem dados
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- year -->

    </div>

    <!-- modal -->
    <div class="modal fade" id="modalFilterDashboard" tabindex="-1" aria-labelledby="modalFilterDashboardLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalFilterDashboardLabel">Filtrar Dados da Dashboard</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="pad0" v-on:submit.prevent="filterData">

              <div class="row">
                <div class="col-md-6">
                  
                  <div class="row">

                    <!-- company id -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-work_place_id">Empresa</label>
                        <v-select :options="filterCompanies" label="corporate_name" v-bind:class="{'is-invalid': errors.company}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.company_id" @input="validateCompany()"></v-select>
                        <div class="invalid-feedback d-block" v-if="errors.company" >
                          <p>{{ errors.company[0] }}</p>
                        </div>
                      </div>
                    </div>

                    <!-- work_place_id -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
                        <v-select :options="filterWorkplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.workplace}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.work_place_id" @input="validateWorkplace()"></v-select>
                        <div class="invalid-feedback d-block" v-if="errors.workplace" >
                          <p>{{ errors.workplace[0] }}</p>
                        </div>
                      </div>
                    </div>

                    <!-- subsector_id -->
                    <!-- <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-subsector_id">Subsetor</label>
                        <v-select :options="filterSubsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="filterItems.subsector_id"></v-select>
                        <div class="invalid-feedback d-block" v-if="errors.subsector_id">
                          <p>{{ errors.subsector_id[0] }}</p>
                        </div>
                      </div>
                    </div> -->

                    <!-- date -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-start_date">Data Inicial</label>
                        <input class="form-control" label="Data" type="date" v-model="filterItems.start_date"/>
                        <div class="invalid-feedback d-block" v-if="errors.start_date">
                          <p>{{ errors.start_date[0] }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-end_date">Data Final</label>
                        <input class="form-control" label="Data" type="date" v-model="filterItems.end_date"/>
                        <div class="invalid-feedback d-block" v-if="errors.end_date">
                          <p>{{ errors.end_date[0] }}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row">

                     <!-- contract -->
                     <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-work_place_id">Contrato</label>
                        <v-select :options="filterContracts" label="corporate_name" v-bind:class="{'is-invalid': errors.contract}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.contract_id"></v-select>
                        <div class="invalid-feedback d-block" v-if="errors.contract" >
                          <p>{{ errors.contract[0] }}</p>
                        </div>
                      </div>
                    </div>

                    <!-- sublocal -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-sublocal">Sublocal</label>
                        <v-select :options="filterSublocal" label="corporate_name" v-bind:class="{'is-invalid': errors.sublocal}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.sublocal_id" @input="validateSublocal()"></v-select>
                        <div class="invalid-feedback d-block" v-if="errors.sublocal" >
                          <p>{{ errors.sublocal[0] }}</p>
                        </div>
                      </div>
                    </div>
                    
                    <!-- sector_id -->
                    <!-- <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-sector_id">Setor</label>
                        <v-select :options="filterSectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="filterItems.sector_id"></v-select>
                        <div class="invalid-feedback d-block" v-if="errors.sector_id">
                          <p>{{ errors.sector_id[0] }}</p>
                        </div>
                      </div>
                    </div> -->

                    <div class="col-md-12">
                      <div class="text-right" style="margin-top: 30px;">
                        <a class="btn btn-outline-secondary text-secondary" style="margin-right: 20px;" @click="clearFilter()">LIMPAR</a>
                        <button type="submit" class="btn btn-success">FILTRAR</button>
                      </div>
                    </div>

                  </div>
                  
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        activityStatus: [],
        chartStatusGeral: {
          chartData: {
            labels: ['Em Aberto', 'Fechado'],
            datasets: [{
              backgroundColor: ['#BDBDBD', '#43A047'],
              data: [0, 0]
            }]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false
          }
        },
        chartStatus: {
          chartData: {
            labels: ['Ativa', 'Inativa', 'Pausada', 'Em Andamento', 'Concluída'],
            datasets: [{
              backgroundColor: ['#BDBDBD', '#E46651', '#00D8FF', '#DD1B16', '#41B883'],
              data: [0, 0, 0, 0, 0]
            }]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false
          }
        },
        chartStatusGeralYear: {
          chartData: {
            labels: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'
            ],
            datasets: [
              {
                label: 'Total de Atividades',
                backgroundColor: '#6C17B3',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              }
            ]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    min: 0,
                    beginAtZero: true
                  }
                }]
              }
          }
        },
        errors: [],
        filterCompanies: [],
        filterContracts: [],
				filterWorkplaces: [],
				filterSublocal: [],
				filterSectors: [],
				filterSubsectors: [],
        filterItems: {},
        fullPage: true,
				loading: false,
        generalActive: 0,
        generalClosed: 0,
        showChartStatus: false,
        showChartStatusGeral: false,
        showChartStatusGeralYear: false,
        startDate: '',
        endDate: '',
        totalActivities: 0,
        yearActivities: []
      }
    },
    mounted() {
      this.loadInfos();
      this.loadCompanies();
    },
    methods: {
      filterData(){
        let _this = this;
				_this.loading = true;
				axios.post("/admin/dashboard/filter", _this.filterItems).then((res) => {
					_this.activityStatus = _this.activityStatus.concat(res.data.activity_status);

          _this.loadChartStatusData();

          _this.startDate = res.data.start_date;
          _this.endDate = res.data.end_date;

          $("#modalFilterDashboard").modal('hide');

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
      },
      clearFilter(){
        this.filterItems = {};
        this.filterData();
      },
      loadInfos() {
				let _this = this;
        _this.activityStatus = [];
				_this.loading = true;
				axios.get("/admin/dashboard").then((res) => {
					_this.activityStatus = _this.activityStatus.concat(res.data.activity_status);
          //_this.yearActivities = _this.yearActivities.concat(res.data.year);

          //year bar chart
          _this.chartStatusGeralYear.chartData.datasets[0].data = res.data.year_data;
          _this.showChartStatusGeralYear = true;

          _this.generalActive = res.data.total_opened;

          _this.startDate = res.data.start_date;
          this.endDate = res.data.end_date;

          _this.loadChartStatusData();

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      loadChartStatusData(){

        let _this = this;

        var tempLabel = [];
        var tempData = [];
        var tempColor = [];
        var exists = false;

        var totalActivitiesDone = 0;
        //var totalActivitiesDonePercent = 0;
        var totalActivitiesPending = 0;
        //var totalActivitiesPendingPercent = 0;
        var totalActivitiesClosed = 0;

        _this.generalActive = 0;
        _this.generalClosed = 0;

        //read all status to get infos
        _this.activityStatus.map((rowActivity) => {
          
          tempLabel.push(rowActivity.name);
          tempData.push(rowActivity.total);
          tempColor.push(rowActivity.color);

          //update total activities
          _this.totalActivities += rowActivity.total;

          //update activities Pending and Done
          if(rowActivity.id == 2 || rowActivity.id == 5){
            totalActivitiesPending += rowActivity.total;
          } else {
            totalActivitiesDone += rowActivity.total;
          }

          //update activities Closed
          if(rowActivity.id == 6 || rowActivity.id == 9){
            totalActivitiesClosed += rowActivity.total;
          }

          //validate percent
          if(rowActivity.percent > 0){
            exists = true;
          }

        });

        //set widget general
        _this.generalActive = totalActivitiesPending;
        _this.generalClosed = totalActivitiesClosed;

        // //calculate percent
        // totalActivitiesDonePercent = (totalActivitiesDone * 100) / _this.totalActivities;
        // totalActivitiesPendingPercent = (totalActivitiesPending * 100) / _this.totalActivities;

        //apply values to chart status geral
        _this.chartStatusGeral.chartData.labels = ['Em Aberto','Fechado'];
        _this.chartStatusGeral.chartData.datasets[0].data = [totalActivitiesPending,totalActivitiesClosed];
        _this.chartStatusGeral.chartData.datasets[0].backgroundColor = ["#FFA726","#43A047"];

        //apply values to chart status
        _this.chartStatus.chartData.labels = tempLabel;
        _this.chartStatus.chartData.datasets[0].data = tempData;
        _this.chartStatus.chartData.datasets[0].backgroundColor = tempColor;

        if(exists){
          _this.showChartStatus = true;
          _this.showChartStatusGeral = true;
          //_this.showChartStatusGeralYear = true;
        }
      },
      loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					_this.filterCompanies = _this.filterCompanies.concat(res.data);
				}).catch((err) => {
				});
			},
      loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.filterContracts = [];
				axios.get("/admin/companycontracts/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.filterContracts.push({
							id: rowContract.contract_id,
							corporate_name: rowContract.contracts.corporate_name
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      loadSectors() {
				let _this = this;
				_this.loading = true;

				_this.filterSectors = [];
        axios.get("/admin/sectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
          _this.filterSectors = _this.filterSectors.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
      });

			},
			loadSubsectors() {
				let _this = this;
				_this.loading = true;

        _this.filterSubsectors = [];
        axios.get("/admin/subsectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
          _this.filterSubsectors = _this.filterSubsectors.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			},
      validateCompany(){
				let _this = this;
				_this.loading = true;

        _this.filterWorkplaces = [];
        axios.get("/admin/companyworkplaces/"+_this.filterItems.company_id+'/search').then((res) => {
          res.data.map((rowReturn) => {
            _this.filterWorkplaces = _this.filterWorkplaces.concat(rowReturn.work_places);
          });
          _this.loadContracts();
        }).catch((err) => {
        });
        this.loading = false;

			},
      validateSublocal(){
				let _this = this;

				_this.loadSectors();
				_this.loadSubsectors();

			},
      validateWorkplace(){
				let _this = this;
        _this.loading = true;

        _this.filterSublocal = [];
        axios.get("/admin/workplacesublocal/"+_this.filterItems.work_place_id+"/search").then((res) => {
          res.data.map((rowSublocal) => {
            _this.filterSublocal.push({
              corporate_name: rowSublocal.sublocal.corporate_name,
              id: rowSublocal.sublocal_id
            });
          });
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			},
    }
  }
</script>