export default {
  methods: {
    _exportExcel(_name, _ignore, _table, _title){

      // Remove any previously added export header
      _table.find("tr.only-export").remove();
      
      //mount header
      var headerTable = "<tr class='only-export'>";
      _title.map((rowTitle) => {
        headerTable += "<td>"+rowTitle+"</td>";
      });
      headerTable += "</tr>";
      _table.prepend(headerTable);

      //define params
      let params = {
        type: 'excel',
        htmlContent: false,
        fileName: _name,
        ignoreColumn: _ignore,
        ignoreRow: [0],
        mso:  {
          fileFormat: 'xlsx',
          xlsx: {
            formatId: {
              numbers: 1
            }
          }
        }
      }

      //define options
      let options = {
        tableName: _name
      };

      //export
      jQuery.extend(true, options, params);
      _table.tableExport(options);

    },
    _exportPDF(_name, _ignore, _table, _title){

       //mount header
       var headerTable = "<tr class='only-export'>";
       _title.map((rowTitle) => {
         headerTable += "<td>"+rowTitle+"</td>";
       });
       headerTable += "</tr>";
       _table.prepend(headerTable);
 
       //define params
      let params = {
        type: 'pdf',
        fileName: _name,
        ignoreColumn: _ignore,
        htmlContent: false,
        onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
        jspdf: {
          orientation: 'l',
          margins: {right: 10, left: 10, top: 40, bottom: 40},
          autotable: {
            styles: {
              fillColor: 'inherit',
              textColor: 'inherit',
              halign: 'center',
              fillStyle: 'DF',
              lineColor: 200,
              lineWidth: 0.1
            },
            headerStyles: {halign: 'left'},
            tableWidth: 'auto'
          }
        }
      }

      //define options
      let options = {
        tableName: _name
      };

      //export
      jQuery.extend(true, options, params);
      _table.tableExport(options);
      
    },
    DoOnCellData(){

    }
  }  
}