<template>
	<div class="tab-pane fade" id="tab-templatenotifications" role="tabpanel" aria-labelledby="templatenotifications-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Notificações cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalTemplateNotifications"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="templatenotificationsItems.length"
						:columns="tableColumns"
						:rows="templatenotificationsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum notificações cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalTemplateNotifications" tabindex="-1" aria-labelledby="modalTemplateNotificationsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalTemplateNotificationsLabel">{{templatenotificationsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

							<!-- configuration_notification_id -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-configuration_notification_id">Notificações</label>
									<v-select :options="configurationnotifications" v-bind:class="{'is-invalid': errors.notification}" :reduce="name => name.id" label="name" v-model="templatenotification.configuration_notification_id"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.notification">
										<p>{{ errors.notification[0] }}</p>
									</div>
								</div>
							</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions','templates'],
		data() {
			return {
				configurationnotifications: [],
				templatenotificationsTitle: "Novo Vínculo de Notificação",
				templatenotification: {
					configuration_notification_id: '',
				},
				isEditTemplateNotifications: false,
				templatenotificationsID: "",
				templatenotificationsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'configuration_notifications.name',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadConfigurationNotifications();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/templatenotifications/"+JSON.parse(_this.templates).id+'/search').then((res) => {
					_this.templatenotificationsItems = _this.templatenotificationsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditTemplateNotifications == true){

					let dataInfo = {
						"notification" : _this.templatenotification.configuration_notification_id,
						"template": JSON.parse(_this.templates).id,
						"_method": "PATCH"
					};
					axios.post("/admin/templatenotifications/"+_this.templatenotificationsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalTemplateNotifications').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"notification" : _this.templatenotification.configuration_notification_id,
						"template": JSON.parse(_this.templates).id,
						"last": ""
					};
					axios.post("/admin/templatenotifications", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.templatenotificationsItems.push(res.data.data);
						$('#modalTemplateNotifications').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Vínculo de Notificação",
					text: "Tem certeza que deseja excluir esse vínculo?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/templatenotifications/"+ID).then((res) => {

							//_this.templatenotifications.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.templatenotification = item;
				this.isEditTemplateNotifications = true;
				this.templatenotificationsID = item.id;
				this.templatenotificationsTitle = "Editar Vínculo de Notificação";

				$("#modalTemplateNotifications").modal('show');

			},
			loadConfigurationNotifications() {
				let _this = this;
				axios.get("/admin/configurationnotifications").then((res) => {
					_this.configurationnotifications = _this.configurationnotifications.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.templatenotification = {
					configuration_notification_id: '',
				};

				this.errors = [];
				this.isEditTemplateNotifications = false;
				this.templatenotificationsID = "";
				this.templatenotificationsTitle = "Novo Vínculo de Notificação";

			}
		}
	}
</script>
