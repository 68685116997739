<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Certificações cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalCertification"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<div class="dropdown float-right">
					<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-download"></i>
						Exportar
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#" @click="exportData('pdf')">PDF</a>
						<a class="dropdown-item" href="#" @click="exportData('csv')">Excel</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="#" @click="$refs.gridfullexport.showModal('certifications','certifications')">Completo</a>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="certificationsItems.length"
						:columns="tableColumns"
						:rows="certificationsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" title="Visualizar" @click="viewData(props.row)"><i class="far fa-eye"></i></button>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma certificação cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalCertification" tabindex="-1" aria-labelledby="modalCertificationLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalCertificationLabel">{{certificationsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

                  <!-- name -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="input-name">Nome</label>
                      <input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"  maxlength="60" class="form-control" placeholder="Nome" v-model="certification.name" required="true" >
                      <div class="invalid-feedback d-block" v-if="errors.name">
                        <p>{{ errors.name[0] }}</p>
                      </div>
                    </div>
                  </div>

                  <!-- area -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="input-knowledge_area">Área de Conhecimento</label>
                      <input type="text" id="input-knowledge_area" v-bind:class="{'is-invalid': errors.knowledge_area}"  maxlength="60" class="form-control" placeholder="Nome" v-model="certification.knowledge_area" required="true" >
                      <div class="invalid-feedback d-block" v-if="errors.knowledge_area">
                        <p>{{ errors.knowledge_area[0] }}</p>
                      </div>
                    </div>
                  </div>

                  <!-- alert -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="input-alert_days">Alerta (aviso de expiração)</label>
											<div class="input-group mb-3">
												<input type="number" id="input-alert_days" v-bind:class="{'is-invalid': errors.alert_days}"  maxlength="60" class="form-control" placeholder="Nome" v-model="certification.alert_days" required="true" >
												<span class="input-group-text">dias</span>
											</div>
                      <div class="invalid-feedback d-block" v-if="errors.alert_days">
                        <p>{{ errors.alert_days[0] }}</p>
                      </div>
                    </div>
                  </div>
                  

                  <!-- description -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="input-description">Descrição</label>
                      <textarea type="text" id="input-description" maxlength="500" v-bind:class="{'is-invalid': errors.description}" class="form-control" placeholder="Descrição" v-model="certification.description" rows="8"></textarea>
                      <div class="invalid-feedback d-block" v-if="errors.description">
                        <p>{{ errors.description[0] }}</p>
                      </div>
                    </div>
                  </div>
                  
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<!-- modal colaborators -->
			<div class="modal fade" id="modalCertificationColaborators" tabindex="-1" aria-labelledby="modalCertificationColaboratorsLabel" aria-hidden="true">
				<div class="modal-dialog modal-lg modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalCertificationColaboratorsLabel">Colaboradores que tem a Certificação</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">

							<div class="row ">
								<div class="col-md-12">

									<vue-good-table v-if="colaboratorsItems.length"
										:columns="tableColaboratorsColumns"
										:rows="colaboratorsItems"
										:pagination-options="tablePagination"
										:search-options="{
											enabled: true,
											placeholder: 'Buscar..',
										}"
										styleClass="vgt-table"
									>
										<template slot="table-row" slot-scope="props">
											<span v-if="props.column.field == 'actions'">
												<div>
													<button class="btn btn-sm btn-outline-secondary" title="Visualizar" @click="viewColaborator(props.row)"><i class="far fa-eye"></i></button>
												</div>
											</span>
											<span v-else>
												{{ props.formattedRow[props.column.field] }}
											</span>
										</template>
									</vue-good-table>
									<div v-else class="text-center p-3 text-muted">
										<h5>Nenhuma certificação cadastrada!</h5>
									</div>
								</div>

								<div class="text-center p-4 text-muted" v-if="loading">
									<h5>Carregando...</h5>
									<p>Aguarde até que os dados sejam carregados...</p>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<gridfullexport-component ref="gridfullexport"></gridfullexport-component>
			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		props: ['permissions'],
		data() {
			return {
				certificationsTitle: "Nova Certificação",
				certification: {
					name: '',
          knowledge_area: '',
          alert_days: 0,
					description: '',
				},
				isEditCertifications: false,
				certificationsID: "",
				certificationsItems: [],
				colaboratorsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Área de Conhecimento',
						field: 'knowledge_area',
					},{
						label: 'Alerta (dias)',
						field: 'alert_days',
					},{
						label: 'Descrição',
						field: 'description',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '120px'
					}
				],
				tableColaboratorsColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'colaborator.users.profile.name',
					},{
						label: 'Instituição',
						field: 'institution',
					},{
						label: 'Data de Conclusão',
						field: 'conclusion_date',
            type: 'date',
						dateInputFormat: "yyyy-MM-dd",
						dateOutputFormat: 'dd/MM/yyyy'
					},{
						label: 'Validade',
						field: 'validity',
            type: 'date',
						dateInputFormat: "yyyy-MM-dd",
						dateOutputFormat: 'dd/MM/yyyy'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '120px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/certifications").then((res) => {
					_this.certificationsItems = _this.certificationsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			exportData(type){
        var tempForm = $('#vgt-table');
				if(type == 'pdf'){
					this._exportPDF('Certificados', [4], tempForm, ['ID','Nome','Área de Conhecimento','Alerta (Dias)','Descrição','Data e Hora']);
				} else {
					this._exportExcel('Certificados', [4], tempForm, ['ID','Nome','Área de Conhecimento','Alerta (Dias)','Descrição','Data e Hora']);
				}
    	},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditCertifications == true){

					let dataInfo = {
						"name" : _this.certification.name,
            "knowledge_area" : _this.certification.knowledge_area,
            "alert_days" : _this.certification.alert_days,
						"description" : _this.certification.description,
						"_method": "PATCH"
					};
					axios.post("/admin/certifications/"+_this.certificationsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalCertification').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.certification.name,
            "knowledge_area" : _this.certification.knowledge_area,
            "alert_days" : _this.certification.alert_days,
						"description" : _this.certification.description,
						"last": ""
					};
					axios.post("/admin/certifications", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.certificationsItems.push(res.data.data);
						$('#modalCertification').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Certificação",
					text: "Tem certeza que deseja excluir essa Certificação?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/certifications/"+ID).then((res) => {

							//_this.categories.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.certification = item;
				this.isEditCertifications = true;
				this.certificationsID = item.id;
				this.certificationsTitle = "Editar Certificação";

				$("#modalCertification").modal('show');

			},
			resetForm(){

				this.certification = {
					name: '',
          knowledge_area: '',
          alert_days: 0,
					description: '',
				};
				this.errors = [];

				this.isEditCertifications = false;
				this.certificationsID = "";
				this.certificationsTitle = "Nova Certificação";

			},
			viewColaborator(colaborator){
				location.href = "/admin/colaborators/"+colaborator.colaborator.id+"/edit";
			},
			viewData(item){
				let _this = this;
				_this.loading = true;
				_this.colaboratorsItems = [];
				axios.get("/admin/certifications/"+item.id+"/search").then((res) => {
					_this.colaboratorsItems = _this.colaboratorsItems.concat(res.data);
					$("#modalCertificationColaborators").modal('show');
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
