<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        
        <!-- card header -->
        <div class="card-header">
          <h4>Preencha os dados abaixo</h4>

          <a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
        </div>
        <!-- end card header -->

        <!-- card body -->
        <div class="card-body">

          <!-- form -->
          <h6 class="heading-small text-muted mb-4">Dados Básicos</h6>
          <div class="row">

            <!-- name -->
            <div class="col-md-8">
              <div class="form-group">
                <label class="form-control-label">Nome</label>
                <input type="text" id="name" v-bind:class="{'is-invalid': errors.name}" class="form-control" placeholder="Nome" v-model="name">
                <div class="invalid-feedback d-block" v-if="errors.name">
                    <p>{{ errors.name[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-md-9 -->
            <!-- end name -->

            <!-- permission -->
            <div class="col-4">
              <div class="form-group">
                <label class="form-control-label">Grupo de Acesso</label>
                <v-select :options="permissions" label="name" v-model="permission"></v-select>
                <div class="invalid-feedback d-block" v-if="errors.permission">
                    <p>{{ errors.permission[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-lg-12 -->
            <!-- end permission -->

          </div><!-- row -->

          <!-- divider -->
          <hr class="my-4" />
          <!-- Address -->
          <h6 class="heading-small text-muted mb-4">Acesso</h6>
          <div class="row">
            
            <!-- email -->
            <div class="col-6">
              <div class="form-group">
                <label class="form-control-label">E-mail</label>
                <input type="email" id="email" v-bind:class="{'is-invalid': errors.email}" class="form-control" placeholder="E-mail" v-model="email" disabled>
                <div class="invalid-feedback d-block" v-if="errors.email">
                    <p>{{ errors.email[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-lg-12 -->
            <!-- end email -->

            <!-- password -->
            <div class="col-6">
              <div class="form-group">
                <label class="form-control-label" for="input-password">Senha</label>
                <input type="password" id="input-password" v-bind:class="{'is-invalid': errors.password}" class="form-control" placeholder="Digite a senha somente se for alterar!" v-model="password">
                <div class="invalid-feedback d-block" v-if="errors.password">
                    <p>{{ errors.password[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-lg-12 -->
            <!-- end password -->

          </div>         
          <!-- end form -->

        </div><!-- card body -->
      </div><!-- card -->
    </div><!-- col-12 -->

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
  </div><!-- row -->
</template>

<script>
  import ApiService from '../../apiservice.js';
  export default {
    props: ['roles','user'],
    mixins: [ApiService],
    data() {
      return {
        name: this.getData('profile').name,
        email: this.getData('email'),
        permission: this.validateRole(),
        permissions: JSON.parse(this.roles),
        password: '',
        errors: [],
        message: '',
        loading: false,
        fullPage: true
      }
    },
    mounted() {
    },
    methods: {
      getData(key) {
        return JSON.parse(this.user)[key];
      },
      validateRole(){
        if (this.getData('roles') == undefined || this.getData('roles') == null){
          return '';
        } else {
          return this.getData('roles')[0].name;
        }
      },
      updateData() {
        let _this = this;
        _this.errors = [];
        _this.message = '';
        _this.loading = true;
        let dataUser = {
          'name': _this.name,
          'email': _this.email,
          'password': _this.password,
          'permission': _this.permission,
          '_method': 'PATCH'
        };
        axios.post('/admin/users/'+this.getData('id'), dataUser).then((res) => { 

          _this.loading = false;

          //call function in ApiService to validate success
          _this._api_validateSuccess(res);
          
        }).catch((err) => {

          _this.loading = false;

          //set errors
          _this.errors = err.response.data.errors;

          //call function in ApiService to validate error
          _this._api_validateError(err);
          
        });
      }
    }
  }
</script>
