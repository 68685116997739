<template>
	<div class="tab-pane fade" id="tab-iteminventories" role="tabpanel" aria-labelledby="iteminventories-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Movimentações de Inventário cadastradas</p>
				<!-- <a href="#" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalItemInventories"><i class="fas fa-plus"></i>&nbsp;Novo</a> -->
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="iteminventoriesItems.length"
						:columns="tableColumns"
						:rows="iteminventoriesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_status'">
								<div v-if="props.row.status == '0'">Inativo</div>
								<div v-if="props.row.status == '1'">Ativo</div>
								<div v-if="props.row.status == '2'">Pausado</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<!-- <button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button> -->
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma movimentação de inventário cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalItemInventories" tabindex="-1" aria-labelledby="modalItemInventoriesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalItemInventoriesLabel">{{iteminventoriesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- status -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-status">Status</label>
											<input type="text" id="input-status" v-bind:class="{'is-invalid': errors.status}"   class="form-control" placeholder="Status" v-model="iteminventory.status" required="false" >
											<div class="invalid-feedback d-block" v-if="errors.status">
												<p>{{ errors.status[0] }}</p>
											</div>
										</div>
									</div>

									<!-- company id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-work_place_id">Empresa</label>
											<v-select :options="companies" label="corporate_name" v-bind:class="{'is-invalid': errors.company_id}" :reduce="corporate_name => corporate_name.id" v-model="iteminventory.company_id" @input="validateCompany()"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.company_id" >
												<p>{{ errors.company_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- work_place_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
											<v-select :options="workplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.work_place_id}" :reduce="corporate_name => corporate_name.id" v-model="iteminventory.work_place_id" @input="validateWorkplace()"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.work_place_id" >
												<p>{{ errors.work_place_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- colaborator_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-colaborator_id">Colaborador</label>
											<v-select :options="colaborators" label="user.profile.name" v-bind:class="{'is-invalid': errors.colaborator_id}" v-model="iteminventory.colaborator_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.colaborator_id">
												<p>{{ errors.colaborator_id[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- sector_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-sector_id">Setor</label>
											<v-select :options="sectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="iteminventory.sector_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.sector_id">
												<p>{{ errors.sector_id[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- subsector_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-subsector_id">Subsetor</label>
											<v-select :options="subsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="iteminventory.subsector_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.subsector_id">
												<p>{{ errors.subsector_id[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions','items'],
		data() {
			return {
				companies: [],
				workplaces: [],
				colaborators: [],
				sectors: [],
				subsectors: [],
				iteminventoriesTitle: "Nova Movimentação de Inventário",
				iteminventory: {
					status: '',
					company_id: '',
					work_place_id: '',
					colaborator_id: '',
					sector_id: '',
					subsector_id: ''
				},
				isEditItemInventories: false,
				iteminventoriesID: "",
				iteminventoriesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tempColaborators: [],
				tempSectors: [],
				tempSubsectors: [],
				tempWorkplaces: [],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Status',
						field: '_status',
					},{
						label: 'Empresa',
						field: 'company.company.corporate_name',
					},{
						label: 'Local de Trabalho',
						field: 'work_places.corporate_name',
					},{
						label: 'Sublocal de Trabalho',
						field: 'sublocal.corporate_name',
					},{
						label: 'Colaborador',
						field: 'colaborators.users.profile.name',
					},{
						label: 'Setor',
						field: 'sectors.name',
					},{
						label: 'Subsetor',
						field: 'subsectors.name',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadCompanies();
			this.loadColaborators();
			this.loadSectors();
			this.loadSubsectors();
		},
		methods: {
			getData(key) {
				return JSON.parse(this.items)[key];
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/iteminventories/"+_this.getData('id')+"/search").then((res) => {
					_this.iteminventoriesItems = _this.iteminventoriesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditItemInventories == true){

					let dataInfo = {
						"status" : _this.iteminventory.status,
						"work_place_id" : _this.iteminventory.work_place_id,
						"colaborator_id" : _this.iteminventory.colaborator_id,
						"sector_id" : _this.iteminventory.sector_id,
						"subsector_id" : _this.iteminventory.subsector_id,
						"_method": "PATCH"
					};
					axios.post("/admin/iteminventories/"+_this.iteminventoriesID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalItemInventories').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"status" : _this.iteminventory.status,
						"work_place_id" : _this.iteminventory.work_place_id,
						"colaborator_id" : _this.iteminventory.colaborator_id,
						"sector_id" : _this.iteminventory.sector_id,
						"subsector_id" : _this.iteminventory.subsector_id,
						"last": ""
					};
					axios.post("/admin/iteminventories", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.iteminventoriesItems.push(res.data.data);
						$('#modalItemInventories').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir iteminventories",
					text: "Tem certeza que deseja excluir esse iteminventories?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/iteminventories/"+ID).then((res) => {

							//_this.iteminventories.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.iteminventory = item;
				this.isEditItemInventories = true;
				this.iteminventoriesID = item.id;
				this.iteminventoriesTitle = "Edit ItemInventories";

				$("#modalItemInventories").modal('show');

			},
			loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					_this.companies = _this.companies.concat(res.data);
				}).catch((err) => {
				});
			},
			loadColaborators() {
				let _this = this;
				axios.get("/admin/colaborators").then((res) => {
					_this.tempColaborators = _this.tempColaborators.concat(res.data);
				}).catch((err) => {
				});
			},
			loadSectors() {
				let _this = this;
				axios.get("/admin/sectors").then((res) => {
					_this.tempSectors = _this.tempSectors.concat(res.data);
				}).catch((err) => {
				});
			},
			loadSubsectors() {
				let _this = this;
				axios.get("/admin/subsectors").then((res) => {
					_this.tempSubsectors = _this.tempSubsectors.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.iteminventory = {
					status: '',
					work_place_id: '',
					colaborator_id: '',
					sector_id: '',
					subsector_id: '',
				};

			},
			validateCompany(){
				let _this = this;
				axios.get("/admin/companyworkplaces/"+_this.iteminventory.company_id+'/search').then((res) => {
					_this.workplaces = _this.workplaces.concat(res.data.work_places);
					_this.tempC
					console.log(this.workplaces);
				}).catch((err) => {
				});
			},
			validateWorkplace(){
				console.log(this.workplaces);
			}
		}
	}
</script>
