<template>
	<div class="tab-pane fade" id="tab-allowdelayfrequency" role="tabpanel" aria-labelledby="allowdelayfrequency-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Permitir Atraso? cadastrados</p>
				<a href="#" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalAllowDelayFrequency"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="allowdelayfrequencyItems.length"
						:columns="tableColumns"
						:rows="allowdelayfrequencyItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum permitir atraso? cadastrado(a)!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalAllowDelayFrequency" tabindex="-1" aria-labelledby="modalAllowDelayFrequencyLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalAllowDelayFrequencyLabel">{{allowdelayfrequencyTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

							<!-- allowdelay_id -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-allowdelay_id">Tempo Atraso</label>
									<v-select :options="allowdelays" v-bind:class="{'is-invalid': errors.allowdelay_id}" label="name" v-model="allowdelayfrequency.allowdelay_id"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.allowdelay_id">
										<p>{{ errors.allowdelay_id[0] }}</p>
									</div>
								</div>
							</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SAVE</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				allowdelays: [],
				allowdelayfrequencyTitle: "Novo Permitir Atraso?",
				allowdelayfrequency: {
					allowdelay_id: '',
				},
				isEditAllowDelayFrequency: false,
				allowdelayfrequencyID: "",
				allowdelayfrequencyItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'allowdelay_id',
						field: 'allowdelay_id',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadAllowDelays();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/allowdelayfrequency").then((res) => {
					_this.allowdelayfrequencyItems = _this.allowdelayfrequencyItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditAllowDelayFrequency == true){

					let dataInfo = {
						"allowdelay_id" : _this.allowdelayfrequency.allowdelay_id,
						"_method": "PATCH"
					};
					axios.post("/admin/allowdelayfrequency/"+_this.allowdelayfrequencyID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalAllowDelayFrequency').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"allowdelay_id" : _this.allowdelayfrequency.allowdelay_id,
						"last": ""
					};
					axios.post("/admin/allowdelayfrequency", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.allowdelayfrequencyItems.push(res.data.data);
						$('#modalAllowDelayFrequency').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir allowdelayfrequency",
					text: "Tem certeza que deseja excluir esse(a) allowdelayfrequency?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/allowdelayfrequency/"+ID).then((res) => {

							//_this.allowdelayfrequency.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.allowdelayfrequency = item;
				this.isEditAllowDelayFrequency = true;
				this.allowdelayfrequencyID = item.id;
				this.allowdelayfrequencyTitle = "Edit AllowDelayFrequency";

				$("#modalAllowDelayFrequency").modal('show');

			},
			loadAllowDelays() {
				let _this = this;
				axios.get("/admin/allowdelays").then((res) => {
					_this.allowdelays = _this.allowdelays.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.allowdelayfrequency = {
					allowdelay_id: '',
				};

			}
		}
	}
</script>
