<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Notificações cadastradas</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalCompanyNotification"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="companynotifications.length"
						:columns="tableColumns"
						:rows="companynotifications"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == '_source'">
                <div v-if="props.row.source == 'new-occurrence'">Nova Ocorrência</div>
								<div v-if="props.row.source == 'certifications'">Certificações</div>
              </span>
              <span v-if="props.column.field == '_frequency'">
                <div v-if="props.row.frequency_type == 1">Minutos</div>
                <div v-if="props.row.frequency_type == 2">Horas</div>
                <div v-if="props.row.frequency_type == 3">Dias</div>
                <div v-if="props.row.frequency_type == 4">Único</div>
              </span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma notificação cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalCompanyNotification" tabindex="-1" aria-labelledby="modalCompanyNotificationLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalCompanyNotificationLabel">{{companyModalTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

                  <div class="col-md-6">
                    <div class="row">

                      <!-- company id -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="input-work_place_id">Empresa</label>
                          <v-select :options="filterCompanies" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="companyNotification.company_id" @input="validateCompany()"></v-select>
                        </div>
                      </div>

                      <!-- work_place_id -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
                          <v-select :options="filterWorkplaces" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="companyNotification.work_place_id" @input="validateWorkplace()"></v-select>
                        </div>
                      </div>

                      <!-- source -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="input-name">Origem</label>
                          <v-select :options="sourceOptions" label="label" v-model="companyNotification.source" :reduce="label => label.field"></v-select>
                        </div>
                      </div>

                       <!-- time -->
                       <div class="col-md-4">
                        <div class="form-group">
                          <label class="form-control-label" for="input-time">Tempo</label>
                          <input type="tel" id="input-time" class="form-control" placeholder="Tempo" v-model="companyNotification.time" />
                        </div>
                      </div>

                      <!-- type -->
                      <div class="col-md-8">
                        <div class="form-group">
                          <label class="form-control-label" for="input-type">Tipo de Frequência</label>
                          <v-select v-model="companyNotification.frequency" :reduce="label => label.field" :options="frequencyOptions"></v-select>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">

                      <!-- contract -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="input-work_place_id">Contrato</label>
                          <v-select :options="filterContracts" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="companyNotification.contract_id"></v-select>
                        </div>
                      </div>

                      <!-- sublocal -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="input-sublocal">Sublocal</label>
                          <v-select :options="filterSublocal" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="companyNotification.sublocal_id" @input="validateSublocal()"></v-select>
                        </div>
                      </div>

                      <!-- type -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="input-description">Tipo</label>
                          <v-select :options="typeOptions" label="label" v-model="companyNotification.type" :reduce="label => label.field" multiple></v-select>
                        </div>
                      </div>

                      <!-- colaborators -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="input-description">Colaboradores</label>
                          <v-select :options="colaboratorOptions" multiple label="name" :reduce="name => name.colaborator_id" v-model="companyNotification.colaborator_id" required></v-select>
                        </div>
                      </div>

                    </div>
                  </div>

								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<gridfullexport-component ref="gridfullexport"></gridfullexport-component>
			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		props: ['permissions'],
		data() {
			return {
				companyModalTitle: "Nova Notificação",
				companyNotification: {
          colaborator_id: '',
          company_id: '',
          contract_id: '',
          frequency: '',
					source: '',
          sublocal_id: '',
          time: '',
					type: '',
          work_place_id: '',
				},
				isEditCompanyNotification: false,
        colaboratorOptions: [],
				companyNotificationsID: "",
				companynotifications: [],
        filterCompanies: [],
        filterContracts: [],
        filterWorkplaces: [],
        filterSublocal: [],
				fullPage: true,
				errors: [],
				loading: false,
        frequencyOptions: [{
          field: '2',
          label: 'Horas'
        },{
          field: '3',
          label: 'Dias'
        },{
          field: '4',
          label: 'Único'
        }],
        sourceOptions: [{
          field: 'new-occurrence',
          label: 'Nova Ocorrência'
        },{
          field: 'certifications',
          label: 'Certificações'
        }],
        typeOptions: [{
          field: 'email',
          label: 'E-mail'
        },{
          field: 'push',
          label: 'Notificação Push (aplicativo)'
        }],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Origem',
						field: '_source',
					},{
						label: 'Tipo',
						field: '_type',
					},{
						label: 'Tempo',
						field: 'frequency',
					},{
						label: 'Frequência',
						field: '_frequency',
					},{
						label: 'Empresa',
						field: 'companies.corporate_name',
					},{
						label: 'Contrato',
						field: 'contracts.corporate_name',
					},{
						label: 'Local de Trabalho',
						field: 'work_places.corporate_name',
					},{
						label: 'Sublocal',
						field: 'sublocal.corporate_name',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
        tempColaborators: [],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
      this.loadCompanies();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/companynotifications").then((res) => {
					_this.companynotifications = _this.companynotifications.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      loadColaborators() {
				let _this = this;
				_this.colaboratorOptions = [];
				_this.tempColaborators = [];
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+_this.companyNotification.company_id+"/search").then((res) => {
					res.data.map((row) => {
						row.name = row.colaborators.users.profile.name;
						_this.tempColaborators.push(row);
					});

					//validate edit
					if(this.isEditCompanyNotification){
						this.validateWorkplace();
						this.validateSublocal();
					}

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					_this.filterCompanies = _this.filterCompanies.concat(res.data);
				}).catch((err) => {
				});
			},
			loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.filterContracts = [];
				axios.get("/admin/companycontracts/"+_this.companyNotification.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.filterContracts.push({
							id: rowContract.contract_id,
							corporate_name: rowContract.contracts.corporate_name
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditCompanyNotification == true){

					let dataInfo = {
						"company_id" : _this.companyNotification.company_id,
						"contract_id" : _this.companyNotification.contract_id,
            "work_place_id" : _this.companyNotification.work_place_id,
            "sublocal_id" : _this.companyNotification.sublocal_id,
            "source" : _this.companyNotification.source,
            "type" : _this.companyNotification.type,
            "time" : _this.companyNotification.time,
            "frequency" : _this.companyNotification.frequency,
            "colaborator_id" : _this.companyNotification.colaborator_id,
						"_method": "PATCH"
					};
					axios.post("/admin/companynotifications/"+_this.companyNotificationsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalCompanyNotification').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"company_id" : _this.companyNotification.company_id,
						"contract_id" : _this.companyNotification.contract_id,
            "work_place_id" : _this.companyNotification.work_place_id,
            "sublocal_id" : _this.companyNotification.sublocal_id,
            "source" : _this.companyNotification.source,
            "type" : _this.companyNotification.type,
            "time" : _this.companyNotification.time,
            "frequency" : _this.companyNotification.frequency,
            "colaborator_id" : _this.companyNotification.colaborator_id,
						"last": ""
					};
					axios.post("/admin/companynotifications", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.companynotifications.push(res.data.data);
						$('#modalCompanyNotification').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Notificação",
					text: "Tem certeza que deseja excluir essa Notificação?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/companynotifications/"+ID).then((res) => {

							//_this.companyNotifications.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.companyNotification = item;
				this.isEditCompanyNotification = true;
				this.companyNotificationsID = item.id;
				this.companyModalTitle = "Editar Notificação";

				this.companyNotification.colaborator_id = [];
				this.companyNotification.type = JSON.parse(item.type);
				this.companyNotification.time = item.frequency;

				item.colaborators.map((rowColaborator) => {
					this.companyNotification.colaborator_id.push({
						colaborator_id: rowColaborator.colaborator_id,
						name: rowColaborator.info.users.profile.name
					});
				});

				this.validateCompany();

				$("#modalCompanyNotification").modal('show');

			},
			resetForm(){

				this.companyNotification = {
					company_id: '',
					contract_id: '',
          work_place_id: '',
          sublocal_id: '',
          source: '',
          type: '',
          time: '',
          frequency: '',
          colaborator_id: ''
				};
				this.errors = [];

				this.isEditCompanyNotification = false;
				this.companyNotificationsID = "";
				this.companyModalTitle = "Nova Notificação";

			},
      validateCompany(){
				let _this = this;
				_this.loading = true;

        _this.filterWorkplaces = [];
        axios.get("/admin/companyworkplaces/"+_this.companyNotification.company_id+'/search').then((res) => {
          res.data.map((rowReturn) => {
            _this.filterWorkplaces = _this.filterWorkplaces.concat(rowReturn.work_places);
          });
          _this.loadContracts();
          _this.loadColaborators();
        }).catch((err) => {
        });
        this.loading = false;
			},
      validateSublocal(){
        let _this = this;
        _this.colaboratorOptions = [];
				axios.get("/admin/companyworkplacecolaborators/"+_this.companyNotification.company_id+"/search").then((res) => {
					res.data.map((itemWorkplaceColaborator) => {

            //read all colaborators
            _this.tempColaborators.map((itemColaborator) => {
              if(itemColaborator.colaborator_id == itemWorkplaceColaborator.colaborator_id){
                if(!_this.colaboratorOptions.some(data => data.id === itemColaborator.id)){
                  _this.colaboratorOptions.push(itemColaborator);   
                }
              }
            });

          });
          _this.loading = false;
				}).catch((err) => {
				});
      },
      validateWorkplace(){
				let _this = this;
        _this.loading = true;

        _this.filterSublocal = [];
        axios.get("/admin/workplacesublocal/"+_this.companyNotification.work_place_id+"/search").then((res) => {
          res.data.map((rowSublocal) => {
            _this.filterSublocal.push({
              corporate_name: rowSublocal.sublocal.corporate_name,
              id: rowSublocal.sublocal_id
            });
          });
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			}
		}
	}
</script>
