<template>
	<div class="tab-pane fade show active" id="tab-geral" role="tabpanel" aria-labelledby="geral-tab">
		<div class="row">
			<div class="col-12">
				<div class="card">

					<!-- card header -->
					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>
					<!-- end card header -->

					<!-- card body -->
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Acesso</h6>
						<div class="row">

							<!-- name -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-name">Nome</label>
									<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"  class="form-control" placeholder="Nome" v-model="name" required="true">
									<div class="invalid-feedback d-block" v-if="errors.name">
										<p>{{ errors.name[0] }}</p>
									</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-email">E-mail</label>
									<input type="email" id="input-email" :disabled="true" v-bind:class="{'is-invalid': errors.email}" maxlength="255" class="form-control" placeholder="E-mail" v-model="email" required="true">
									<div class="invalid-feedback d-block" v-if="errors.email">
										<p>{{ errors.email[0] }}</p>
									</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-password">Senha (tamanho máximo: 15 caracteres)</label>
									<input type="password" id="input-password" v-bind:class="{'is-invalid': errors.password}" maxlength="15" class="form-control" placeholder="Digite somente se for alterar a senha" v-model="password" required="true">
									<div class="invalid-feedback d-block" v-if="errors.password">
										<p>{{ errors.password[0] }}</p>
									</div>
								</div>
							</div>

							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-pin">PIN</label>
									<div class="input-group mb-3">
										<input :type="inputType" id="input-pin" v-bind:class="{'is-invalid': errors.pin}" class="form-control" placeholder="PIN" v-model="pin" required="true" disabled>
										<div class="input-group-prepend">
											<button class="btn btn-outline-secondary" type="button" @click="changeInputView()"><i :class="inputIcon"></i></button>
										</div>
									</div>

									<div class="invalid-feedback" v-if="errors.pin">
										<p>{{ errors.pin[0] }}</p>
									</div>
								</div>
							</div>
							<div class="col-md-1">
								<div class="form-group">
									<a class="btn btn-outline-warning" href="#" style="margin-top: 28px;height: 42px;width:100%;padding: 8px;" @click="newPIN()" title="Gerar Novo PIN"><i class="fas fa-sync"></i></a>
								</div>
							</div>

						</div>
						<!-- end form -->

						<div class="separator"></div>

						<!-- form -->
						<h6 class="heading-small text-muted mb-4">Dados</h6>
						<div class="row">

							<!-- cpf -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-cpf">CPF</label>
									<input type="tel" id="input-cpf" v-bind:class="{'is-invalid': errors.cpf}"  maxlength="30" class="form-control" placeholder="CPF" v-model="cpf" required="false" v-mask="'###.###.###-##'">
									<div class="invalid-feedback d-block" v-if="errors.cpf">
										<p>{{ errors.cpf[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end cpf -->

							<!-- phone -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-phone">Telefone</label>
									<input type="text" id="input-phone" v-bind:class="{'is-invalid': errors.phone}"  maxlength="30" class="form-control" placeholder="Telefone" v-model="phone" required="true" v-mask="'(##) #####-####'">
									<div class="invalid-feedback d-block" v-if="errors.phone">
										<p>{{ errors.phone[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-4 -->
							<!-- end phone -->

							<!-- country -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-country">País</label>
									<input type="text" id="input-country" v-bind:class="{'is-invalid': errors.country}"   class="form-control" placeholder="País" v-model="country" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.country">
										<p>{{ errors.country[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-4 -->
							<!-- end country -->

							<!-- profession_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-profession_id">Cargo / Profissão</label>
									<v-select :options="professions" v-bind:class="{'is-invalid': errors.profession_id}" label="name" v-model="profession_id" :reduce="name => name.id"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.profession_id">
										<p>{{ errors.profession_id[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end profession_id -->

							<!-- status -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-status">Status</label>
									<v-select :options="optionsStatus" v-bind:class="{'is-invalid': errors.status}" :reduce="label => label.value" v-model="status"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.status">
										<p>{{ errors.status[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-4 -->
							<!-- end status -->

						</div>
						<!-- end form -->

					</div><!-- card body -->
				</div><!-- card -->
			</div><!-- col-12 -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div><!-- row -->
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ["colaborators"],
		data() {
			return {
				cpf: this.getData("cpf"),
				phone: this.getData("phone"),
				country: this.getData("country"),
				status: this.getData("status"),
				user_id: this.getData("user_id"),
				pin: this.getData('pin'),
				profession_id: this.getData("profession_id"),
				professions: [],
				errors: [],
				name: this.getData("users").profile.name,
				email: this.getData("users").email,
				password: '',
				message: "",
				loading: false,
				fullPage: true,
				inputIcon: 'fas fa-eye',
        inputType: 'password',
				optionsStatus: [{
					label: 'Ativo',
					value: 'active'
				},{
					label: 'Inativo',
					value: 'inactive'
				}]
			}
		},
		mounted() {
			this.loadUsers();
			this.loadProfessions();
		},
		methods: {
			getData(key) {
				return JSON.parse(this.colaborators)[key];
			},
			changeInputView(){
        if (this.inputType == 'password'){
          this.inputType = "text";
          this.inputIcon = "fas fa-eye-slash";
        } else {
          this.inputType = "password";
          this.inputIcon = "fas fa-eye";
        }
      },
			newPIN(){
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				axios.get("/admin/colaborators/"+this.getData('id')+"/pin").then((res) => {

					_this.loading = false;

					_this.pin = res.data.data.pin;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,false);

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);
					
				});
			},
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"cpf" : _this.cpf,
					"phone" : _this.phone,
					"country" : _this.country,
					"status" : _this.status,
					"user_id" : _this.user_id,
					"profession_id" : _this.profession_id,
					"name": _this.name,
					"email": _this.email,
					"_method": "PATCH"
				};
				axios.post("/admin/colaborators/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			loadUsers() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/users").then((res) => {
					_this.users = _this.users.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadProfessions() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/professions").then((res) => {
					_this.professions = _this.professions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
