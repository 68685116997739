<template>
	<div class="row access-groups">
		<div class="col">

			<!-- top -->
			<div class="row section-top">
				<div class="col-md-12 pad0">
					<p class="section-lead">Preencha os campos abaixo</p>
					<a href="#" @click="newData()" class="btn btn-primary float-right">SALVAR</a>
					<a href="#" @click="type = 'menu'" class="btn btn-secondary float-right" style="margin-right: 15px;" v-if="type != 'menu'">VER MENU</a>
				</div>
			</div>
			<!-- end top -->

			<!-- name -->
			<div class="card">
				<div class="card-body">
					<div class="form-group">
						<label>Nome do Grupo de Acesso</label>
						<input class="form-control" v-model="name" id="name"/>
					</div>
				</div>
			</div>
			<!-- end name -->

			<!-- menu -->
			<div class="row menu" v-if="type == 'menu'">
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-desktop"></i>
						</div>
						<div class="card-body">
							<h4>Padrão</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'default'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-building"></i>
						</div>
						<div class="card-body">
							<h4>Corporativo</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'corporate'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-cogs"></i>
						</div>
						<div class="card-body">
							<h4>Configurações</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'configurations'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="far fa-chart-bar"></i>
						</div>
						<div class="card-body">
							<h4>Relatórios</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'reports'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="far fa-bookmark"></i>
						</div>
						<div class="card-body">
							<h4>Auxiliares</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'system'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-lock"></i>
						</div>
						<div class="card-body">
							<h4>Restrito</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'admin'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
				
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-mobile-alt"></i>
						</div>
						<div class="card-body">
							<h4>Aplicativo</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'app'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
			</div>
			<!-- end menu -->

			<!-- itens admin -->
			<div class="row list" v-if="type == 'admin'">

				<!-- list all admin permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.admin" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all admin permissions -->

			</div>

			<!-- itens default -->
			<div class="row list" v-if="type == 'default'">

				<!-- list all default permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.default" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all default permissions -->

			</div>

			<!-- itens corporate -->
			<div class="row list" v-if="type == 'corporate'">

				<!-- list all app permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.corporate" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all app permissions -->

			</div>

			<!-- itens configurations -->
			<div class="row list" v-if="type == 'configurations'">

				<!-- list all configurations permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.configurations" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all configurations permissions -->

			</div>

			<!-- itens reports -->
			<div class="row list" v-if="type == 'reports'">

				<!-- list all reports permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.reports" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all reports permissions -->

				</div>

			<!-- itens system system -->
			<div class="row list" v-if="type == 'system'">

				<!-- list all system permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.system" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all system permissions -->

			</div>

		</div>

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ApiService from "../../apiservice.js";
	export default {
		mixins: [ApiService],
		data() {
			return {
				loading: false,
				name: "",
				permission: {
					admin: [
						{
							title: "Grupos de Acesso",
							items: [
								{
									title: "Listar",
									name: "admin-permissions-list"
								},{
									title: "Criar",
									name: "admin-permissions-create"
								},{
									title: "Editar",
									name: "admin-permissions-edit"
								},{
									title: "Deletar",
									name: "admin-permissions-delete"
								}
							]
						},{
							title: "Usuários",
							items: [
								{
									title: "Listar",
									name: "admin-users-list"
								},{
									title: "Criar",
									name: "admin-users-create"
								},{
									title: "Editar",
									name: "admin-users-edit"
								},{
									title: "Deletar",
									name: "admin-users-delete"
								},{
									title: "Desativar",
									name: "admin-users-disable"
								},{
									title: "Resetar Senha",
									name: "admin-users-resetpassword"
								}
							]
						},{
							title: "Logs",
							items: [
								{
									title: "Log Acesso",
									name: "admin-logs-access"
								},{
									title: "Log API",
									name: "admin-logs-api"
								},{
									title: "Log Atividade",
									name: "admin-logs-activity"
								},{
									title: "Log Erro",
									name: "admin-logs-error"
								},{
									title: "Log de Falhas",
									name: "admin-logs-failedjobs"
								}
							]
						}
					],
					app: [],
					configurations: [
					{
							title: "Checklists",
							items: [
								{
									title: "Listar",
									name: "admin-checklists-list"
								},{
									title: "Criar",
									name: "admin-checklists-create"
								},{
									title: "Editar",
									name: "admin-checklists-edit"
								},{
									title: "Deletar",
									name: "admin-checklists-delete"
								}
							]
						},
						{
							title: "Notificações",
							items: [
								{
									title: "Listar",
									name: "admin-companynotifications-list"
								},{
									title: "Criar",
									name: "admin-companynotifications-create"
								},{
									title: "Editar",
									name: "admin-companynotifications-edit"
								},{
									title: "Deletar",
									name: "admin-companynotifications-delete"
								}
							]
						},
						{
							title: "Templates",
							items: [
								{
									title: "Listar",
									name: "admin-templates-list"
								},{
									title: "Criar",
									name: "admin-templates-create"
								},{
									title: "Editar",
									name: "admin-templates-edit"
								},{
									title: "Deletar",
									name: "admin-templates-delete"
								}
							]
						},
						{
							title: "Vínculo Template / Checklist",
							items: [
								{
									title: "Listar",
									name: "admin-templatechecklists-list"
								},{
									title: "Criar",
									name: "admin-templatechecklists-create"
								},{
									title: "Editar",
									name: "admin-templatechecklists-edit"
								},{
									title: "Deletar",
									name: "admin-templatechecklists-delete"
								}
							]
						},
						{
							title: "Vínculo Template / EPIs",
							items: [
								{
									title: "Listar",
									name: "admin-templateepis-list"
								},{
									title: "Criar",
									name: "admin-templateepis-create"
								},{
									title: "Editar",
									name: "admin-templateepis-edit"
								},{
									title: "Deletar",
									name: "admin-templateepis-delete"
								}
							]
						},
						{
							title: "Vínculo Template / Notificações",
							items: [
								{
									title: "Listar",
									name: "admin-templatenotifications-list"
								},{
									title: "Criar",
									name: "admin-templatenotifications-create"
								},{
									title: "Editar",
									name: "admin-templatenotifications-edit"
								},{
									title: "Deletar",
									name: "admin-templatenotifications-delete"
								}
							]
						},
						{
							title: "Vínculo Template / Mensagens",
							items: [
								{
									title: "Listar",
									name: "admin-templatemessages-list"
								},{
									title: "Criar",
									name: "admin-templatemessages-create"
								},{
									title: "Editar",
									name: "admin-templatemessages-edit"
								},{
									title: "Deletar",
									name: "admin-templatemessages-delete"
								}
							]
						},
					],
					corporate: [
						{
							title: "Empresas",
							items: [
								{
									title: "Listar",
									name: "admin-companies-list"
								},{
									title: "Criar",
									name: "admin-companies-create"
								},{
									title: "Editar",
									name: "admin-companies-edit"
								},{
									title: "Deletar",
									name: "admin-companies-delete"
								}
							]
						},
						{
							title: "Colaboradores",
							items: [
								{
									title: "Listar",
									name: "admin-colaborators-list"
								},{
									title: "Criar",
									name: "admin-colaborators-create"
								},{
									title: "Editar",
									name: "admin-colaborators-edit"
								},{
									title: "Deletar",
									name: "admin-colaborators-delete"
								}
							]
						},
						{
							title: "Contratos",
							items: [
								{
									title: "Listar",
									name: "admin-contracts-list"
								},{
									title: "Criar",
									name: "admin-contracts-create"
								},{
									title: "Editar",
									name: "admin-contracts-edit"
								},{
									title: "Deletar",
									name: "admin-contracts-delete"
								}
							]
						},
						{
							title: "Vínculo Empresa / Colaboradores",
							items: [
								{
									title: "Listar",
									name: "admin-companycolaborators-list"
								},{
									title: "Criar",
									name: "admin-companycolaborators-create"
								},{
									title: "Editar",
									name: "admin-companycolaborators-edit"
								},{
									title: "Deletar",
									name: "admin-companycolaborators-delete"
								}
							]
						},
						{
							title: "Locais de Trabalho",
							items: [
								{
									title: "Listar",
									name: "admin-workplaces-list"
								},{
									title: "Criar",
									name: "admin-workplaces-create"
								},{
									title: "Editar",
									name: "admin-workplaces-edit"
								},{
									title: "Deletar",
									name: "admin-workplaces-delete"
								}
							]
						},
						{
							title: "Vínculo Local de Trabalho / Colaborador",
							items: [
								{
									title: "Listar",
									name: "admin-workplacecolaborators-list"
								},{
									title: "Criar",
									name: "admin-workplacecolaborators-create"
								},{
									title: "Editar",
									name: "admin-workplacecolaborators-edit"
								},{
									title: "Deletar",
									name: "admin-workplacecolaborators-delete"
								}
							]
						},
						{
							title: "Vínculo Local de Trabalho / Responsável",
							items: [
								{
									title: "Listar",
									name: "admin-workplaceresponsibles-list"
								},{
									title: "Criar",
									name: "admin-workplaceresponsibles-create"
								},{
									title: "Editar",
									name: "admin-workplaceresponsibles-edit"
								},{
									title: "Deletar",
									name: "admin-workplaceresponsibles-delete"
								}
							]
						},
						{
							title: "Sublocais de Trabalho",
							items: [
								{
									title: "Listar",
									name: "admin-sublocal-list"
								},{
									title: "Criar",
									name: "admin-sublocal-create"
								},{
									title: "Editar",
									name: "admin-sublocal-edit"
								},{
									title: "Deletar",
									name: "admin-sublocal-delete"
								}
							]
						},
						{
							title: "Setores",
							items: [
								{
									title: "Listar",
									name: "admin-sectors-list"
								},{
									title: "Criar",
									name: "admin-sectors-create"
								},{
									title: "Editar",
									name: "admin-sectors-edit"
								},{
									title: "Deletar",
									name: "admin-sectors-delete"
								}
							]
						},
						{
							title: "Subsetores",
							items: [
								{
									title: "Listar",
									name: "admin-subsectors-list"
								},{
									title: "Criar",
									name: "admin-subsectors-create"
								},{
									title: "Editar",
									name: "admin-subsectors-edit"
								},{
									title: "Deletar",
									name: "admin-subsectors-delete"
								}
							]
						},
						{
							title: "Vínculo Sublocal de Trabalho / Setor",
							items: [
								{
									title: "Listar",
									name: "admin-sublocalsector-list"
								},{
									title: "Criar",
									name: "admin-sublocalsector-create"
								},{
									title: "Editar",
									name: "admin-sublocalsector-edit"
								},{
									title: "Deletar",
									name: "admin-sublocalsector-delete"
								}
							]
						},
						{
							title: "Vínculo Sublocal de Trabalho / Subsetor",
							items: [
								{
									title: "Listar",
									name: "admin-sublocalsubsector-list"
								},{
									title: "Criar",
									name: "admin-sublocalsubsector-create"
								},{
									title: "Editar",
									name: "admin-sublocalsubsector-edit"
								},{
									title: "Deletar",
									name: "admin-sublocalsubsector-delete"
								}
							]
						},
					],
					default: [
						{
							title: "Atividades",
							items: [
								{
									title: "Listar",
									name: "admin-activities-list"
								},{
									title: "Criar",
									name: "admin-activities-create"
								},{
									title: "Editar",
									name: "admin-activities-edit"
								},{
									title: "Deletar",
									name: "admin-activities-delete"
								}
							]
						},
						{
							title: "Ocorrências",
							items: [
								{
									title: "Listar",
									name: "admin-occurrences-list"
								},{
									title: "Criar",
									name: "admin-occurrences-create"
								},{
									title: "Editar",
									name: "admin-occurrences-edit"
								},{
									title: "Deletar",
									name: "admin-occurrences-delete"
								}
							]
						},
						{
							title: "Itens",
							items: [
								{
									title: "Listar",
									name: "admin-items-list"
								},{
									title: "Criar",
									name: "admin-items-create"
								},{
									title: "Editar",
									name: "admin-items-edit"
								},{
									title: "Deletar",
									name: "admin-items-delete"
								}
							]
						},
						{
							title: "Inventário de Item",
							items: [
								{
									title: "Listar",
									name: "admin-iteminventories-list"
								},{
									title: "Criar",
									name: "admin-iteminventories-create"
								},{
									title: "Editar",
									name: "admin-iteminventories-edit"
								},{
									title: "Deletar",
									name: "admin-iteminventories-delete"
								}
							]
						},
						{
							title: "Anexos de Itens",
							items: [
								{
									title: "Listar",
									name: "admin-itemattachments-list"
								},{
									title: "Criar",
									name: "admin-itemattachments-create"
								},{
									title: "Editar",
									name: "admin-itemattachments-edit"
								},{
									title: "Deletar",
									name: "admin-itemattachments-delete"
								}
							]
						},
						{
							title: "Romaneio",
							items: [
								{
									title: "Listar",
									name: "admin-inventory-list"
								},{
									title: "Criar",
									name: "admin-inventory-create"
								},{
									title: "Editar",
									name: "admin-inventory-edit"
								},{
									title: "Deletar",
									name: "admin-inventory-delete"
								}
							]
						},
					],
					reports: [
					{
							title: "Relatório de Ocorrências",
							items: [
								{
									title: "Gerar Relatório",
									name: "admin-reportoccurrences-list"
								}
							]
						},
					],
					system: [
						{
							title: "Fabricantes",
							items: [
								{
									title: "Listar",
									name: "admin-manufacturers-list"
								},{
									title: "Criar",
									name: "admin-manufacturers-create"
								},{
									title: "Editar",
									name: "admin-manufacturers-edit"
								},{
									title: "Deletar",
									name: "admin-manufacturers-delete"
								}
							]
						},
						{
							title: "Tipos de Carga",
							items: [
								{
									title: "Listar",
									name: "admin-loadtypes-list"
								},{
									title: "Criar",
									name: "admin-loadtypes-create"
								},{
									title: "Editar",
									name: "admin-loadtypes-edit"
								},{
									title: "Deletar",
									name: "admin-loadtypes-delete"
								}
							]
						},
						{
							title: "Tipos de Consumo",
							items: [
								{
									title: "Listar",
									name: "admin-consumptiontypes-list"
								},{
									title: "Criar",
									name: "admin-consumptiontypes-create"
								},{
									title: "Editar",
									name: "admin-consumptiontypes-edit"
								},{
									title: "Deletar",
									name: "admin-consumptiontypes-delete"
								}
							]
						},
						{
							title: "Tipos de EPI",
							items: [
								{
									title: "Listar",
									name: "admin-epitypes-list"
								},{
									title: "Criar",
									name: "admin-epitypes-create"
								},{
									title: "Editar",
									name: "admin-epitypes-edit"
								},{
									title: "Deletar",
									name: "admin-epitypes-delete"
								}
							]
						},
						{
							title: "Mensagens",
							items: [
								{
									title: "Listar",
									name: "admin-messages-list"
								},{
									title: "Criar",
									name: "admin-messages-create"
								},{
									title: "Editar",
									name: "admin-messages-edit"
								},{
									title: "Deletar",
									name: "admin-messages-delete"
								}
							]
						},
						{
							title: "Status de Incidente",
							items: [
								{
									title: "Listar",
									name: "admin-incidentstatus-list"
								},{
									title: "Criar",
									name: "admin-incidentstatus-create"
								},{
									title: "Editar",
									name: "admin-incidentstatus-edit"
								},{
									title: "Deletar",
									name: "admin-incidentstatus-delete"
								}
							]
						},
						{
							title: "Status de Atividade",
							items: [
								{
									title: "Listar",
									name: "admin-activitystatus-list"
								},{
									title: "Criar",
									name: "admin-activitystatus-create"
								},{
									title: "Editar",
									name: "admin-activitystatus-edit"
								},{
									title: "Deletar",
									name: "admin-activitystatus-delete"
								}
							]
						},
						{
							title: "Periodicidade",
							items: [
								{
									title: "Listar",
									name: "admin-frequencies-list"
								},{
									title: "Criar",
									name: "admin-frequencies-create"
								},{
									title: "Editar",
									name: "admin-frequencies-edit"
								},{
									title: "Deletar",
									name: "admin-frequencies-delete"
								}
							]
						},
						{
							title: "Permitir Atraso",
							items: [
								{
									title: "Listar",
									name: "admin-allowdelays-list"
								},{
									title: "Criar",
									name: "admin-allowdelays-create"
								},{
									title: "Editar",
									name: "admin-allowdelays-edit"
								},{
									title: "Deletar",
									name: "admin-allowdelays-delete"
								}
							]
						},
						{
							title: "Vínculo Atraso / Periodicidade",
							items: [
								{
									title: "Listar",
									name: "admin-allowdelayfrequency-list"
								},{
									title: "Criar",
									name: "admin-allowdelayfrequency-create"
								},{
									title: "Editar",
									name: "admin-allowdelayfrequency-edit"
								},{
									title: "Deletar",
									name: "admin-allowdelayfrequency-delete"
								}
							]
						},
						{
							title: "Tipos de Problemas",
							items: [
								{
									title: "Listar",
									name: "admin-typeproblems-list"
								},{
									title: "Criar",
									name: "admin-typeproblems-create"
								},{
									title: "Editar",
									name: "admin-typeproblems-edit"
								},{
									title: "Deletar",
									name: "admin-typeproblems-delete"
								}
							]
						},
						{
							title: "Categorias",
							items: [
								{
									title: "Listar",
									name: "admin-categories-list"
								},{
									title: "Criar",
									name: "admin-categories-create"
								},{
									title: "Editar",
									name: "admin-categories-edit"
								},{
									title: "Deletar",
									name: "admin-categories-delete"
								}
							]
						},
						{
							title: "Subcategorias",
							items: [
								{
									title: "Listar",
									name: "admin-subcategories-list"
								},{
									title: "Criar",
									name: "admin-subcategories-create"
								},{
									title: "Editar",
									name: "admin-subcategories-edit"
								},{
									title: "Deletar",
									name: "admin-subcategories-delete"
								}
							]
						},
						{
							title: "Grupo de Colaboradores",
							items: [
								{
									title: "Listar",
									name: "admin-colaboratorgroups-list"
								},{
									title: "Criar",
									name: "admin-colaboratorgroups-create"
								},{
									title: "Editar",
									name: "admin-colaboratorgroups-edit"
								},{
									title: "Deletar",
									name: "admin-colaboratorgroups-delete"
								}
							]
						},
						{
							title: "Profissões / Cargos",
							items: [
								{
									title: "Listar",
									name: "admin-professions-list"
								},{
									title: "Criar",
									name: "admin-professions-create"
								},{
									title: "Editar",
									name: "admin-professions-edit"
								},{
									title: "Deletar",
									name: "admin-professions-delete"
								}
							]
						},
						{
							title: "Tipos de Empresa",
							items: [
								{
									title: "Listar",
									name: "admin-companytypes-list"
								},{
									title: "Criar",
									name: "admin-companytypes-create"
								},{
									title: "Editar",
									name: "admin-companytypes-edit"
								},{
									title: "Deletar",
									name: "admin-companytypes-delete"
								}
							]
						},{
							title: "Certificações",
							items: [
								{
									title: "Listar",
									name: "admin-certifications-list"
								},{
									title: "Criar",
									name: "admin-certifications-create"
								},{
									title: "Editar",
									name: "admin-certifications-edit"
								},{
									title: "Deletar",
									name: "admin-certifications-delete"
								}
							]
						}
					]
				},
				permissions: [],
				type: "menu",
			}
		},
		mounted() {
		},
		methods: {
			changeToggle(toggle){

				//validate if have permissions
				if (this.permissions.length == 0){

					//push permission
					this.permissions.push(toggle);

				} else {

					//check if permission exists in array
					var check = this.permissions.includes(toggle);

					//validate check
					if (check){

						//remove permission
						this.permissions.pop(toggle);

					} else {

						//add permission
						this.permissions.push(toggle);

					}
					//end if validate check

				}
				//end validate if have permissions

			},
			newData() {
				if (this.name != ""){

					let _this = this;
					_this.errors = [];
					_this.message = "";
					_this.loading = true;

					let dataPermission = {
						"name": _this.name,
						"permission": _this.permission,
						"permissions": _this.permissions
					};
					axios.post("/admin/permissions", dataPermission).then((res) => {

						_this.loading = false;

						//call function in ApiService to validate success";
						_this._api_validateSuccess(res);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			}
		}
	}
</script>