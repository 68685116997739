<template>
	<div class="tab-pane fade" id="tab-subsectors" role="tabpanel" aria-labelledby="subsectors-tab" v-on:show="teste()">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Subsetores cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalSubsectors"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<a class="btn btn-outline-secondary float-right" @click="generateCode()"><i class="fas fa-qrcode"></i>&nbsp;Gerar</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="subsectorsItems.length"
						:columns="tableColumns"
						:rows="subsectorsItems"
						:select-options="{ enabled: true }"
						:pagination-options="tablePagination"
						@on-selected-rows-change="selectionChanged"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_default'">
								<div v-if="props.row.default == 1">Sim</div>
								<div v-else>Não</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum subsetor cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalSubsectors" tabindex="-1" aria-labelledby="modalSubsectorsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalSubsectorsLabel">{{subsectorsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" maxlength="60" class="form-control" placeholder="Nome" v-model="subsector.name" >
											<div class="invalid-feedback d-block" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>

									<!-- sector_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-sector_id">Setor</label>
											<v-select :options="sectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="subsector.sector_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.sector_id">
												<p>{{ errors.sector_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- default -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-sector_id">Armazenamento (itens neste subsetor recebem o status "pausa")</label>
											<v-select :options="optionsDefault" v-bind:class="{'is-invalid': errors.default}" :reduce="label => label.value" v-model="subsector.default"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.default">
												<p>{{ errors.default[0] }}</p>
											</div>
										</div>
									</div>

									<!-- templates -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-selectedTemplates">Templates</label>
											<v-select :options="templatesItems" label="name" multiple v-bind:class="{'is-invalid': errors.selectedTemplates}" :reduce="name => name.id" v-model="subsector.selectedTemplates"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.selectedTemplates">
												<p>{{ errors.selectedTemplates[0] }}</p>
											</div>
										</div>
									</div>

									<!-- description -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-description">Descrição</label>
											<textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" class="form-control" placeholder="Descrição" v-model="subsector.description" rows="8" maxlength="250"></textarea>
											<div class="invalid-feedback d-block" v-if="errors.description">
												<p>{{ errors.description[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions','workplaces'],
		data() {
			return {
				workPlaceID: '',
				sectors: [],
				subsectorsTitle: "Novo Subsetor",
				subsector: {
					name: '',
					description: '',
					sector_id: '',
					default: 0,
					selectedTemplates: []
				},
				gridSelected: [],
				isEditSubsectors: false,
				subsectorsID: "",
				subsectorsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				templatesItems: [],
				optionsDefault: [{
					label: "Não",
					value: 0
				},{
					label: "Sim",
					value: 1
				}],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Setor',
						field: 'sectors.name',
					},{
						label: 'Armazenamento',
						field: '_default',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.workPlaceID = (JSON.parse(this.workplaces)).id;
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadSectors();
			this.loadTemplates();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/subsectors/"+_this.workPlaceID+"/search").then((res) => {
					_this.subsectorsItems = _this.subsectorsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadTemplates(){
				let _this = this;
				_this.loading = true;
				let dataFilter = {
					"type" : "inventory",
				};
				axios.post("/admin/templates/filter", dataFilter).then((res) => {

					_this.loading = false;

					_this.templatesItems = [];
					_this.templatesItems = res.data.data;

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},	
			generateCode(){
				if(this.gridSelected.length == 0){

					this.$swal({
						title: 'Erro',
						text: "Selecione os Subsetores que deseja Gerar os QRCodes",
						icon: 'error'
					});

				} else {

					let _this = this;
					_this.loading = true;

					let dataInfo = {
						"subsectors": _this.gridSelected
					};
					axios.post("/admin/subsectors/generate/image", dataInfo).then((res) => {

						_this.loading = false;

						//window.open('https://mvtecnologia.s3.us-east-1.amazonaws.com/items/exported/qrcode.zip','_blank');
						//window.open('https://mvtecnologia.s3.us-east-1.amazonaws.com/subsectors/exported/'+res.data.file,'_blank');
						window.open('/storage/subsectors/exported/'+res.data.file,'_blank');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				}

			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditSubsectors == true){

					let dataInfo = {
						"name" : _this.subsector.name,
						"description" : _this.subsector.description,
						"sector_id" : _this.subsector.sector_id,
						"default": _this.subsector.default,
						"workplace": _this.workPlaceID,
						"templates": _this.subsector.selectedTemplates,
						"_method": "PATCH"
					};
					axios.post("/admin/subsectors/"+_this.subsectorsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalSubsectors').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.subsector.name,
						"description" : _this.subsector.description,
						"sector_id" : _this.subsector.sector_id,
						"workplace": _this.workPlaceID,
						"default": _this.subsector.default,
						"templates": _this.subsector.selectedTemplates,
						"last": ""
					};
					axios.post("/admin/subsectors", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.subsectorsItems.push(res.data.data);
						$('#modalSubsectors').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Subsetor",
					text: "Tem certeza que deseja excluir esse subsetor?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/subsectors/"+ID).then((res) => {

							_this.subsectorsItems.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,false);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.subsector = item;
				this.isEditSubsectors = true;
				this.subsectorsID = item.id;

				this.subsector.selectedTemplates = [];
				item.templates.map((rowTemplate) => {
					this.subsector.selectedTemplates.push(rowTemplate.template.id);
				});

				this.subsectorsTitle = "Editar Subsetor";

				$("#modalSubsectors").modal('show');

			},
			loadSectors() {
				let _this = this;
				_this.sectors = [];
				axios.get("/admin/sectors/"+_this.workPlaceID+"/search").then((res) => {
					_this.sectors = _this.sectors.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.subsector = {
					name: '',
					description: '',
					sector_id: '',
				};
				this.errors = [];
				this.loadSectors();

				this.isEditSubsectors = false;
				this.subsectorsID = "";
				this.subsectorsTitle = "Novo Subsetor";

			},
			selectionChanged(params) {
				this.gridSelected = params.selectedRows;
			}
		}
	}
</script>
