<template>
	<div>

    <!-- modal -->
    <div class="modal fade" id="modalSystemFields" tabindex="-1" aria-labelledby="modalSystemFieldsLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalSystemFieldsLabel">Exportar Relatório Completo</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form v-on:submit.prevent="exportData">

              <div class="row">

                <!-- type -->
                <div :class="info.source === 'colaborators' ? 'col-md-6' : 'col-md-12'">
                  <div class="form-group">
                    <label class="form-control-label" for="input-type">Tipo de Relatório</label>
                    <v-select :options="types" label="type" v-model="type"></v-select>
                  </div>
                </div>

                <!-- colaborator status -->
                <div class="col-md-6" v-if="info.source === 'colaborators'">
                  <div class="form-group">
                    <label class="form-control-label" for="input-status">Status</label>
                    <v-select :options="optionsStatus" :reduce="label => label.value" v-model="colaboratorStatus"></v-select>
                  </div>
                </div>
            

                <!-- fields -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos</label>
                    <v-select :options="fields" label="name" multiple :reduce="name => name.field" v-model="field" @input="validateFields()"></v-select>
                  </div>
                </div>

                <!-- items filter -->
                <div class="col-md-12" v-if="info.source == 'items' || info.source == 'activities' || info.source == 'occurrences'">
                  <div class="row">
                    
                    <!-- company id -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-work_place_id">Empresa</label>
                        <v-select :options="filterCompanies" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filterAdditional.company_id" @input="validateCompany()"></v-select>
                      </div>
                    </div>

                    <!-- contract -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-work_place_id">Contrato</label>
                        <v-select :options="filterContracts" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filterAdditional.contract_id"></v-select>
                      </div>
                    </div>

                    <!-- work_place_id -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
                        <v-select :options="filterWorkplaces" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filterAdditional.work_place_id" @input="validateWorkplace()"></v-select>
                      </div>
                    </div>

                    <!-- sublocal -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-sublocal">Sublocal</label>
                        <v-select :options="filterSublocal" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filterAdditional.sublocal_id" @input="validateSublocal('filter')"></v-select>
                      </div>
                    </div>

                    <!-- sector_id -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-sector_id">Setor</label>
                        <v-select :options="filterSectors" label="name" :reduce="name => name.id" v-model="filterAdditional.sector_id" @input="validateSector()"></v-select>
                      </div>
                    </div>
                    
                    <!-- subsector_id -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-subsector_id">Subsetor</label>
                        <v-select :options="filterSubsectors" :reduce="name => name.id" label="name" v-model="filterAdditional.subsector_id"></v-select>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- end items filter -->

                <!-- period -->
                <div class="col-md-12" v-if="info.source === 'activities'">
                  <div class="form-group">
                    <label>Selecione o Período (Data Inicial e Data Final)</label>

                    <div class="row">
                      <div class="col-md-6">
                        <input type="date" id="input-start" class="form-control" v-model="startDate">
                      </div>
                      <div class="col-md-6">
                        <input type="date" id="input-end" class="form-control" v-model="endDate">
                      </div>
                    </div>
                  </div>
                </div>

                
                <div class="col-md-12" style="margin: 0;" v-if="hasRelationship">
                  <hr/>
                  <h5 style="font-size: 14px;" class="text-warning">Relacionamentos</h5>
                  <h5 style="font-size: 12px;" class="text-secondary">selecione os campos para ativar os relacionamentos</h5>
                </div>

                <div class="col-md-12" v-if="relationshipHas.contracts">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>CONTRATO</b></label>
                    <v-select :options="relationshipFields.contracts" label="name" multiple v-model="relationshipValues.contracts"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.colaboratorCourses">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos dos <b>CURSOS DO COLABORADOR</b></label>
                    <v-select :options="relationshipFields.colaboratorCourses" label="name" multiple v-model="relationshipValues.colaboratorCourses"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.companies">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos da <b>EMPRESA</b></label>
                    <v-select :options="relationshipFields.companies" label="name" multiple v-model="relationshipValues.companies"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.items">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>ITEM</b></label>
                    <v-select :options="relationshipFields.items" label="name" multiple v-model="relationshipValues.items"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.workPlaceResponsibles">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos dos <b>RESPONSÁVEIS</b></label>
                    <v-select :options="relationshipFields.workPlaceResponsibles" label="name" multiple v-model="relationshipValues.workPlaceResponsibles"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.sectors">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>SETOR</b></label>
                    <v-select :options="relationshipFields.sectors" label="name" multiple v-model="relationshipValues.sectors"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.activityStatus">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>STATUS DE ATIVIDADE</b></label>
                    <v-select :options="relationshipFields.activityStatus" label="name" multiple v-model="relationshipValues.activityStatus"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.incidentStatus">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>STATUS DE INCIDÊNCIA</b></label>
                    <v-select :options="relationshipFields.incidentStatus" label="name" multiple v-model="relationshipValues.incidentStatus"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.subsectors">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>SUBSETOR</b></label>
                    <v-select :options="relationshipFields.subsectors" label="name" multiple v-model="relationshipValues.subsectors"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.sublocal">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>SUBLOCAL DE TRABALHO</b></label>
                    <v-select :options="relationshipFields.sublocal" label="name" multiple v-model="relationshipValues.sublocal"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.templates">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>TEMPLATE</b></label>
                    <v-select :options="relationshipFields.templates" label="name" multiple v-model="relationshipValues.templates"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.users">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>USUÁRIO</b></label>
                    <v-select :options="relationshipFields.users" label="name" multiple v-model="relationshipValues.users"></v-select>
                  </div>
                </div>

                <div class="col-md-12" v-if="relationshipHas.work_places">
                  <div class="form-group">
                    <label class="form-control-label" for="input-fields">Selecione os Campos do <b>LOCAL DE TRABALHO</b></label>
                    <v-select :options="relationshipFields.work_places" label="name" multiple v-model="relationshipValues.work_places"></v-select>
                  </div>
                </div>
            
              </div>

              <div class="text-right">
                <button type="submit" class="btn btn-success">EXPORTAR</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div>
</template>

<script>
	import ApiService from '../apiservice.js';
	export default {
		mixins: [ApiService],
		props: [],
		data() {
			return {
        field: [],
        fields: [],
        filterAdditional: {},
        filterCompanies: [],
        filterContracts: [],
        filterWorkplaces: [],
        filterSublocal: [],
        filterSectors: [],
        filterSubsectors: [],
        fullPage: true,
        hasRelationship: false,
        loading: false,
        relationshipFields: {
          activityStatus: [],
          colaboratorCouses: [],
          companies: [],
          contracts: [],
          items: [],
          incidentStatus: [],
          sectors: [],
          subsectors: [],
          sublocal: [],
          templates: [],
          users: [],
          work_places: [],
          workPlaceResponsibles: []
        },
        relationshipHas: {
          activityStatus: false,
          colaboratorCourses: false,
          companies: false,
          contracts: false,
          items: false,
          incidentStatus: false,
          sectors: false,
          subsectors: false,
          sublocal: false,
          templates: false,
          users: false,
          work_places: false,
          workPlaceResponsibles: false
        },
        relationshipLoads: [],
        relationshipValues: {
          activityStatus: [],
          colaboratorCourses: [],
          companies: [],
          contracts: [],
          items: [],
          incidentStatus: [],
          sectors: [],
          subsectors: [],
          sublocal: [],
          templates: [],
          users: [],
          work_places: [],
          workPlaceResponsibles: []
        },
        optionsStatus: [{
					label: 'Ativo',
					value: 'active'
				},{
					label: 'Inativo',
					value: 'inactive'
				}],
        info: {
          table: '',
          source: ''
        },
        tempSubsectors: [],
        startDate: '',
        endDate: '',
        colaboratorStatus: 'active',
        type: 'Excel',
				types: ['Excel']
			}
		},
		mounted() {

		},
		methods: {
      exportData(){
        let _this = this;
				_this.errors = [];

        //get headings
        var exportHead = "";

        //read all selected fields
        _this.field.map((rowField) => {

          //read all fields
          _this.fields.map((rowTempField) => {

            //validate field
            if(rowTempField.field == rowField){
              exportHead += rowTempField.name+",";
            }

          });

        });

        //remove last character
        var exportHead = exportHead.substring(0, exportHead.length - 1);

        //mount url
        var url = "/admin/reports/full?type="+_this.info.table+"&head="+JSON.stringify(exportHead)+"&fields="+JSON.stringify(_this.field)+"&start="+_this.startDate+"&end="+_this.endDate+"&relationship="+JSON.stringify(_this.relationshipValues)+"&addittional="+JSON.stringify(_this.filterAdditional)+"&status_colaborator="+_this.colaboratorStatus;

        //open
        window.open(url,'_blank');

        // let dataInfo = {
        //   "type" : _this.source,
        //   "fields" : _this.field,
        //   "relationship": _this.relationshipFields
        // };
        // axios.post("/admin/reports/full", {responseType: 'blob'}, dataInfo).then((res) => {

        //   _this.loading = false;

        //   $('#modal').modal('hide');

        //   var fileDownload = require('js-file-download');
        //   fileDownload(res, 'filename.xlsx');


        //   //call function in ApiService to validate success
        //   //_this._api_validateSuccess(res,false);

        // }).catch((err) => {

        //   _this.loading = false;

        //   //set errors
        //   _this.errors = err.response.data.errors;

        //   //call function in ApiService to validate error
        //   _this._api_validateError(err);

        // });
      },
      loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					_this.filterCompanies = _this.filterCompanies.concat(res.data);
				}).catch((err) => {
				});
			},
      loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.filterContracts = [];
				axios.get("/admin/companycontracts/"+_this.filterAdditional.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.filterContracts.push({
							id: rowContract.contract_id,
							corporate_name: rowContract.contracts.corporate_name
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      loadRelationships(){
        let _this = this;

        _this.relationshipLoads.map((rowRelationship) => {

          //define search term
          var searchTerm = '';

          //validate auxiliares
          if(rowRelationship == 'activityStatus' || rowRelationship == 'incidentStatus'){
            searchTerm = 'auxiliares';
          } else if (rowRelationship == 'colaboratorCourses'){
            searchTerm = 'colaborator_courses';
          } else if (rowRelationship == 'workPlaceResponsibles'){
            searchTerm = 'work_place_responsibles';
          } else {
            searchTerm = rowRelationship;
          }
          
          //get fields
          axios.get("/admin/system/fields/"+searchTerm).then((res) => {
            var tempFields = JSON.parse(res.data.fields);

            // _this.completeRelationship(rowRelationship, tempFields);
            _this.relationshipFields[rowRelationship] = tempFields;

          }).catch((err) => {
          });

        });

      },
      loadSectors() {
				let _this = this;
				_this.loading = true;

        _this.filterSectors = [];

        axios.get("/admin/sectors/"+_this.filterAdditional.sublocal_id+"/search").then((res) => {
          _this.filterSectors = _this.filterSectors.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			},
			loadSubsectors() {
				let _this = this;
				_this.loading = true;

        _this.filterSubsectors = [];
        _this.tempSubsectors = [];
      
        axios.get("/admin/subsectors/"+_this.filterAdditional.sublocal_id+"/search").then((res) => {
          _this.tempSubsectors = _this.tempSubsectors.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			},
      showModal(type, table){
        let _this = this;
				_this.loading = true;

        //get informações from origem
        this.info.source = type;
        this.info.table = table;

        //validate additional filter
        if(type == 'items' || type == 'activities' || type == 'occurrences'){
          this.loadCompanies();
        }
        
        //get fields
				axios.get("/admin/system/fields/"+type).then((res) => {

					var tempFields = JSON.parse(res.data.fields);

          _this.fields = tempFields;

          $("#modalSystemFields").modal('show');

          _this.validateRelationships();

					_this.loading = false;

				}).catch((err) => {
					_this.loading = false;
				});

      },
      validateCompany(){
				let _this = this;
				_this.loading = true;

        _this.filterWorkplaces = [];
        axios.get("/admin/companyworkplaces/"+_this.filterAdditional.company_id+'/search').then((res) => {
          res.data.map((rowReturn) => {
            _this.filterWorkplaces = _this.filterWorkplaces.concat(rowReturn.work_places);
          });
          _this.loadContracts();
        }).catch((err) => {
        });
        this.loading = false;
			},
      validateFields(){

        //define variables
        var inputActivityStatus = false;
        var inputCompanies = false;
        var inputContracts = false;
        var inputItems = false;
        var inputIncidentStatus = false;
        var inputSublocal = false;
        var inputTemplates = false;
        var inputWorkplaces = false;

        if(this.info.source == 'work_places'){
          var inputSublocal = true;
        } else {
          var inputSublocal = false;
        }

        //validate selected fields
        this.field.map((rowField) => {

          if(rowField == 'activity_status_id'){
            inputActivityStatus = true;
          }

          if(rowField == 'company_id'){
            inputCompanies = true;
          }

          if(rowField == 'contract_id'){
            inputContracts = true;
          }

          if(rowField == 'item_id'){
            inputItems = true;
          }

          if(rowField == 'incident_status_id'){
            inputIncidentStatus = true;
          }

          if(rowField == 'sublocal_id'){
            inputSublocal = true;
          }

          if(rowField == 'template_id'){
            inputTemplates = true;
          }

          if(rowField == 'work_place_id'){
            if(this.info.source == 'occurrences'){
              inputSublocal = true;
            } else {
              inputWorkplaces = true;
            }
          }

        });

        //apply result validation
        this.relationshipHas.activityStatus = inputActivityStatus;
        this.relationshipHas.companies = inputCompanies;
        this.relationshipHas.contracts = inputContracts;
        this.relationshipHas.items = inputItems;
        this.relationshipHas.incidentStatus = inputIncidentStatus;
        this.relationshipHas.sublocal = inputSublocal;
        this.relationshipHas.templates = inputTemplates;
        this.relationshipHas.work_places = inputWorkplaces;

      },
      validateRelationships(){

        switch (this.info.source) {
          case 'activities':
            this.relationshipLoads = ['activityStatus','companies','contracts','items','sublocal','templates','work_places'];
            this.hasRelationship = true;
            this.loadRelationships();
            break;

          case 'colaborators':
            this.relationshipLoads = ['colaboratorCourses','users'];
            this.hasRelationship = true;
            this.relationshipHas.colaboratorCourses = true;
            this.relationshipHas.users = true;
            this.loadRelationships();
            break;

          case 'occurrences':
            this.relationshipLoads = ['items','companies','sublocal','templates','incidentStatus'];
            this.hasRelationship = true;
            this.loadRelationships();
            break;

          case 'sublocal':
            this.relationshipLoads = ['sectors','subsectors'];
            this.hasRelationship = true;
            this.relationshipHas.sectors = true;
            this.relationshipHas.subsectors = true;
            this.loadRelationships();
            break;

          case 'work_places':
            this.relationshipLoads = ['workPlaceResponsibles','sublocal'];
            this.hasRelationship = true;
            this.relationshipHas.workPlaceResponsibles = true;
            this.relationshipHas.sublocal = true;
            this.loadRelationships();
            break;
        
          default:
            this.hasRelationship = false;
            break;
        }

      },
      validateSector(){
				let _this = this;
				_this.filterSubsectors = [];

				_this.tempSubsectors.map((rowSubsector) => {
					if(rowSubsector.sector_id == _this.filterAdditional.sector_id){
						_this.filterSubsectors.push(rowSubsector);
					}
				});
			},
      validateSublocal(source){
				let _this = this;

				_this.loadSectors(source);
				_this.loadSubsectors(source);

			},
      validateWorkplace(){
				let _this = this;
        _this.loading = true;

        _this.filterSublocal = [];
        axios.get("/admin/workplacesublocal/"+_this.filterAdditional.work_place_id+"/search").then((res) => {
          res.data.map((rowSublocal) => {
            _this.filterSublocal.push({
              corporate_name: rowSublocal.sublocal.corporate_name,
              id: rowSublocal.sublocal_id
            });
          });
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			}
		}
	}
</script>
