<template>
  <div class="row">
    <div class="col">

      <!-- table -->
      <div class="card p-4">
        <vue-good-table v-if="logs.length"
          :columns="tableColumns"
          :rows="logs"
          :pagination-options="tablePagination"
          :search-options="{
            enabled: true,
            placeholder: 'Buscar..',
          }"
          styleClass="vgt-table"
			  >
			  </vue-good-table>
        <div v-else class="text-center p-3 text-muted">
            <h5>Nenhum log cadastrado!</h5>
        </div>
      </div>
      <!-- end table -->

      <div class="text-center p-4 text-muted" v-if="loading && logs.length == 0">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>

    </div><!-- col -->
  </div><!-- row -->
</template>

<script>
  export default {
    data() {
      return {
        logs: [],
        loading: false,
        tablePagination: {
          enabled: true,
          nextLabel: 'próxima',
          prevLabel: 'anterior',
          rowsPerPageLabel: 'Registros por página',
          ofLabel: 'de',
          pageLabel: 'página', // for 'pages' mode
          allLabel: 'Todos',
        },
        tableColumns: [
          {
            label: "E-mail",
            field: "email",
          },{
            label: "IP",
            field: "ip",
          },{
            label: "Descrição",
            field: "description",
          },{
            label: "Status",
            field: "status",
          },{
            label: 'Data e Hora',
            field: 'created_at',
            type: 'date',
            dateInputFormat: "yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSXXX",
            dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
          }
        ]
      }
    },
    mounted() {
      this.loadLogs();
    },
    methods: {
      loadLogs() {
        let _this = this;
        _this.loading = true;
        axios.get('/admin/log/access').then((res) => {
          _this.logs = _this.logs.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      }
    }
  }
</script>
