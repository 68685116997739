<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Templates cadastrados</p>
				<a href="/admin/templates/create" class="btn btn-outline-success float-right"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<a class="btn btn-outline-warning float-right" data-toggle="collapse" href="#panelFilter" role="button" aria-expanded="false" aria-controls="panelFilter"><i class="fas fa-filter"></i>&nbsp;Filtrar</a>
				<div class="dropdown float-right">
					<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-download"></i>
						Exportar
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#" @click="exportData('pdf')">PDF</a>
						<a class="dropdown-item" href="#" @click="exportData('csv')">Excel</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="#" @click="$refs.gridfullexport.showModal('templates','templates')">Completo</a>
					</div>
				</div>
			</div>
		</div>


		<div class="row">
			<div class="col-md-12">
				<div class="collapse" id="panelFilter">
					<div class="card card-body">

						<div class="row">

							<!-- name -->
							<div class="col-md-6">
								<label class="form-control-label" for="input-name">Nome</label>
								<input type="text" id="input-name"   class="form-control" placeholder="Nome" v-model="filterItems.name" required="false" >
							</div>

							<!-- type -->
							<div class="col-md-2">
								<label class="form-control-label" for="input-type">Tipo</label>
								<v-select :options="optionsType" :reduce="label => label.value" v-model="filterItems.type"></v-select>
							</div>

							<!-- frequency -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-frequency_id">Periodicidade</label>
									<v-select :options="frequencies" label="name" :reduce="name => name.id" v-model="filterItems.frequency_id"></v-select>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							
							<div class="col-md-12 text-right mt-2">
								<button class="btn btn-success" @click="filter()">FILTRAR</button>
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="templatesItems.length"
						:columns="tableColumns"
						:rows="templatesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_type'">
								<p v-if="props.row.type == 'consumption'">Consumo</p>
								<p v-if="props.row.type == 'checklist'">Checklist</p>
							</span>
							<span v-if="props.column.field == '_frequency'">
								{{validateFrequency(props.row.frequency_id)}}
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum templates cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<gridfullexport-component ref="gridfullexport"></gridfullexport-component>
			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		props: ['permissions'],
		data() {
			return {
				frequencies: [],
				templatesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				optionsType: [{
					label: 'Consumo',
					value: 'consumption'
				},{
					label: 'Checklist',
					value: 'checklist'
				}],
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},
					{
						label: 'Nome',
						field: 'name',
					},
					{
						label: 'Tipo',
						field: '_type',
					},
					{
						label: 'Periodicidade',
						field: '_frequency',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				filterItems: {},
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadFrequencies();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/templates").then((res) => {
					_this.templatesItems = _this.templatesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadFrequencies() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/frequencies").then((res) => {
					_this.frequencies = _this.frequencies.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir templates",
					text: "Tem certeza que deseja excluir esse templates?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/templates/"+ID).then((res) => {

							//_this.templates.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			exportData(type){
        let params = type == 'pdf' ? {
					type: 'pdf',
					fileName: 'Templates',
					ignoreColumn: [5],
					htmlContent: false,
					onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
					jspdf: {
						orientation: 'l',
						margins: {right: 10, left: 10, top: 40, bottom: 40},
						autotable: {
							styles: {
								fillColor: 'inherit',
								textColor: 'inherit',
								halign: 'center',
								fillStyle: 'DF',
								lineColor: 200,
								lineWidth: 0.1
							},
							headerStyles: {halign: 'left'},
							tableWidth: 'auto'
						}
					}
        } : {
					type: 'excel',
					htmlContent: false,
					fileName: 'Templates',
					ignoreColumn: [8],
					mso:  {fileFormat: 'xlsx'}
        };

        let options = { tableName: 'Templates' };
        jQuery.extend(true, options, params);
        $('#vgt-table').tableExport(options);

    	},
			filter(){
				let _this = this;
				_this.loading = true;
				let dataFilter = {
					"name" : _this.filterItems.name,
					"type" : _this.filterItems.type,
					"frequency_id": _this.filterItems.frequency_id,
					"last": ""
				};
				axios.post("/admin/templates/filter", dataFilter).then((res) => {

					_this.loading = false;

					_this.templatesItems = [];
					_this.templatesItems = res.data.data;

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			editData(item){
				location.href="/admin/templates/"+item.id+"/edit";
			},
			validateFrequency(id){
				var tempName = "";
				this.frequencies.map((row) => {
					if (row.id == id){
						tempName = row.name;
					}
				});
				return tempName;
			},
			resetForm(){

				this.template = {
					name: '',
					description: '',
					type: '',
					frequency_id: '',
				};

			}
		}
	}
</script>
