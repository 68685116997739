<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<h6 class="heading-small text-muted mb-4">Acesso</h6>
					<div class="row">

						<!-- name -->
						<div class="col-md-12">
							<div class="form-group">
								<label class="form-control-label" for="input-name">Nome</label>
								<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"  class="form-control" placeholder="Nome" v-model="name" required="true">
								<div class="invalid-feedback d-block" v-if="errors.name">
									<p>{{ errors.name[0] }}</p>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="form-group">
								<label class="form-control-label" for="input-email">E-mail</label>
								<input type="email" id="input-email" v-bind:class="{'is-invalid': errors.email}" maxlength="255" class="form-control" placeholder="E-mail" v-model="email" required="true">
								<div class="invalid-feedback d-block" v-if="errors.email">
									<p>{{ errors.email[0] }}</p>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="form-group">
								<label class="form-control-label" for="input-password">Senha (tamanho máximo: 15 caracteres)</label>
								<div class="input-group">
									<input :type="inputType.type" v-bind:class="{'is-invalid': errors.password}" class="form-control" placeholder="Senha" v-model="password" required="true">
									<div class="input-group-append">
										<button class="btn btn-outline-secondary" @click="inputPasswordType()" type="button" id="button-addon2"><i :class="inputType.icon"></i></button>
									</div>
								</div>
								<div class="invalid-feedback d-block" v-if="errors.password">
									<p>{{ errors.password[0] }}</p>
								</div>
							</div>
						</div>

					</div>
					<!-- end form -->

					<div class="separator"></div>

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="row">

						<!-- cpf -->
						<div class="col-md-4">
							<div class="form-group">
								<label class="form-control-label" for="input-cpf">CPF</label>
								<input type="tel" id="input-cpf" v-bind:class="{'is-invalid': errors.cpf}"  maxlength="30" class="form-control" placeholder="CPF" v-model="cpf" required="false" v-mask="'###.###.###-##'">
								<div class="invalid-feedback d-block" v-if="errors.cpf">
									<p>{{ errors.cpf[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-md-3 -->
						<!-- end cpf -->

						<!-- phone -->
						<div class="col-md-4">
							<div class="form-group">
								<label class="form-control-label" for="input-phone">Telefone</label>
								<input type="text" id="input-phone" v-bind:class="{'is-invalid': errors.phone}"  maxlength="30" class="form-control" placeholder="Telefone" v-model="phone" required="true" v-mask="'(##) #####-####'">
								<div class="invalid-feedback d-block" v-if="errors.phone">
									<p>{{ errors.phone[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-md-4 -->
						<!-- end phone -->

						<!-- country -->
						<div class="col-md-4">
							<div class="form-group">
								<label class="form-control-label" for="input-country">País</label>
								<input type="text" id="input-country" v-bind:class="{'is-invalid': errors.country}"   class="form-control" placeholder="País" v-model="country" required="false" >
								<div class="invalid-feedback d-block" v-if="errors.country">
									<p>{{ errors.country[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-md-4 -->
						<!-- end country -->

						<!-- profession_id -->
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-control-label" for="input-profession_id">Cargo / Profissão</label>
								<v-select :options="professions" v-bind:class="{'is-invalid': errors.profession_id}" label="name" v-model="profession_id" :reduce="name => name.id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.profession_id">
									<p>{{ errors.profession_id[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-md-6 -->
						<!-- end profession_id -->

						<!-- status -->
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-control-label" for="input-status">Status</label>
								<v-select :options="optionsStatus" v-bind:class="{'is-invalid': errors.status}" :reduce="label => label.value" v-model="status"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.status">
									<p>{{ errors.status[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-md-4 -->
						<!-- end status -->

					</div>

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		data() {
			return {
				cpf: "",
				phone: "",
				country: "",
				status: "",
				profession_id: "",
				name: "",
				email: "",
				password: "",
				inputType: {
					icon: 'fas fa-eye',
					type: 'password',
					viewed: false
				},
				professions: [],
				optionsStatus: [{
					label: 'Ativo',
					value: 'active'
				},{
					label: 'Inativo',
					value: 'inactive'
				}],
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadProfessions();
		},
		methods: {
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"cpf" : _this.cpf,
					"phone" : _this.phone,
					"country" : _this.country,
					"status" : _this.status,
					"profession_id" : _this.profession_id,
					"name": _this.name,
					"email": _this.email,
					"password": _this.password,
					"last": ""
				};
				axios.post("/admin/colaborators", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);
					
				});
			},
			resetForm() {
				this.cpf = '';
				this.phone = '';
				this.country = '';
				this.status = '';
				this.user_id = '';
				this.profession_id = '';
			},
			loadProfessions() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/professions").then((res) => {
					_this.professions = _this.professions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			inputPasswordType(){
				if(this.inputType.viewed == true){

					this.inputType.icon = 'fas fa-eye';
					this.inputType.type = 'password';
					this.inputType.viewed = false;

				} else {

					this.inputType.icon = 'fas fa-eye-slash';
					this.inputType.type = 'text';
					this.inputType.viewed = true;

				}
			}
		}
	}
</script>
