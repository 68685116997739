<template>
	<div class="tab-pane fade" id="tab-companycolaborators" role="tabpanel" aria-labelledby="companycolaborators-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Colaboradores vinculados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalCompanyColaborator"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="colaboratorgroupsItems.length"
						:columns="tableColumns"
						:rows="colaboratorgroupsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum colaborador vinculado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalCompanyColaborator" tabindex="-1" aria-labelledby="modalCompanyColaboratorLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalCompanyColaboratorLabel">{{colaboratorgroupsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

                  <!-- colaborator -->
                  <div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-colaborator_id">Colaborador</label>
											<v-select :options="colaborators" v-bind:class="{'is-invalid': errors.colaborator}" v-model="colaborator_id" :reduce="label => label.value"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.colaborator">
												<p>{{ errors.colaborator[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['companies','permissions'],
		data() {
			return {
        colaborator_id: '',
        colaborators: [],
				colaboratorgroupsTitle: "Vincular Colaborador",
				colaboratorgroup: {
					name: '',
				},
				companyID: '',
				isEditCompanyColaborators: false,
				companyColaboratorID: "",
				colaboratorgroupsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'colaborator_id',
					},{
						label: 'Nome',
						field: 'colaborators.users.profile.name',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.companyID = (JSON.parse(this.companies)).id;
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
      this.loadColaborators();
		},
		methods: {
      loadColaborators() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/colaborators/search").then((res) => {
          res.data.map((row) => {
            _this.colaborators = _this.colaborators.concat({
              label: row.users.profile.name,
              value: row.id
            });
          });
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+this.companyID+"/search").then((res) => {
					_this.colaboratorgroupsItems = _this.colaboratorgroupsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditCompanyColaborators == true){

					let dataInfo = {
						"company": _this.companyID,
            "colaborator": _this.colaborator_id,
						"_method": "PATCH"
					};

					axios.post("/admin/companycolaborators/"+_this.companyColaboratorID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalCompanyColaborator').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"company": _this.companyID,
            "colaborator": _this.colaborator_id,
						"last": ""
					};
					axios.post("/admin/companycolaborators", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.colaboratorgroupsItems.push(res.data.data);
						$('#modalCompanyColaborator').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir vínculo de colaborador",
					text: "Tem certeza que deseja excluir esse vínculo?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/companycolaborators/"+ID).then((res) => {

							_this.colaboratorgroupsItems.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,false);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.colaborator_id = item.colaborator_id;
				this.colaborator_group_id = item.colaborator_group_id;

				this.isEditCompanyColaborators = true;
				this.companyColaboratorID = item.id;
				this.colaboratorgroupsTitle = "Editar vínculo de Colaborador";

				$("#modalCompanyColaborator").modal('show');

			},
			resetForm(){

				this.colaborator_id = "";
				this.colaborator_group_id = "";
				this.errors = [];

			}
		}
	}
</script>
