<template>
	<div class="tab-pane fade" id="tab-workplaceresponsibles" role="tabpanel" aria-labelledby="workplaceresponsibles-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Responsáveis cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalWorkPlaceResponsibles"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<a class="btn btn-outline-warning float-right" data-toggle="collapse" href="#panelFilter" role="button" aria-expanded="false" aria-controls="panelFilter"><i class="fas fa-filter"></i>&nbsp;Filtrar</a>
			</div>
		</div>

		<!-- FILTER -->
		<div class="row">
			<div class="col-md-12">
				<div class="collapse" id="panelFilter">
					<div class="card card-body">

						<div class="row">

							<!-- name -->
							<div class="col-md-4">
								<label class="form-control-label" for="input-name">Nome</label>
								<input type="text" id="input-name"   class="form-control" placeholder="Nome" v-model="filterItems.name" required="true" >
							</div>
							
							<!-- phone -->
							<div class="col-md-4">
								<label class="form-control-label" for="input-phone">Telefone</label>
								<input type="tel" id="input-phone"  maxlength="30" class="form-control" placeholder="Telefone" v-model="filterItems.phone" required="false" v-mask="'(##) #####-####'">
							</div>

							<!-- email -->
							<div class="col-md-4">
								<label class="form-control-label" for="input-email">E-mail</label>
								<input type="email" id="input-email"   class="form-control" placeholder="E-mail" v-model="filterItems.email" required="false" >
							</div>
							
							<div class="col-md-12 mt-2 text-right">
								<button class="btn btn-success" @click="filter()">FILTRAR</button>
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- END FILTER -->

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="workplaceresponsiblesItems.length"
						:columns="tableColumns"
						:rows="workplaceresponsiblesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum responsável cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalWorkPlaceResponsibles" tabindex="-1" aria-labelledby="modalWorkPlaceResponsiblesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalWorkPlaceResponsiblesLabel">{{workplaceresponsiblesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" class="form-control" placeholder="Nome" v-model="workplaceresponsible.name" required="true" >
											<div class="invalid-feedback d-block" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>

									<!-- phone -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-phone">Telefone</label>
											<input type="tel" id="input-phone" v-bind:class="{'is-invalid': errors.phone}"  maxlength="30" class="form-control" placeholder="Telefone" v-model="workplaceresponsible.phone" required="false" v-mask="'(##) #####-####'">
											<div class="invalid-feedback d-block" v-if="errors.phone">
												<p>{{ errors.phone[0] }}</p>
											</div>
										</div>
									</div>

									<!-- email -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-email">E-mail</label>
											<input type="email" id="input-email" v-bind:class="{'is-invalid': errors.email}"   class="form-control" placeholder="E-mail" v-model="workplaceresponsible.email" required="false" >
											<div class="invalid-feedback d-block" v-if="errors.email">
												<p>{{ errors.email[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- observations -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-observations">Observações</label>
											<textarea type="text" id="input-observations" v-bind:class="{'is-invalid': errors.observations}" class="form-control" placeholder="Observações" v-model="workplaceresponsible.observations" rows="8"></textarea>
											<div class="invalid-feedback d-block" v-if="errors.observations">
												<p>{{ errors.observations[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['workplaces','permissions'],
		data() {
			return {
				workPlaceID: '',
				workplaceresponsiblesTitle: "Novo Reponsável",
				workplaceresponsible: {
					name: '',
					phone: '',
					email: '',
					observations: '',
				},
				isEditWorkPlaceResponsibles: false,
				workplaceresponsiblesID: "",
				workplaceresponsiblesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'name',
					},
					{
						label: 'Telefone',
						field: 'phone',
					},
					{
						label: 'E-mail',
						field: 'email',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				filterItems: {},
				userCan: ''
			}
		},
		mounted() {
			this.workPlaceID = (JSON.parse(this.workplaces)).id;
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/workplaceresponsibles/"+_this.workPlaceID+"/search").then((res) => {
					_this.workplaceresponsiblesItems = _this.workplaceresponsiblesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditWorkPlaceResponsibles == true){

					let dataInfo = {
						"name" : _this.workplaceresponsible.name,
						"phone" : _this.workplaceresponsible.phone,
						"email" : _this.workplaceresponsible.email,
						"observations" : _this.workplaceresponsible.observations,
						"workplace": _this.workPlaceID,
						"_method": "PATCH"
					};
					axios.post("/admin/workplaceresponsibles/"+_this.workplaceresponsiblesID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalWorkPlaceResponsibles').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.workplaceresponsible.name,
						"phone" : _this.workplaceresponsible.phone,
						"email" : _this.workplaceresponsible.email,
						"observations" : _this.workplaceresponsible.observations,
						"workplace": _this.workPlaceID,
						"last": ""
					};
					axios.post("/admin/workplaceresponsibles", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.workplaceresponsiblesItems.push(res.data.data);
						$('#modalWorkPlaceResponsibles').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Responsável",
					text: "Tem certeza que deseja excluir esse Responsável?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/workplaceresponsibles/"+ID).then((res) => {

							_this.workplaceresponsiblesItems.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,false);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.workplaceresponsible = item;
				this.isEditWorkPlaceResponsibles = true;
				this.workplaceresponsiblesID = item.id;
				this.workplaceresponsiblesTitle = "Editar Reponsável";

				$("#modalWorkPlaceResponsibles").modal('show');

			},
			filter(){
				let _this = this;
				_this.loading = true;
				let dataFilter = {
					"name" : _this.filter.name,
					"phone" : _this.filter.phone,
					"email" : _this.filter.email,
					"last": ""
				};
				axios.post("/admin/workplaceresponsibles/"+_this.workPlaceID+"/filter", dataFilter).then((res) => {

					_this.loading = false;

					_this.workplaceresponsiblesItems = [];
					_this.workplaceresponsiblesItems.push(res.data.data);

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			resetForm(){

				this.workplaceresponsible = {
					name: '',
					phone: '',
					email: '',
					observations: '',
				};
				this.errors = [];

				this.isEditWorkPlaceResponsibles = false;
				this.workplaceresponsiblesID = "";
				this.workplaceresponsiblesTitle = "Novo Reponsável";

			}
		}
	}
</script>
