<template>
	<div class="tab-pane fade" id="tab-itemattachments" role="tabpanel" aria-labelledby="itemattachments-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Anexos cadastrados</p>
				<a href="#" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalItemAttachments"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="itemattachmentsItems.length"
						:columns="tableColumns"
						:rows="itemattachmentsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_type'">
								<div v-if="props.row.type == 'image'">Imagem</div>
								<div v-if="props.row.type == 'pdf'">PDF</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" @click="viewFile(props.row)" title="Visualizar"><i class="far fa-eye"></i></button>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum anexo cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalItemAttachments" tabindex="-1" aria-labelledby="modalItemAttachmentsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalItemAttachmentsLabel">{{itemattachmentsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

							<!-- type -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-type">Tipo</label>
									<v-select v-model="itemattachment.type" v-bind:class="{'is-invalid': errors.type}" :options="optionsType" :reduce="label => label.value"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.type">
										<p>{{ errors.type[0] }}</p>
									</div>
								</div>
							</div>
							

							<!-- file -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-file">Arquivo</label>
									<div class="custom-file">
										<input type="file" class="custom-file-input" v-bind:class="{'is-invalid': errors.file}" id="file" name="file" accept="*" ref="file" lang="pt" v-on:change="handleFileUpload()">
										<label class="custom-file-label" for="file">{{filefileName}}</label>
									</div>
									<div class="invalid-feedback d-block" v-if="errors.file">
										<p>{{ errors.file[0] }}</p>
									</div>
								</div>
							</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions','items'],
		data() {
			return {
				filefileName: "Selecione o arquivo",
				itemattachmentsTitle: "Novo Anexo",
				itemattachment: {
					type: '',
					file: '',
				},
				isEditItemAttachments: false,
				itemattachmentsID: "",
				itemattachmentsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				optionsType: [{
					label: "Imagem",
					value: 'image'
				},{
					label: "PDF",
					value: 'pdf'
				}],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Tipo',
						field: '_type',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '120px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			getData(key) {
				return JSON.parse(this.items)[key];
			},
			handleFileUpload(){
				this.itemattachment.file = this.$refs.file.files[0];
				this.filefileName = this.$refs.file.files[0].name;
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/itemattachments/"+_this.getData('id')+"/search").then((res) => {
					_this.itemattachmentsItems = _this.itemattachmentsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditItemAttachments == true){

					let formData = new FormData();
					formData.append("type", _this.itemattachment.type);
					formData.append("file", _this.itemattachment.file);
					formData.append("_method", "PATCH");

					axios.post("/admin/itemattachments/"+_this.itemattachmentsID, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalItemAttachments').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let formData = new FormData();
					formData.append("type", _this.itemattachment.type);
					formData.append("file", _this.itemattachment.file);
					formData.append("item_id", JSON.parse(_this.items).id);

					axios.post("/admin/itemattachments", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.itemattachmentsItems.push(res.data.data);
						$('#modalItemAttachments').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Anexo",
					text: "Tem certeza que deseja excluir esse Anexo?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/itemattachments/"+ID).then((res) => {

							_this.itemattachments.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.itemattachment = item;
				this.isEditItemAttachments = true;
				this.itemattachmentsID = item.id;
				this.itemattachmentsTitle = "Editar Anexo";

				$("#modalItemAttachments").modal('show');

			},
			resetForm(){

				this.itemattachment = {
					type: '',
					file: '',
				};
				this.errors = [];
				this.isEditItemAttachments = false;
				this.itemattachmentsID = "";
				this.itemattachmentsTitle = "Novo Anexo";

			},
			viewFile(item){
				window.open('/storage/items/'+item.file,'_blank');
			}
		}
	}
</script>
