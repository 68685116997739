<template>
  <div class="tab-pane fade show active" id="tab-dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
    <div class="row">
      <div class="col-12">
        
        <!-- infos -->
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-4">
                <div class="form-group">
                  <label>Atividade Anterior</label>
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="last_activity" :disabled="true">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button"><i class="far fa-eye"></i></button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>PIN Mensagens</label>
                  <input type="text" v-model="pin_message" :disabled="true" class="form-control"/>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>PIN EPI's</label>
                  <input type="text" v-model="pin_epis" :disabled="true" class="form-control"/>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Iniciado em:</label>
                  <input type="text" v-model="init_datetime" :disabled="true" class="form-control"/>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Concluído em:</label>
                  <input type="text" v-model="done_datetime" :disabled="true" class="form-control"/>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Data da Sincronização</label>
                  <input type="text" v-model="sync_datetime" :disabled="true" class="form-control"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label>Executado por</label>
                  <input type="text" v-model="executed_by" :disabled="true" class="form-control"/>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- end infos -->

        <!-- checklists -->
        <div class="card">
          <div class="card-body">

            <vue-good-table v-if="checklists.length"
              :columns="tableColumns"
              :rows="checklists"
              :pagination-options="tablePagination"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar..',
              }"
              styleClass="vgt-table"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == '_type'">
                  <div v-if="props.row.info">
                    <div v-if="props.row.info.type == 'none'">-</div>
                    <div v-if="props.row.info.type == 'text'">Texto</div>
                    <div v-if="props.row.info.type == 'selection'">Seleção</div>
                    <div v-if="props.row.info.type == 'multiple'">Múltipla Escolha</div>
                  </div>
                </span>
                <span v-if="props.column.field == '_attach'">
                  <div v-if="props.row.info">
                    <p v-if="props.row.info.attach == 'true'">Sim</p>
                    <p v-if="props.row.info.attach == 'false'">Não</p>
                  </div>
                </span>
                <span v-if="props.column.field == '_source'">
                  {{ props.row.type }}
                  <div v-if="props.row.answer">
                    <i v-if="props.row.answer.type == 0" class="far fa-thumbs-up text-success"></i>
                    <i v-if="props.row.answer.type == 1" class="far fa-thumbs-down text-danger"></i>
                  </div>
                </span>
                <span v-if="props.column.field == 'actions'">
                  <div>
                    <button class="btn btn-sm btn-outline-secondary" title="Visualizar" @click="viewData(props.row)"><i class="far fa-eye"></i></button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
            <div v-else class="text-center p-3 text-muted">
              <h5>Nenhum checklist encontrado!</h5>
            </div>

          </div>
        </div>
        <!-- end checklists -->

      </div>

      <!-- modal -->
      <div class="modal fade" id="modalAnswerChecklist" tabindex="-1" aria-labelledby="modalAnswerChecklist" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalAnswerChecklist">Resposta do Checklist</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div clas="row">

                <!-- info -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label" for="input-workplace_id">Informações</label>
                    <textarea class="form-control" v-model="modalAnswer.info" required></textarea>
                  </div>
                </div>

                <!-- file -->
                <div class="col-md-12 mt-2">
                  <label>Imagem</label>
                  <img class="preview-image" :src="modalAnswer.image"/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end modal -->

      <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
    </div><!-- row -->
  </div>
</template>

<script>
	export default {
	props: ["activities"],
		data() {
			return {
        checklists: [],
        fullPage: true,
        done_datetime: this.getData('done_datetime') == null ? '---' : moment(this.getData('done_datetime')).format('DD/MM/YYYY HH:mm:ss'),
        init_datetime: this.getData('init_datetime') == null ? '---' : moment(this.getData('init_datetime')).format('DD/MM/YYYY HH:mm:ss'),
				loading: false,
        modalAnswer: {},
        pin_message: this.getData('pin_message_validated_at') == null ? '---' : moment(this.getData('pin_message_validated_at')).format('DD/MM/YYYY HH:mm:ss'),
        pin_epis: this.getData('pin_epi_validated_at') == null ? '---' : moment(this.getData('pin_epi_validated_at')).format('DD/MM/YYYY HH:mm:ss'),
        sync_datetime: this.getData('sync_datetime') == null ? '---' : moment(this.getData('sync_datetime')).format('DD/MM/YYYY HH:mm:ss'),
        last_activity: this.getData('last_activity') == null ? 'Não Existe' : moment(this.getData('last_activity')).format('DD/MM/YYYY HH:mm:ss'),
        executed_by: this.validateExecuted(),
        tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
          {
            label: '',
            field: '_source'
          },{
						label: 'Nome',
						field: 'info.name',
					},{
						label: 'Tipo',
						field: '_type',
					},{
						label: 'Detalhes',
						field: 'info.details',
					},{
						label: 'O que verificar?',
						field: 'info.what_to_check',
					},{
						label: 'Anexo?',
						field: '_attach',
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '70px'
					}
        ]
			}
		},
		mounted() {
      this.loadChecklists();
		},
		methods: {
			getData(key) {
				return JSON.parse(this.activities)[key];
			},
      loadChecklists(){
        var template = this.getData('templates');

        this.checklists = [];
        this.checklists = template.checklists;
      },
      validateExecuted(){
        var executed = this.getData('executed_info');
        if(executed === null){
          return "---";
        } else {
          return executed.users.profile.name;
        }
      },
      viewData(item){

        if(item.answer){

          this.modalAnswer = {};
          this.modalAnswer.info = item.answer.answer;
          this.modalAnswer.image = "https://mvtecnologia.s3.us-east-1.amazonaws.com/answers/"+item.answer.files[0].file;
          $("#modalAnswerChecklist").modal('show');

        } else {

          this.$swal({
            title: 'Atenção',
            text: 'Checklist sem resposta!',
            icon: 'warning'
          });

        }

      }
    }
	}
</script>
