<template>
	<div>

		<!-- filter -->
		<div class="row">
			<div class="col-md-12">
				<div class="card card-body">
					<div class="row">

						<!-- company id -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Empresa</label>
								<v-select :options="filterCompanies" label="corporate_name" v-bind:class="{'is-invalid': errors.company}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.company_id" @input="validateCompany('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.company" >
									<p>{{ errors.company[0] }}</p>
								</div>
							</div>
						</div>

						<!-- contract -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Contrato</label>
								<v-select :options="filterContracts" label="corporate_name" v-bind:class="{'is-invalid': errors.contract}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.contract_id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.contract" >
									<p>{{ errors.contract[0] }}</p>
								</div>
							</div>
						</div>

						<!-- work_place_id -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
								<v-select :options="filterWorkplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.workplace}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.work_place_id" @input="validateWorkplace('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.workplace" >
									<p>{{ errors.workplace[0] }}</p>
								</div>
							</div>
						</div>

						<!-- sublocal -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-sublocal">Sublocal</label>
								<v-select :options="filterSublocal" label="corporate_name" v-bind:class="{'is-invalid': errors.sublocal}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.sublocal_id" @input="validateSublocal('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.sublocal" >
									<p>{{ errors.sublocal[0] }}</p>
								</div>
							</div>
						</div>

						<!-- item -->
						<div class="col-md-8 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-itemsSelected">Itens</label>
								<v-select :options="filterListItems" label="label" :reduce="label => label.value" v-bind:class="{'is-invalid': errors.itemsSelected}" v-model="filterItems.itemsSelected" multiple></v-select>
								<div class="invalid-feedback d-block" v-if="errors.itemsSelected">
									<p>{{ errors.itemsSelected[0] }}</p>
								</div>
							</div>
						</div>

						<div class="col-md-4 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-template_id">Template</label>
								<v-select :options="filterTemplates" label="name" :reduce="name => name.id" v-bind:class="{'is-invalid': errors.template_id}" v-model="filterItems.template_id" :required="true"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.template_id">
									<p>{{ errors.template_id[0] }}</p>
								</div>
							</div>
						</div>
						
						<!-- sector_id -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-sector_id">Setor</label>
								<v-select :options="filterSectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="filterItems.sector_id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.sector_id">
									<p>{{ errors.sector_id[0] }}</p>
								</div>
							</div>
						</div>
						
						<!-- subsector_id -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-subsector_id">Subsetor</label>
								<v-select :options="filterSubsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="filterItems.subsector_id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.subsector_id">
									<p>{{ errors.subsector_id[0] }}</p>
								</div>
							</div>
						</div>

						<!-- date -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-start_date">Data Inicial</label>
								<input class="form-control" label="Data" type="date" v-model="filterItems.start_date"/>
								<div class="invalid-feedback d-block" v-if="errors.start_date">
									<p>{{ errors.start_date[0] }}</p>
								</div>
							</div>
						</div>
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-end_date">Data Final</label>
								<input class="form-control" label="Data" type="date" v-model="filterItems.end_date"/>
								<div class="invalid-feedback d-block" v-if="errors.end_date">
									<p>{{ errors.end_date[0] }}</p>
								</div>
							</div>
						</div>
						
						<div class="col-md-12 mt-2 text-right">
							<button class="btn btn-success" @click="generateReport()">GERAR RELATÓRIO</button>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- end filter -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	import ExportGrid from '../../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		data() {
			return {
				circuits: [],
				companies: [],
				filterCompanies: [],
				filterListItems: [],
				filterWorkplaces: [],
				filterSublocal: [],
				filterContracts: [],
				filterSectors: [],
				filterSubsectors: [],
				filterTemplates: [],
				modalSectors: [],
				modalSubsectors: [],
				gridSectors: [],
				gridSubsectors: [],
				gridItems: {},
				routes: [],
				workplaces: [],
				colaborators: [],
				filterItems: {
					type: 'last',
					company_id: null,
					contract_id: null,
					work_place_id: null,
					sublocal_id: null,
					itemsSelected: [],
					template_id: null,
					sector_id: null,
					subsector_id: null,
					start_date: null,
					end_date: null
				},
				items: [],
				itemsSelected: [],
				
				sectors: [],
				subsectors: [],
				gridSelected: [],
				iteminventoriesTitle: "Nova Movimentação de Inventário",
				iteminventory: {
					status: '',
					company_id: '',
					contract_id: '',
					work_place_id: '',
					sublocal_id: '',
					colaborator_id: '',
					sector_id: '',
					subsector_id: ''
				},
				isEditItemInventories: false,
				iteminventoriesID: "",
				iteminventoriesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				
				tempColaborators: [],
        tempItems: [],
				tempSectors: [],
				tempSubsectors: [],
				tempWorkplaces: [],
				userCan: ''
			}
		},
		mounted() {
      this.loadItems();
			this.loadCompanies();
			this.loadTemplates();
			//this.loadCircuits();
			//this.loadRoutes();
		},
		methods: {
      generateReport(){
        let _this = this;
				_this.loading = true;
				_this.errors = [];
				_this.iteminventoriesItems = [];

				if(_this.filterItems.template_id == null){

					_this.loading = false;
					_this.$swal({
						title: "Atenção",
						text: "Template é Obrigatório",
						icon: "warning"
					});

				} else {

					let dataFilter = {
						"company" : _this.filterItems.company_id,
						"contract": _this.filterItems.contract_id,
						"workplace" : _this.filterItems.work_place_id,
						"sublocal": _this.filterItems.sublocal_id,
						"items" : _this.filterItems.itemsSelected,
						"template": _this.filterItems.template_id,
						"sector" : _this.filterItems.sector_id,
						"subsector" : _this.filterItems.subsector_id,
						"start_date" : _this.filterItems.start_date,
						"end_date" : _this.filterItems.end_date
					};

					//mount url
					var url = "/admin/reports/activities/excel/full?filters="+JSON.stringify(dataFilter);

					window.open(url,'_blank');

					_this.loading = false;

					_this.$swal({
						title: "Sucesso",
						text: "Relatório gerado com Sucesso",
						icon: "success"
					});

					// axios.post("/admin/reports/activities", dataFilter).then((res) => {
						
					// 	_this.loading = false;

					// 	//call function in ApiService to validate success
					// 	_this._api_validateSuccess(res,false);

					// 	window.open(url,'_blank');

					// }).catch((err) => {
						
					// 	_this.loading = false;

					// 	if (err.response.data.errors){
					// 		//set errors
					//   	_this.errors = err.response.data.errors;
					// 	}

					// 	//call function in ApiService to validate error
					// 	_this._api_validateError(err);

					// });

				}

      },
			loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.filterContracts = [];
				axios.get("/admin/companycontracts/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.filterContracts.push({
							id: rowContract.contract_id,
							corporate_name: rowContract.contracts.corporate_name
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					_this.companies = _this.companies.concat(res.data);
					_this.filterCompanies = _this.filterCompanies.concat(res.data);
				}).catch((err) => {
				});
			},
			loadColaborators() {
				let _this = this;
				_this.colaborators = [];
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+_this.filterItems.company_id+"/search").then((res) => {
					//_this.colaborators = _this.colaborators.concat(res.data);
					res.data.map((rowColaborator) => {
						_this.colaborators.push({
							label: rowColaborator.colaborators.users.profile.name,
							value: rowColaborator.colaborator_id
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      loadItems(){
				let _this = this;
				_this.loading = true;
				_this.items = [];
				_this.tempItems = [];

				axios.get("/admin/items").then((res) => {
					_this.tempItems = _this.tempItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSectors(source) {
				let _this = this;
				_this.loading = true;

				if(source == 'filter'){

					_this.filterSectors = [];
					_this.modalSectors = [];
					_this.gridSectors = [];

					axios.get("/admin/sectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
						_this.filterSectors = _this.filterSectors.concat(res.data);
						_this.modalSectors = _this.modalSectors.concat(res.data);
						_this.gridSectors = _this.gridSectors.concat(res.data);
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
				});

				}

			},
			loadSubsectors(source) {
				let _this = this;
				_this.loading = true;

				if(source == 'filter'){

					_this.filterSubsectors = [];
					_this.modalSubsectors = [];
					_this.gridSubsectors = [];
				
					axios.get("/admin/subsectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
						_this.filterSubsectors = _this.filterSubsectors.concat(res.data);
						_this.modalSubsectors = _this.modalSubsectors.concat(res.data);
						_this.gridSubsectors = _this.gridSubsectors.concat(res.data);
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
					});

				}

			},
			loadTemplates() {
				let _this = this;
				axios.get("/admin/templates").then((res) => {
					_this.filterTemplates = _this.filterTemplates.concat(res.data);
				}).catch((err) => {
				});
			},
			loadCircuits() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/circuits").then((res) => {
					_this.circuits = _this.circuits.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadRoutes() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/routes").then((res) => {
					_this.routes = _this.routes.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			resetForm(){

			},
			selectionChanged(params) {
				// params.selectedRows - all rows that are selected (this page)

				if (this.gridSelected.length == 0){
					$("#panelMove").slideToggle();
				} else {
					if(params.selectedRows.length == 0){
						$("#panelMove").slideToggle();
					}
				}
				this.gridSelected = params.selectedRows;
				
			},
			selectItem(item){
				this.resetForm();
				this.modalMove.itemsSelected = [];
				this.modalMove.itemsSelected.push(item);
			},
			updateGrid(newItems){

				let _this = this;

				var tempItems = [];

				//update list
				_this.iteminventoriesItems.map((rowItem, index) => {

					if(_this.filterItems.type == 'last'){

						//search item to update
						newItems.map((rowNewItem) => {
							if (rowItem.item_id == rowNewItem.item_id){
								_this.iteminventoriesItems.splice(index,1);
								_this.iteminventoriesItems.unshift(rowNewItem);
							}
						});

					}

				});
				//end update list

			},
			validateCompany(source){
				let _this = this;
				_this.loadColaborators();
				_this.loading = true;

				if(source == 'filter'){

					_this.filterWorkplaces = [];
					axios.get("/admin/companyworkplaces/"+_this.filterItems.company_id+'/search').then((res) => {
						res.data.map((rowReturn) => {
							_this.filterWorkplaces = _this.filterWorkplaces.concat(rowReturn.work_places);
						});
						_this.loadContracts();
					}).catch((err) => {
					});
					this.loading = false;

				}
			},
			validateWorkplace(source){
				let _this = this;

				if(source == 'filter'){

					_this.filterSublocal = [];
					axios.get("/admin/workplacesublocal/"+_this.filterItems.work_place_id+"/search").then((res) => {
						res.data.map((rowSublocal) => {
							_this.filterSublocal.push({
								corporate_name: rowSublocal.sublocal.corporate_name,
								id: rowSublocal.sublocal_id
							});
						});
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
					});

				}

			},
			validateSublocal(source){
				let _this = this;

				_this.loadSectors(source);
				_this.loadSubsectors(source);

				_this.filterListItems = [];
				_this.tempItems.map((rowItem) =>{
					if(rowItem.inventory !== null && rowItem.inventory !== undefined){
						if(rowItem.inventory.work_place_id == _this.filterItems.sublocal_id){
							_this.filterListItems.push({
								label: rowItem.name,
								value: rowItem.id
							});
						}
					}
				});

			}
		}
	}
</script>
