<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Permitir Atrasos cadastrados</p>
				<a href="#" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalAllowDelays"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="allowdelaysItems.length"
						:columns="tableColumns"
						:rows="allowdelaysItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_type'">
								<p v-if="props.row.type == 'day'">Dia</p>
								<p v-if="props.row.type == 'month'">Mês</p>
								<p v-if="props.row.type == 'year'">Ano</p>
								<p v-if="props.row.type == 'scale'">Escala</p>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum permitir atraso cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalAllowDelays" tabindex="-1" aria-labelledby="modalAllowDelaysLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalAllowDelaysLabel">{{allowdelaysTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="allowdelay.name" required="true" >
											<div class="invalid-feedback d-block" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- type -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-type">Tipo</label>
											<v-select v-model="allowdelay.type" :options="optionsType"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.type">
												<p>{{ errors.type[0] }}</p>
											</div>
										</div>
									</div>

									<!-- time -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-time">Tempo</label>
											<input type="tel" id="input-time" v-bind:class="{'is-invalid': errors.time}"   class="form-control" placeholder="Tempo" v-model="allowdelay.time" required="true" >
											<div class="invalid-feedback d-block" v-if="errors.time">
												<p>{{ errors.time[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				allowdelaysTitle: "Novo Permitir Atraso",
				allowdelay: {
					name: '',
					time: '',
					type: '',
				},
				isEditAllowDelays: false,
				allowdelaysID: "",
				allowdelaysItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				optionsType: [{
					label: 'Dia',
					value: 'day'
				},{
					label: 'Mês',
					value: 'month'
				},{
					label: 'Ano',
					value: 'year'
				},{
					label: 'Escala',
					value: 'scale'
				}],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'name',
					},
					{
						label: 'Tipo',
						field: '_type',
					},
					{
						label: 'Tempo',
						field: 'time',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/allowdelays").then((res) => {
					_this.allowdelaysItems = _this.allowdelaysItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditAllowDelays == true){

					let dataInfo = {
						"name" : _this.allowdelay.name,
						"time" : _this.allowdelay.time,
						"type" : _this.allowdelay.type.value,
						"_method": "PATCH"
					};
					axios.post("/admin/allowdelays/"+_this.allowdelaysID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalAllowDelays').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.allowdelay.name,
						"time" : _this.allowdelay.time,
						"type" : _this.allowdelay.type.value,
						"last": ""
					};
					axios.post("/admin/allowdelays", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.allowdelaysItems.push(res.data.data);
						$('#modalAllowDelays').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Permitir Atraso",
					text: "Tem certeza que deseja excluir essa Permitir Atraso?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/allowdelays/"+ID).then((res) => {

							//_this.allowdelays.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.allowdelay = item;
				this.isEditAllowDelays = true;
				this.allowdelaysID = item.id;
				this.allowdelaysTitle = "Editar Permitir Atraso";

				$("#modalAllowDelays").modal('show');

			},
			resetForm(){

				this.allowdelay = {
					name: '',
					time: '',
					type: '',
				};

			}
		}
	}
</script>
