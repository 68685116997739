<template>
  <div class="row">
    <div class="col">

      <!-- table -->
      <div class="card p-4">
        <vue-good-table v-if="logs.length"
          :columns="tableColumns"
          :rows="logs"
          :pagination-options="tablePagination"
          :search-options="{
            enabled: true,
            placeholder: 'Buscar..',
          }"
          styleClass="vgt-table"
			  >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'total'">
              {{ props.row.activities.length }} atividades
            </span>
            <span v-if="props.column.field == 'actions'">
              <div>
                <button class="btn btn-sm btn-outline-secondary" title="Visualizar Atividades" @click="viewLogActivities(props.row)"><i class="far fa-eye"></i> Visualizar Atividades</button>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
			  </vue-good-table>
        <div v-else class="text-center p-3 text-muted">
            <h5>Nenhum log encontrado!</h5>
        </div>
      </div>
      <!-- end table -->

      <div class="text-center p-4 text-muted" v-if="loading && logs.length == 0">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>

      <!-- modal -->
			<div class="modal fade" id="modalActivities" tabindex="-1" aria-labelledby="modalActivitiesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalActivitiesLabel">Atividades Geradas</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<vue-good-table v-if="generatedActivities.length"
                :columns="tableColumnsActivities"
                :rows="generatedActivities"
                :pagination-options="tablePagination"
                :search-options="{
                  enabled: true,
                  placeholder: 'Buscar..',
                }"
                styleClass="vgt-table"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <div>
                      <a class="btn btn-sm btn-outline-secondary" title="Ver Atividade" :href="'/admin/activities/'+props.row.activity_id" target="_blank"><i class="far fa-eye"></i> Ver Atividade</a>
                    </div>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
              <div v-else class="text-center p-3 text-muted">
                  <h5>Nenhuma atividade gerada!</h5>
              </div>
						</div>
					</div>
				</div>
			</div>

    </div><!-- col -->
  </div><!-- row -->
</template>

<script>
  export default {
    data() {
      return {
        logs: [],
        loading: false,
        generatedActivities: [],
        tablePagination: {
          enabled: true,
          nextLabel: 'próxima',
          prevLabel: 'anterior',
          rowsPerPageLabel: 'Registros por página',
          ofLabel: 'de',
          pageLabel: 'página', // for 'pages' mode
          allLabel: 'Todos',
        },
        tableColumns: [
          {
            label: 'Data e Hora',
            field: 'created_at',
            type: 'date',
            dateInputFormat: "yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSXXX",
            dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
          },{
            label: "Total de Atividades",
            field: "total"
          },{
            label: "Ações",
            field: "actions",
            width: "220px"
          }
        ],
        tableColumnsActivities: [
          {
            label: 'ID',
            field: 'activity_id',
            width: "100px"
          },{
            label: "Título",
            field: "info.title"
          },{
            label: "Ações",
            field: "actions",
            width: "140px"
          }
        ]
      }
    },
    mounted() {
      this.loadLogs();
    },
    methods: {
      loadLogs() {
        let _this = this;
        _this.loading = true;
        axios.get('/admin/log/generateactivity').then((res) => {
          _this.logs = _this.logs.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      },
      viewLogActivities(log){
        this.generatedActivities = [];
        this.generatedActivities = log.activities;
        $("#modalActivities").modal('show');
      }
    }
  }
</script>
