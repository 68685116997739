<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- name -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-name">Nome</label>
									<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="name" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.name">
										<p>{{ errors.name[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end name -->

							<!-- source -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-source">Método</label>
									<v-select v-bind:class="{'is-invalid': errors.source}" :options="optionsSource" v-model="source" :reduce="label => label.value"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.source">
										<p>{{ errors.source[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end source -->

							<!-- subject -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-subject">Assunto</label>
									<input type="text" id="input-subject" v-bind:class="{'is-invalid': errors.subject}"   class="form-control" placeholder="Assunto" v-model="subject" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.subject">
										<p>{{ errors.subject[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end subject -->

							<!-- content -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-content">Conteúdo</label>
									<textarea type="text" id="input-content" v-bind:class="{'is-invalid': errors.content}" class="form-control" placeholder="Conteúdo" v-model="content" rows="8" required="false"></textarea>
									<div class="invalid-feedback d-block" v-if="errors.content">
										<p>{{ errors.content[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end content -->

							<!-- to -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-to">Para</label>
									<input type="text" id="input-to" v-bind:class="{'is-invalid': errors.to}"   class="form-control" placeholder="Para" v-model="to" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.to">
										<p>{{ errors.to[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end to -->

							<!-- cc -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-cc">Cópia</label>
									<input type="text" id="input-cc" v-bind:class="{'is-invalid': errors.cc}"   class="form-control" placeholder="Cópia" v-model="cc" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.cc">
										<p>{{ errors.cc[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end cc -->

							<!-- cco -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-cco">Cópia Oculta</label>
									<input type="text" id="input-cco" v-bind:class="{'is-invalid': errors.cco}"   class="form-control" placeholder="Cópia Oculta" v-model="cco" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.cco">
										<p>{{ errors.cco[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end cco -->

							<!-- type -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-type">Tipo</label>
									<v-select v-bind:class="{'is-invalid': errors.type}" :options="optionsType" v-model="type" :reduce="label => label.value"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.type">
										<p>{{ errors.type[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end type -->

							<!-- time -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-time">Tempo</label>
									<input type="tel" id="input-time" v-bind:class="{'is-invalid': errors.time}"   class="form-control" placeholder="Tempo" v-model="time" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.time">
										<p>{{ errors.time[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end time -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ["configurationnotifications"],
		data() {
			return {
				name: this.getData("name"),
				type: this.getData("type"),
				subject: this.getData("subject"),
				content: this.getData("content"),
				to: this.getData("to"),
				cc: this.getData("cc"),
				cco: this.getData("cco"),
				source: this.getData("source"),
				time: this.getData("time"),
				errors: [],
				message: "",
				loading: false,
				optionsType: [{
					label: 'Dia',
					value: 'day'
				},{
					label: 'Mês',
					value: 'month'
				},{
					label: 'Ano',
					value: 'year'
				},{
					label: 'Escala',
					value: 'scale'
				}],
				optionsSource: [{
					label: 'E-mail',
					value: 'email'
				}],
				fullPage: true
			}
		},
		mounted() {
		},
		methods: {
			getData(key) {
				return JSON.parse(this.configurationnotifications)[key];
			},
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"name" : _this.name,
					"type" : _this.type,
					"subject" : _this.subject,
					"content" : _this.content,
					"to" : _this.to,
					"cc" : _this.cc,
					"cco" : _this.cco,
					"source" : _this.source,
					"time" : _this.time,
					"_method": "PATCH"
				};
				axios.post("/admin/configurationnotifications/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,false);
					
				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			}
		}
	}
</script>
