<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Periodicidades cadastradas</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalFrequencies"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<div class="dropdown float-right">
					<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-download"></i>
						Exportar
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#" @click="exportData('pdf')">PDF</a>
						<a class="dropdown-item" href="#" @click="exportData('csv')">Excel</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="#" @click="$refs.gridfullexport.showModal('frequencies','frequencies')">Completo</a>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="frequenciesItems.length"
						:columns="tableColumns"
						:rows="frequenciesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_type'">
								<p v-if="props.row.type == 'day'">Dia</p>
								<p v-if="props.row.type == 'month'">Mês</p>
								<p v-if="props.row.type == 'year'">Ano</p>
								<p v-if="props.row.type == 'scale'">Escala</p>
								<p v-if="props.row.type == 'unic'">Único</p>
								<p v-if="props.row.type == 'specific'">Específico</p>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma periodicidades cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalFrequencies" tabindex="-1" aria-labelledby="modalFrequenciesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalFrequenciesLabel">{{frequencyTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" maxlength="60" class="form-control" placeholder="Nome" v-model="frequency.name" required="true" />
											<div class="invalid-feedback d-block" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>

									<!-- type -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-type">Tipo</label>
											<v-select v-model="frequency.type" v-bind:class="{'is-invalid': errors.type}" @input="validateType()" :reduce="label => label.value" :options="optionsType"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.type">
												<p>{{ errors.type[0] }}</p>
											</div>
										</div>
									</div>

									<!-- time -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-time">Tempo</label>
											<input type="tel" id="input-time" v-bind:class="{'is-invalid': errors.time}" :disabled="disabledTime" class="form-control" placeholder="Tempo" v-model="frequency.time" required="true" />
											<div class="invalid-feedback d-block" v-if="errors.time">
												<p>{{ errors.time[0] }}</p>
											</div>
										</div>
									</div>

									<!-- days of week -->
									<div class="col-md-12" v-if="frequency.type == 'specific'">
										<div class="form-group">
											<hr/>
											<label>Selecione os dias da semana</label>
											<div class="row">
												<div class="col-md-3">
													<label>Domingo</label>
													<select class="form-control" id="sunday" v-model="frequency.days_of_week.sunday">
														<option value="0">Não</option>
														<option value="1">Sim</option>
													</select>
												</div>
												<div class="col-md-3">
													<label>Segunda</label>
													<select class="form-control" id="monday" v-model="frequency.days_of_week.monday">
														<option value="0">Não</option>
														<option value="1">Sim</option>
													</select>
												</div>
												<div class="col-md-3">
													<label>Terça</label>
													<select class="form-control" id="tuesday" v-model="frequency.days_of_week.tuesday">
														<option value="0">Não</option>
														<option value="1">Sim</option>
													</select>
												</div>
												<div class="col-md-3">
													<label>Quarta</label>
													<select class="form-control" id="wednesday" v-model="frequency.days_of_week.wednesday">
														<option value="0">Não</option>
														<option value="1">Sim</option>
													</select>
												</div>
												<div class="col-md-3">
													<label>Quinta</label>
													<select class="form-control" id="thursday" v-model="frequency.days_of_week.thursday">
														<option value="0">Não</option>
														<option value="1">Sim</option>
													</select>
												</div>
												<div class="col-md-3">
													<label>Sexta</label>
													<select class="form-control" id="friday" v-model="frequency.days_of_week.friday">
														<option value="0">Não</option>
														<option value="1">Sim</option>
													</select>
												</div>
												<div class="col-md-3">
													<label>Sábado</label>
													<select class="form-control" id="saturday" v-model="frequency.days_of_week.saturday">
														<option value="0">Não</option>
														<option value="1">Sim</option>
													</select>
												</div>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<gridfullexport-component ref="gridfullexport"></gridfullexport-component>
			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService,ExportGrid],
		props: ['permissions'],
		data() {
			return {
				disabledTime: false,
				frequency: {
					name: '',
					time: '',
					type: '',
					days_of_week: {
						sunday: '',
						monday: '',
						tuesday: '',
						wednesday: '',
						thursday: '',
						friday: '',
						saturday: ''
					}
				},
				frequenciesItems: [],
				fullPage: true,
				errors: [],
				isEditFrequency: false,
				frequencyID: '',
				frequencyTitle: 'Nova Periodicidade',
				loading: false,
				optionsType: [{
					label: 'Dia',
					value: 'day'
				},{
					label: 'Mês',
					value: 'month'
				},{
					label: 'Ano',
					value: 'year'
				},{
					label: 'Escala',
					value: 'scale'
				},{
					label: 'Único',
					value: 'single'
				},{
					label: 'Específico',
					value: 'specific'
				}],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Tipo',
						field: '_type',
					},{
						label: 'Tempo',
						field: 'time',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/frequencies").then((res) => {
					_this.frequenciesItems = _this.frequenciesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			exportData(type){
				var tempForm = $('#vgt-table');
				if(type == 'pdf'){
					this._exportPDF('Periodicidade', [5], tempForm, ['ID','Nome','Tempo','Tipo','Data e Hora']);
				} else {
					this._exportExcel('Periodicidade', [5], tempForm, ['ID','Nome','Tempo','Tipo','Data e Hora']);
				}
    	},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditFrequency == true){

					let dataInfo = {
						"name" : _this.frequency.name,
						"time" : _this.frequency.time,
						"type" : _this.frequency.type,
						"days_of_week": _this.frequency.days_of_week.sunday+","+_this.frequency.days_of_week.monday+","+_this.frequency.days_of_week.tuesday+","+_this.frequency.days_of_week.wednesday+","+_this.frequency.days_of_week.thursday+","+_this.frequency.days_of_week.friday+","+_this.frequency.days_of_week.saturday,
						"_method": "PATCH"
					};
					axios.post("/admin/frequencies/"+_this.frequencyID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalFrequencies').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
						_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.frequency.name,
						"time" : _this.frequency.time,
						"type" : _this.frequency.type,
						"days_of_week": _this.frequency.days_of_week.sunday+","+_this.frequency.days_of_week.monday+","+_this.frequency.days_of_week.tuesday+","+_this.frequency.days_of_week.wednesday+","+_this.frequency.days_of_week.thursday+","+_this.frequency.days_of_week.friday+","+_this.frequency.days_of_week.saturday,
						"last": ""
					};
					axios.post("/admin/frequencies", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.frequenciesItems.push(res.data.data);
						$('#modalFrequencies').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
						_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				}
				
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Periodicidade",
					text: "Tem certeza que deseja excluir essa Periodicidade?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/frequencies/"+ID).then((res) => {

							//_this.frequencies.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.frequency.name = item.name;
				this.frequency.time = item.time;
				this.frequency.type = item.type;

				var tempDaysOfWeek = item.days_of_week.split(',');

				this.frequency.days_of_week.sunday = tempDaysOfWeek[0];
				this.frequency.days_of_week.monday = tempDaysOfWeek[1];
				this.frequency.days_of_week.tuesday = tempDaysOfWeek[2];
				this.frequency.days_of_week.wednesday = tempDaysOfWeek[3];
				this.frequency.days_of_week.thursday = tempDaysOfWeek[4];
				this.frequency.days_of_week.friday = tempDaysOfWeek[5];
				this.frequency.days_of_week.saturday = tempDaysOfWeek[6];

				this.validateType();

				this.isEditFrequency = true;
				this.frequencyID = item.id;
				this.frequencyTitle = "Editar Periodicidade";

				$("#modalFrequencies").modal('show');

			},
			resetForm(){

				this.frequency = {
					name: '',
					time: '',
					type: '',
					days_of_week: {
						sunday: '1',
						monday: '1',
						tuesday: '1',
						wednesday: '1',
						thursday: '1',
						friday: '1',
						saturday: '1'
					}
				};
				this.errors = [];

				this.isEditFrequency = false;
				this.frequencyID = "";
				this.frequencyTitle = 'Nova Periodicidade';

			},
			validateType(){

				if (this.frequency.type == 'single'){
					this.frequency.time = 1;
					this.disabledTime = true;
				} else if(this.frequency.type == 'specific'){
					this.disabledTime = true;
					this.frequency.time = 0;
				}
			}
		}
	}
</script>
