<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Movimentações de Inventário cadastradas</p>
				<!-- <a href="#" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalItemInventories"><i class="fas fa-plus"></i>&nbsp;Novo</a> -->
				<div class="dropdown float-right">
					<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-download"></i>
						Exportar
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#" @click="exportData('pdf')">PDF</a>
						<a class="dropdown-item" href="#" @click="exportData('csv')">Excel</a>
					</div>
				</div>
			</div>
		</div>

		<!-- filter -->
		<div class="row">
			<div class="col-md-12">
				<div class="card card-body">
					<div class="row">

						<!-- company id -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Empresa</label>
								<v-select :options="filterCompanies" label="corporate_name" v-bind:class="{'is-invalid': errors.company}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.company_id" @input="validateCompany('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.company" >
									<p>{{ errors.company[0] }}</p>
								</div>
							</div>
						</div>

						<!-- contract -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Contrato</label>
								<v-select :options="filterContracts" label="corporate_name" v-bind:class="{'is-invalid': errors.contract}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.contract_id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.contract" >
									<p>{{ errors.contract[0] }}</p>
								</div>
							</div>
						</div>

						<!-- work_place_id -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
								<v-select :options="filterWorkplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.workplace}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.work_place_id" @input="validateWorkplace('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.workplace" >
									<p>{{ errors.workplace[0] }}</p>
								</div>
							</div>
						</div>

						<!-- sublocal -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-sublocal">Sublocal</label>
								<v-select :options="filterSublocal" label="corporate_name" v-bind:class="{'is-invalid': errors.sublocal}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.sublocal_id" @input="validateSublocal('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.sublocal" >
									<p>{{ errors.sublocal[0] }}</p>
								</div>
							</div>
						</div>

						<!-- movement id -->
						<div class="col-md-2 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Nº da Movimentação</label>
								<input type="number" class="form-control" label="movement_id" v-bind:class="{'is-invalid': errors.movement_id}" v-model="filterItems.movement_id"/>
								<div class="invalid-feedback d-block" v-if="errors.movement_id" >
									<p>{{ errors.movement_id[0] }}</p>
								</div>
							</div>
						</div>

						<!-- item -->
						<div class="col-md-7 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-itemsSelected">Itens</label>
								<v-select :options="filterListItems" label="name" :reduce="name => name.item_id" v-bind:class="{'is-invalid': errors.itemsSelected}" v-model="filterItems.itemsSelected" multiple></v-select>
								<div class="invalid-feedback d-block" v-if="errors.itemsSelected">
									<p>{{ errors.itemsSelected[0] }}</p>
								</div>
							</div>
						</div>

						<!-- type -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-type">Tipo</label>
								<v-select :options="optionsType" label="label" v-bind:class="{'is-invalid': errors.type}" :reduce="label => label.value" v-model="filterItems.type"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.type">
									<p>{{ errors.type[0] }}</p>
								</div>
							</div>
						</div>
						
						<!-- sector_id -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-sector_id">Setor</label>
								<v-select :options="filterSectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="filterItems.sector_id" @input="loadItems()"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.sector_id">
									<p>{{ errors.sector_id[0] }}</p>
								</div>
							</div>
						</div>
						
						<!-- subsector_id -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-subsector_id">Subsetor</label>
								<v-select :options="filterSubsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="filterItems.subsector_id" @input="loadItems()"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.subsector_id">
									<p>{{ errors.subsector_id[0] }}</p>
								</div>
							</div>
						</div>

						<!-- date -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-start_date">Data Inicial</label>
								<input class="form-control" label="Data" type="date" v-model="filterItems.start_date"/>
								<div class="invalid-feedback d-block" v-if="errors.start_date">
									<p>{{ errors.start_date[0] }}</p>
								</div>
							</div>
						</div>
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-end_date">Data Final</label>
								<input class="form-control" label="Data" type="date" v-model="filterItems.end_date"/>
								<div class="invalid-feedback d-block" v-if="errors.end_date">
									<p>{{ errors.end_date[0] }}</p>
								</div>
							</div>
						</div>
						
						<div class="col-md-12 mt-2 text-right">
							<a class="btn btn-outline-secondary" style="margin-right: 20px;" @click="clearFilter()">LIMPAR</a>
							<button class="btn btn-success" @click="loadInventories()">FILTRAR</button>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- end filter -->

		<div class="row">
			<div class="col">

				<!-- move by grid -->
				<div class="collapse" id="panelMove">
					<div class="card">
						<div class="card-body">

							<h5>Movimentação de vários itens, selecione os campos abaixo para concluir a movimentação!</h5>

							<div class="row">

								<!-- sector_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-sector_id">Setor</label>
										<v-select :options="gridSectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="gridItems.sector_id"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.sector_id">
											<p>{{ errors.sector_id[0] }}</p>
										</div>
									</div>
								</div>
								
								<!-- subsector_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-subsector_id">Subsetor</label>
										<v-select :options="gridSubsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="gridItems.subsector_id"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.subsector_id">
											<p>{{ errors.subsector_id[0] }}</p>
										</div>
									</div>
								</div>

								<!-- status -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-status">Status</label>
										<v-select :options="optionsStatus" label="label" v-bind:class="{'is-invalid': errors.status}" :reduce="label => label.value" v-model="gridItems.status"></v-select>
										<!-- <input type="text" id="input-status" v-bind:class="{'is-invalid': errors.status}"   class="form-control" placeholder="Status" v-model="iteminventory.status" required="false" > -->
										<div class="invalid-feedback d-block" v-if="errors.status">
											<p>{{ errors.status[0] }}</p>
										</div>
									</div>
								</div>

								<div class="col-md-12 text-right">
									<button class="btn btn-success" @click="moveGrid()">CONCLUIR</button>
								</div>

							</div>

						</div>
					</div>
				</div>
				<!-- end move by grid -->

				<!-- table -->
				<div class="card p-4" v-if="iteminventoriesItems.length > 0">
					<vue-good-table v-if="iteminventoriesItems.length"
						:columns="tableColumns"
						:rows="iteminventoriesItems"
						:pagination-options="tablePagination"
						:select-options="{ enabled: true }"
						@on-selected-rows-change="selectionChanged"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_status'">
								<div v-if="props.row.last_info !== null">
									<div class="text-warning" v-if="props.row.last_info.status == '0'">Inativo</div>
									<div class="text-success" v-if="props.row.last_info.status == '1'">Ativo</div>
									<div class="text-danger" v-if="props.row.last_info.status == '2'">Pausado</div>
								</div>
							</span>
							<span v-if="props.column.field == '_newStatus'">
								<div class="text-warning" v-if="props.row.status == '0'">Inativo</div>
								<div class="text-success" v-if="props.row.status == '1'">Ativo</div>
								<div class="text-danger" v-if="props.row.status == '2'">Pausado</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" title="Movimentar Item" data-toggle="modal" data-target="#modalItemMove" @click="selectItem(props.row)" href="#"><i class="fas fa-exchange-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma movimentação de inventário cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalItemInventories" tabindex="-1" aria-labelledby="modalItemInventoriesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalItemInventoriesLabel">{{iteminventoriesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- company id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-work_place_id">Empresa</label>
											<v-select :options="companies" label="corporate_name" v-bind:class="{'is-invalid': errors.company_id}" :reduce="corporate_name => corporate_name.id" v-model="iteminventory.company_id" @input="validateCompany()"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.company_id" >
												<p>{{ errors.company_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- work_place_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
											<v-select :options="workplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.work_place_id}" :reduce="corporate_name => corporate_name.id" v-model="iteminventory.work_place_id" @input="validateWorkplace()"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.work_place_id" >
												<p>{{ errors.work_place_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- item -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-itemsSelected">Itens</label>
											<v-select :options="items" label="label" :reduce="label => label.value" v-bind:class="{'is-invalid': errors.itemsSelected}" v-model="itemsSelected" multiple></v-select>
											<div class="invalid-feedback d-block" v-if="errors.itemsSelected">
												<p>{{ errors.itemsSelected[0] }}</p>
											</div>
										</div>
									</div>

									<!-- colaborator_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-colaborator_id">Colaborador</label>
											<v-select :options="colaborators" label="label" :reduce="label => label.value" v-bind:class="{'is-invalid': errors.colaborator_id}" v-model="iteminventory.colaborator_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.colaborator_id">
												<p>{{ errors.colaborator_id[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- sector_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-sector_id">Setor</label>
											<v-select :options="sectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="iteminventory.sector_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.sector_id">
												<p>{{ errors.sector_id[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- subsector_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-subsector_id">Subsetor</label>
											<v-select :options="subsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="iteminventory.subsector_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.subsector_id">
												<p>{{ errors.subsector_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- status -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-status">Status</label>
											<v-select :options="optionsStatus" label="label" v-bind:class="{'is-invalid': errors.status}" :reduce="label => label.value" v-model="iteminventory.status"></v-select>
											<!-- <input type="text" id="input-status" v-bind:class="{'is-invalid': errors.status}"   class="form-control" placeholder="Status" v-model="iteminventory.status" required="false" > -->
											<div class="invalid-feedback d-block" v-if="errors.status">
												<p>{{ errors.status[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<!-- modal -->
			<div class="modal fade" id="modalItemMove" tabindex="-1" aria-labelledby="modalItemMoveLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalItemMoveLabel">Movimentação de Item</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- sector_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-sector_id">Setor</label>
											<v-select :options="modalSectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="modalMove.sector_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.sector_id">
												<p>{{ errors.sector_id[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- subsector_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-subsector_id">Subsetor</label>
											<v-select :options="modalSubsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="modalMove.subsector_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.subsector_id">
												<p>{{ errors.subsector_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- colaborator_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-colaborator_id">Colaborador</label>
											<v-select :options="colaborators" label="label" :reduce="label => label.value" v-bind:class="{'is-invalid': errors.colaborator_id}" v-model="modalMove.colaborator_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.colaborator_id">
												<p>{{ errors.colaborator_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- status -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-status">Status</label>
											<v-select :options="optionsStatus" label="label" v-bind:class="{'is-invalid': errors.status}" :reduce="label => label.value" v-model="modalMove.status"></v-select>
											<!-- <input type="text" id="input-status" v-bind:class="{'is-invalid': errors.status}"   class="form-control" placeholder="Status" v-model="iteminventory.status" required="false" > -->
											<div class="invalid-feedback d-block" v-if="errors.status">
												<p>{{ errors.status[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		data() {
			return {
				companies: [],
				filterCompanies: [],
				filterListItems: [],
				filterWorkplaces: [],
				filterSublocal: [],
				filterContracts: [],
				filterSectors: [],
				filterSubsectors: [],
				modalSectors: [],
				modalSubsectors: [],
				gridSectors: [],
				gridSubsectors: [],
				gridItems: {},
				workplaces: [],
				colaborators: [],
				filterItems: {
					type: 'last'
				},
				items: [],
				itemsSelected: [],
				tempItems: [],
				sectors: [],
				subsectors: [],
				gridSelected: [],
				iteminventoriesTitle: "Nova Movimentação de Inventário",
				iteminventory: {
					status: '',
					company_id: '',
					contract_id: '',
					work_place_id: '',
					sublocal_id: '',
					colaborator_id: '',
					sector_id: '',
					subsector_id: ''
				},
				isEditItemInventories: false,
				iteminventoriesID: "",
				iteminventoriesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				modalMove: {
					itemsSelected: []
				},
				optionsStatus: [{
					label: 'Ativo',
					value: 1
				},{
					label: 'Pausado',
					value: 2
				}],
				optionsType: [{
					label: 'Última Movimentação',
					value: 'last'
				},{
					label: 'Todas Movimentações',
					value: 'all'
				}],
				tempColaborators: [],
				tempSectors: [],
				tempSubsectors: [],
				tempWorkplaces: [],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'movement_id',
					},{
						label: 'Nome do Item',
						field: 'item.name',
					},{
						label: 'Setor de Origem',
						field: 'last_info.sectors.name',
					},{
						label: 'Subsetor de Origem',
						field: 'last_info.subsectors.name',
					},{
						label: 'Status de Origem',
						field: '_status',
					},{
						label: 'Setor de Destino',
						field: 'sectors.name',
					},{
						label: 'Subsetor de Destino',
						field: 'subsectors.name',
					},{
						label: 'Status de Destino',
						field: '_newStatus',
					},{
						label: 'Data da Movimentação',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: '',
						field: 'actions',
						sortable: false,
						width: '40px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.loadCompanies();
		},
		methods: {
			loadItems(){
				let _this = this;
				_this.loading = true;
				_this.items = [];
				_this.tempItems = [];
				_this.filterListItems = [];

				let dataFilter = {
					"company_id" : _this.filterItems.company_id,
					"contract_id" : _this.filterItems.contract_id,
					"work_place_id" : _this.filterItems.work_place_id,
					"sublocal_id" : _this.filterItems.sublocal_id,
					"sector_id" : _this.filterItems.sector_id,
					"subsector_id" : _this.filterItems.subsector_id
				};

				axios.post("/admin/items/select", dataFilter).then((res) => {
					_this.tempItems = _this.tempItems.concat(res.data.data);
					_this.filterListItems = _this.filterListItems.concat(res.data.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.filterContracts = [];
				axios.get("/admin/companycontracts/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.filterContracts.push({
							id: rowContract.contract_id,
							corporate_name: rowContract.contracts.corporate_name
						});
					});
					_this.filterContracts.sort(function(a, b){
						return a.corporate_name - b.corporate_name;
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadInventories() {
				let _this = this;
				_this.loading = true;
				_this.errors = [];
				_this.iteminventoriesItems = [];

				let dataFilter = {
					"company" : _this.filterItems.company_id,
					"contract" : _this.filterItems.contract_id,
					"workplace" : _this.filterItems.work_place_id,
					"sublocal": _this.filterItems.sublocal_id,
					"items" : _this.filterItems.itemsSelected,
					"sector" : _this.filterItems.sector_id,
					"subsector" : _this.filterItems.subsector_id,
					"start_date" : _this.filterItems.start_date,
					"end_date" : _this.filterItems.end_date,
					"movement_id": _this.filterItems.movement_id,
					"type" : _this.filterItems.type
				};

				axios.post("/admin/inventories/search", dataFilter).then((res) => {
					//_this.tempItems = _this.tempItems.concat(res.data);
					_this.iteminventoriesItems = _this.iteminventoriesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					
					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			clearFilter(){

				this.filterItems = {
					company_id: null,
					work_place_id: null,
					sublocal_id: null,
					itemsSelected: null,
					sector_id: null,
					subsector_id: null,
					start_date: null,
					end_date: null,
					movement_id: null
				};

				this.iteminventoriesItems = [];
			},
			exportData(type){
				var tempForm = $('#vgt-table');
				if(type == 'pdf'){
					this._exportPDF('Romaneio', [0,10], tempForm, ['','ID','Nome do Item','Setor de Origem','Subsetor de Origem','Status de Origem','Setor de Destino','Subsetor de Destino','Status de Destino','Data da Movimentação']);
				} else {
					this._exportExcel('Romaneio', [0,10], tempForm, ['','ID','Nome do Item','Setor de Origem','Subsetor de Origem','Status de Origem','Setor de Destino','Subsetor de Destino','Status de Destino','Data da Movimentação']);
				}
    	},
			moveGrid(){
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				let dataInfo = {
					"status" : _this.gridItems.status,
					"contract_id": _this.filterItems.contract_id,
					"work_place_id" : _this.filterItems.work_place_id,
					"sublocal_id": _this.filterItems.sublocal_id,
					"colaborator_id" : _this.modalMove.colaborator_id,
					"sector_id" : _this.gridItems.sector_id,
					"subsector_id" : _this.gridItems.subsector_id,
					"items": _this.gridSelected
				};
				axios.post("/admin/inventories", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			saveData(){
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				let dataInfo = {
					"status" : _this.modalMove.status,
					"contract_id": _this.filterItems.contract_id,
					"work_place_id" : _this.filterItems.work_place_id,
					"sublocal_id": _this.filterItems.sublocal_id,
					"colaborator_id" : _this.modalMove.colaborator_id,
					"sector_id" : _this.modalMove.sector_id,
					"subsector_id" : _this.modalMove.subsector_id,
					"items": _this.modalMove.itemsSelected
				};
				axios.post("/admin/inventories", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();

					_this.updateGrid(res.data.data);

					$('#modalItemMove').modal('hide');

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,false);

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					res.data.sort(function(a, b){
						return a.corporate_name - b.corporate_name;
					});
					_this.companies = _this.companies.concat(res.data);
					_this.filterCompanies = _this.filterCompanies.concat(res.data);
				}).catch((err) => {
				});
			},
			loadColaborators() {
				let _this = this;
				_this.colaborators = [];
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((rowColaborator) => {
						_this.colaborators.push({
							label: rowColaborator.colaborators.users.profile.name,
							value: rowColaborator.colaborator_id
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSectors(source) {
				let _this = this;
				_this.loading = true;

				if(source == 'filter'){

					_this.filterSectors = [];
					_this.modalSectors = [];
					_this.gridSectors = [];

					axios.get("/admin/sectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
						res.data.sort(function(a, b){
							return a.name - b.name;
						});
						_this.filterSectors = _this.filterSectors.concat(res.data);
						_this.modalSectors = _this.modalSectors.concat(res.data);
						_this.gridSectors = _this.gridSectors.concat(res.data);
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
				});

				}

			},
			loadSubsectors(source) {
				let _this = this;
				_this.loading = true;

				if(source == 'filter'){

					_this.filterSubsectors = [];
					_this.modalSubsectors = [];
					_this.gridSubsectors = [];
				
					axios.get("/admin/subsectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
						res.data.sort(function(a, b){
							return a.name - b.name;
						});
						_this.filterSubsectors = _this.filterSubsectors.concat(res.data);
						_this.modalSubsectors = _this.modalSubsectors.concat(res.data);
						_this.gridSubsectors = _this.gridSubsectors.concat(res.data);
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
					});

				}

			},
			resetForm(){
				this.iteminventory = {
					status: '',
					work_place_id: '',
					colaborator_id: '',
					sector_id: '',
					subsector_id: '',
					company_id: ''
				};
				this.modalMove = {
					sector_id: '',
					subsector_id: '',
					colaborator_id: '',
					itemsSelected: [],
					status: ''
				};
			},
			selectionChanged(params) {
				// params.selectedRows - all rows that are selected (this page)

				if (this.gridSelected.length == 0){
					$("#panelMove").slideToggle();
				} else {
					if(params.selectedRows.length == 0){
						$("#panelMove").slideToggle();
					}
				}
				this.gridSelected = params.selectedRows;
				
			},
			selectItem(item){
				this.resetForm();
				this.modalMove.itemsSelected = [];
				this.modalMove.itemsSelected.push(item);
			},
			updateGrid(newItems){

				let _this = this;

				var tempItems = [];

				//update list
				_this.iteminventoriesItems.map((rowItem, index) => {

					if(_this.filterItems.type == 'last'){

						//search item to update
						newItems.map((rowNewItem) => {
							if (rowItem.item_id == rowNewItem.item_id){
								_this.iteminventoriesItems.splice(index,1);
								_this.iteminventoriesItems.unshift(rowNewItem);
							}
						});

					}

				});
				//end update list

			},
			validateCompany(source){
				let _this = this;
				_this.loading = true;

				if(source == 'filter'){

					_this.filterWorkplaces = [];
					axios.get("/admin/companyworkplaces/"+_this.filterItems.company_id+'/search').then((res) => {
						res.data.map((rowReturn) => {
							_this.filterWorkplaces = _this.filterWorkplaces.concat(rowReturn.work_places);
						});
						_this.filterWorkplaces.sort(function(a, b){
							return a.corporate_name - b.corporate_name;
						});
						_this.loadContracts();
					}).catch((err) => {
					});
					this.loading = false;

				}
			},
			validateWorkplace(source){
				let _this = this;

				if(source == 'filter'){

					_this.filterSublocal = [];
					axios.get("/admin/workplacesublocal/"+_this.filterItems.work_place_id+"/search").then((res) => {
						res.data.map((rowSublocal) => {
							_this.filterSublocal.push({
								corporate_name: rowSublocal.sublocal.corporate_name,
								id: rowSublocal.sublocal_id
							});
						});
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
					});

				}

			},
			validateSublocal(source){
				let _this = this;

				_this.loadSectors(source);
				_this.loadSubsectors(source);
				_this.loadItems();

			}
		}
	}
</script>
