<template>
  <div class="row">
    <div class="col">
 
      <!-- table -->
      <div class="card p-4">
        <vue-good-table v-if="users.length"
          :columns="tableColumns"
          :rows="users"
          :pagination-options="tablePagination"
          :search-options="{
            enabled: true,
            placeholder: 'Buscar..',
          }"
          styleClass="vgt-table"
			  >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == '_status'">
              <p class="alert-table alert alert-success" v-if="props.row.status == 1">Ativo</p>
              <p class="alert-table alert alert-danger" v-if="props.row.status == 0">Inativo</p>
            </span>
            <span v-if="props.column.field == 'actions'">
              <div>
                <button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row.id)" title="Editar"><i class="far fa-edit"></i></button>
                <button class="btn btn-sm btn-outline-warning" v-if="userCan.resetpassword" @click="resetPassword(props.row.id)" title="Resetar a Senha"><i class="far fa-envelope"></i></button>
                <button class="btn btn-sm btn-outline-danger" v-if="userCan.disable && props.row.status == 1" @click="disableData(props.row.id)" title="Desativar"><i class="fas fa-ban"></i></button>
                <button class="btn btn-sm btn-outline-success" v-if="userCan.disable && props.row.status == 0" @click="enableData(props.row.id)" title="Ativar"><i class="fas fa-check"></i></button>
                <button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
              </div>
            </span>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
			  </vue-good-table>
        <div v-else class="text-center p-3 text-muted">
            <h5>Nenhum usuário cadastrado!</h5>
        </div>
      </div>
      <!-- end table -->

      <div class="text-center p-4 text-muted" v-if="loading">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>

    </div><!-- col -->

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
  </div><!-- row -->
</template>

<script>
  import ApiService from '../../apiservice.js';
  export default {
    props: ['permissions'],
    mixins: [ApiService],
    data() {
      return {
        fullPage: true,
        users: [],
        loading: false,
        tablePagination: {
          enabled: true,
          nextLabel: 'próxima',
          prevLabel: 'anterior',
          rowsPerPageLabel: 'Registros por página',
          ofLabel: 'de',
          pageLabel: 'página', // for 'pages' mode
          allLabel: 'Todos',
        },
        tableColumns: [
          {
            label: "Nome",
            field: "profile.name",
          },{
            label: "E-mail",
            field: "email",
          },{
            label: "Status",
            field: "_status",
          },{
            label: "Ações",
            field: "actions",
            sortable: false,
            width: '145px'
          }
        ],
        userCan: ''
      }
    },
    mounted() {
      this.userCan = JSON.parse(this.permissions);
      if(this.userCan.list){
        this.loadUsers();
      }
    },
    methods: {
      loadUsers() {
        let _this = this;
        _this.loading = true;
        axios.get('/admin/users').then((res) => {
          _this.users = _this.users.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      },
      deleteData(ID, index) {
        let _this = this;

        //show alert confirmation
        _this.$swal({
          title: "Excluir usuário(a)",
          text: "Tem certeza que deseja excluir esse(a) usuário(a)?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Excluir',
          cancelButtonText: 'Cancelar'
        }).then((alertConfirm) => {

          //validate isConfirmed
          if (alertConfirm.isConfirmed) {

            //remove user
            axios.delete('/admin/users/'+ID).then((res) => {

              _this.users.splice(index, 1);
              
              //call function in ApiService to validate success
              _this._api_validateSuccess(res,false);

            }).catch(err => {

              _this.loading = false;

              //call function in ApiService to validate error
              _this._api_validateError(err);

            });

          }
          //end validate isConfirmed

        });
        //end alert confirmation
      },
      disableData(ID) {
        let _this = this;

        //show alert confirmation
        _this.$swal({
          title: "Desativar usuário(a)",
          text: "Tem certeza que deseja desativar esse(a) usuário(a)?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Desativar',
          cancelButtonText: 'Cancelar'
        }).then((alertConfirm) => {

          //validate isConfirmed
          if (alertConfirm.isConfirmed) {

            axios.get('/admin/users/'+ID+'/disable').then((res) => {

              //search user to disable
              _this.users.map((row) => {
                if (row.id == ID){
                  row.status = 0;
                }
              });
              //end search user to disable
              
              //call function in ApiService to validate success
              _this._api_validateSuccess(res,false);

            }).catch(err => {

              _this.loading = false;

              //call function in ApiService to validate error
              _this._api_validateError(err);

            });

          }
          //end validate isConfirmed

        });
        //end alert confirmation
      },
      editData(ID){
        location.href="/admin/users/"+ID+"/edit";
      },
      enableData(ID) {
        let _this = this;

        //show alert confirmation
        _this.$swal({
          title: "Ativar usuário(a)",
          text: "Tem certeza que deseja ativar esse(a) usuário(a)?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Ativar',
          cancelButtonText: 'Cancelar'
        }).then((alertConfirm) => {

          //validate isConfirmed
          if (alertConfirm.isConfirmed) {

            axios.get('/admin/users/'+ID+'/enable').then((res) => {

              //search user to enable
              _this.users.map((row) => {
                if (row.id == ID){
                  row.status = 1;
                }
              });
              //end search user to enable
              
              //call function in ApiService to validate success
              _this._api_validateSuccess(res,false);

            }).catch(err => {

              _this.loading = false;

              //call function in ApiService to validate error
              _this._api_validateError(err);

            });

          }
          //end validate isConfirmed

        });
        //end alert confirmation
      },
      resetPassword(ID){

        this.loading = true;

        axios.get('/admin/users/'+ID+'/resetpassword').then((res) => {

          this.loading = false;
          
          //call function in ApiService to validate success
          this._api_validateSuccess(res,false);

        }).catch(err => {

          this.loading = false;

          //call function in ApiService to validate error
          this._api_validateError(err);

        });

      }
    }
  }
</script>
