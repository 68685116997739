<template>
    <div>
        <div class="row section-top">
            <div class="col-md-12 pad0">
                <p class="section-lead">
                    Lista de todos Checklists cadastrados
                </p>
                <a
                    href="#"
                    @click="resetForm()"
                    class="btn btn-outline-success float-right"
                    data-toggle="modal"
                    data-target="#modalChecklists"
                    ><i class="fas fa-plus"></i>&nbsp;Novo</a
                >
                <div class="dropdown float-right">
                    <button
                        class="btn btn-outline-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i class="fas fa-download"></i>
                        Exportar
                    </button>
                    <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <a
                            class="dropdown-item"
                            href="#"
                            @click="exportData('pdf')"
                            >PDF</a
                        >
                        <a
                            class="dropdown-item"
                            href="#"
                            @click="exportData('csv')"
                            >Excel</a
                        >
                        <div class="dropdown-divider"></div>
                        <a
                            class="dropdown-item"
                            href="#"
                            @click="
                                $refs.gridfullexport.showModal(
                                    'checklists',
                                    'checklists'
                                )
                            "
                            >Completo</a
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <!-- table -->
                <div class="card p-4">
                    <vue-good-table
                        v-if="checklistsItems.length"
                        :columns="tableColumns"
                        :rows="checklistsItems"
                        :pagination-options="tablePagination"
                        :search-options="{
                            enabled: true,
                            placeholder: 'Buscar..'
                        }"
                        styleClass="vgt-table"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == '_attach'">
                                <p v-if="props.row.attach == 'true'">Sim</p>
                                <p v-if="props.row.attach == 'false'">Não</p>
                            </span>
                            <span v-if="props.column.field == 'actions'">
                                <div>
                                    <button
                                        class="btn btn-sm btn-outline-secondary"
                                        v-if="userCan.edit"
                                        @click="editData(props.row)"
                                        title="Editar"
                                    >
                                        <i class="far fa-edit"></i>
                                    </button>
                                    <button
                                        class="btn btn-sm btn-outline-danger"
                                        v-if="userCan.delete"
                                        @click="
                                            deleteData(
                                                props.row.id,
                                                props.row.index
                                            )
                                        "
                                        title="Deletar"
                                    >
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                    <div v-else class="text-center p-3 text-muted">
                        <h5>Nenhum checklists cadastrado!</h5>
                    </div>
                </div>

                <div class="text-center p-4 text-muted" v-if="loading">
                    <h5>Carregando...</h5>
                    <p>Aguarde até que os dados sejam carregados...</p>
                </div>
            </div>

            <!-- modal -->
            <div
                class="modal fade"
                id="modalChecklists"
                tabindex="-1"
                aria-labelledby="modalChecklistsLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalChecklistsLabel">
                                {{ checklistsTitle }}
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form v-on:submit.prevent="saveData">
                                <div class="row">
                                    <!-- name -->
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label
                                                class="form-control-label"
                                                for="input-name"
                                                >Nome</label
                                            >
                                            <input
                                                type="text"
                                                id="input-name"
                                                v-bind:class="{
                                                    'is-invalid': errors.name
                                                }"
                                                maxlength="60"
                                                class="form-control"
                                                placeholder="Nome"
                                                v-model="checklist.name"
                                            />
                                            <div
                                                class="invalid-feedback d-block"
                                                v-if="errors.name"
                                            >
                                                <p>{{ errors.name[0] }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- details -->
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label
                                                class="form-control-label"
                                                for="input-details"
                                                >Detalhes</label
                                            >
                                            <textarea
                                                type="text"
                                                id="input-details"
                                                v-bind:class="{
                                                    'is-invalid': errors.details
                                                }"
                                                class="form-control"
                                                placeholder="Detalhes"
                                                v-model="checklist.details"
                                                rows="8"
                                            ></textarea>
                                            <div
                                                class="invalid-feedback d-block"
                                                v-if="errors.details"
                                            >
                                                <p>{{ errors.details[0] }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- value -->
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label
                                                class="form-control-label"
                                                for="input-value"
                                                >Conteúdo (separado por
                                                ;)</label
                                            >
                                            <textarea
                                                type="text"
                                                id="input-value"
                                                v-bind:class="{
                                                    'is-invalid': errors.value
                                                }"
                                                class="form-control"
                                                placeholder="Conteúdo"
                                                v-model="checklist.value"
                                                rows="8"
                                            ></textarea>
                                            <div
                                                class="invalid-feedback d-block"
                                                v-if="errors.value"
                                            >
                                                <p>{{ errors.value[0] }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- type -->
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label
                                                class="form-control-label"
                                                for="input-type"
                                                >Tipo</label
                                            >
                                            <v-select
                                                :options="optionsType"
                                                v-bind:class="{
                                                    'is-invalid': errors.type
                                                }"
                                                v-model="checklist.type"
                                                :reduce="label => label.value"
                                            ></v-select>
                                            <div
                                                class="invalid-feedback d-block"
                                                v-if="errors.type"
                                            >
                                                <p>{{ errors.type[0] }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- attach -->
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label
                                                class="form-control-label"
                                                for="input-attach"
                                                >Anexar Foto?</label
                                            >
                                            <v-select
                                                :options="optionsAttach"
                                                v-bind:class="{
                                                    'is-invalid': errors.attach
                                                }"
                                                v-model="checklist.attach"
                                                :reduce="label => label.value"
                                            ></v-select>
                                            <div
                                                class="invalid-feedback d-block"
                                                v-if="errors.attach"
                                            >
                                                <p>{{ errors.attach[0] }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- what_to_check -->
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label
                                                class="form-control-label"
                                                for="input-what_to_check"
                                                >O que verificar?</label
                                            >
                                            <input
                                                type="text"
                                                id="input-what_to_check"
                                                v-bind:class="{
                                                    'is-invalid':
                                                        errors.what_to_check
                                                }"
                                                class="form-control"
                                                placeholder="O que verificar?"
                                                v-model="
                                                    checklist.what_to_check
                                                "
                                            />
                                            <div
                                                class="invalid-feedback d-block"
                                                v-if="errors.what_to_check"
                                            >
                                                <p>
                                                    {{
                                                        errors.what_to_check[0]
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right">
                                    <button
                                        type="submit"
                                        class="btn btn-success"
                                    >
                                        SALVAR
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end modal -->

            <gridfullexport-component
                ref="gridfullexport"
            ></gridfullexport-component>
            <vue-loading
                color="#212121"
                :active.sync="loading"
                :can-cancel="false"
                :is-full-page="fullPage"
            ></vue-loading>
        </div>
    </div>
</template>

<script>
import ApiService from "../../apiservice.js";
import ExportGrid from "../../exportgrid.js";
export default {
    mixins: [ApiService, ExportGrid],
    props: ["permissions"],
    data() {
        return {
            checklistsTitle: "Novo Checklist",
            checklist: {
                name: "",
                details: "",
                value: "",
                type: "",
                what_to_check: "",
                attach: ""
            },
            isEditChecklists: false,
            checklistsID: "",
            checklistsItems: [],
            fullPage: true,
            errors: [],
            loading: false,
            optionsAttach: [
                {
                    label: "Sim",
                    value: "true"
                },
                {
                    label: "Não",
                    value: "false"
                }
            ],
            optionsType: [
                {
                    label: "Texto",
                    value: "text"
                },
                {
                    label: "Seleção",
                    value: "selection"
                },
                {
                    label: "Múltipla Escolha",
                    value: "multiple"
                },
                {
                    label: "Nenhum",
                    value: "none"
                }
            ],
            tablePagination: {
                enabled: true,
                nextLabel: "próxima",
                prevLabel: "anterior",
                rowsPerPageLabel: "Registros por página",
                tofLabel: "de",
                pageLabel: "página",
                allLabel: "Todos"
            },
            tableColumns: [
                {
                    label: "ID",
                    field: "id"
                },
                {
                    label: "Nome",
                    field: "name"
                },
                {
                    label: "Tipo",
                    field: "type"
                },
                {
                    label: "O que verificar?",
                    field: "what_to_check"
                },
                {
                    label: "Anexo?",
                    field: "_attach"
                },
                {
                    label: "Data e Hora",
                    field: "created_at",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
                    dateOutputFormat: "dd/MM/yyyy HH:mm:ss"
                },
                {
                    label: "Ações",
                    field: "actions",
                    sortable: false,
                    width: "100px"
                }
            ],
            userCan: ""
        };
    },
    mounted() {
        this.userCan = JSON.parse(this.permissions);
        if (this.userCan.list) {
            this.loadItems();
        }
    },
    methods: {
        loadItems() {
            let _this = this;
            _this.loading = true;
            axios
                .get("/admin/checklists")
                .then(res => {
                    _this.checklistsItems = _this.checklistsItems.concat(
                        res.data
                    );
                    _this.loading = false;
                })
                .catch(err => {
                    _this.loading = false;
                });
        },
        exportData(type) {
            let params =
                type == "pdf"
                    ? {
                          type: "pdf",
                          fileName: "Checklists",
                          ignoreColumn: [5],
                          htmlContent: false,
                          onCellHtmlData: (cell, row, col, htmlContent) =>
                              htmlContent,
                          jspdf: {
                              orientation: "l",
                              margins: {
                                  right: 10,
                                  left: 10,
                                  top: 40,
                                  bottom: 40
                              },
                              autotable: {
                                  styles: {
                                      fillColor: "inherit",
                                      textColor: "inherit",
                                      halign: "center",
                                      fillStyle: "DF",
                                      lineColor: 200,
                                      lineWidth: 0.1
                                  },
                                  headerStyles: { halign: "left" },
                                  tableWidth: "auto"
                              }
                          }
                      }
                    : {
                          type: "excel",
                          htmlContent: false,
                          fileName: "Checklists",
                          ignoreColumn: [8],
                          mso: { fileFormat: "xlsx" }
                      };

            let options = { tableName: "Checklists" };
            jQuery.extend(true, options, params);
            $("#vgt-table").tableExport(options);
        },
        saveData() {
            let _this = this;
            _this.errors = [];
            _this.loading = true;

            if (_this.isEditChecklists == true) {
                let dataInfo = {
                    name: _this.checklist.name,
                    details: _this.checklist.details,
                    value: _this.checklist.value,
                    type: _this.checklist.type,
                    what_to_check: _this.checklist.what_to_check,
                    attach: _this.checklist.attach,
                    _method: "PATCH"
                };
                axios
                    .post("/admin/checklists/" + _this.checklistsID, dataInfo)
                    .then(res => {
                        _this.loading = false;
                        _this.resetForm();

                        $("#modalChecklists").modal("hide");

                        //call function in ApiService to validate success
                        _this._api_validateSuccess(res, false);
                    })
                    .catch(err => {
                        _this.loading = false;

                        if (err.response.data.errors) {
                            //set errors
                            _this.errors = err.response.data.errors;
                        }

                        //call function in ApiService to validate error
                        _this._api_validateError(err);
                    });
            } else {
                let dataInfo = {
                    name: _this.checklist.name,
                    details: _this.checklist.details,
                    value: _this.checklist.value,
                    type: _this.checklist.type,
                    what_to_check: _this.checklist.what_to_check,
                    attach: _this.checklist.attach,
                    last: ""
                };
                axios
                    .post("/admin/checklists", dataInfo)
                    .then(res => {
                        _this.loading = false;
                        _this.resetForm();

                        _this.checklistsItems.push(res.data.data);
                        $("#modalChecklists").modal("hide");

                        //call function in ApiService to validate success
                        _this._api_validateSuccess(res, false);
                    })
                    .catch(err => {
                        _this.loading = false;

                        if (err.response.data.errors) {
                            //set errors
                            _this.errors = err.response.data.errors;
                        }

                        //call function in ApiService to validate error
                        _this._api_validateError(err);
                    });
            }
        },
        deleteData(ID, index) {
            let _this = this;

            //show alert confirmation
            _this
                .$swal({
                    title: "Excluir checklists",
                    text: "Tem certeza que deseja excluir esse checklists?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Excluir",
                    cancelButtonText: "Cancelar"
                })
                .then(alertConfirm => {
                    //validate isConfirmed
                    if (alertConfirm.isConfirmed) {
                        //remove
                        axios
                            .delete("/admin/checklists/" + ID)
                            .then(res => {
                                //_this.checklists.splice(index, 1);

                                //call function in ApiService to validate success
                                _this._api_validateSuccess(res, true);
                            })
                            .catch(err => {
                                _this.loading = false;

                                //call function in ApiService to validate error
                                _this._api_validateError(err);
                            });
                    } //end validate isConfirmed
                });
            //end alert confirmation
        },
        editData(item) {
            this.checklist = item;
            this.isEditChecklists = true;
            this.checklistsID = item.id;
            this.checklistsTitle = "Edit Checklist";

            this.errors = [];

            $("#modalChecklists").modal("show");
        },
        resetForm() {
            this.checklist = {
                name: "",
                details: "",
                value: "",
                type: "",
                what_to_check: "",
                attach: ""
            };
            this.errors = [];

            this.isEditChecklists = false;
            this.checklistsID = "";
            this.checklistsTitle = "Novo Checklist";
        }
    }
};
</script>
