<template>
	<div class="tab-pane fade" id="tab-companyworkplacecolaborators" role="tabpanel" aria-labelledby="companyworkplacecolaborators-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Vínculos realizados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalCompanyWorkPlaceColaborator"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="companyworkplacesItems.length"
						:columns="tableColumns"
						:rows="companyworkplacesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_source'">
								<div v-if="props.row.source == 1">Todos</div>
								<div v-if="props.row.source == 2">Ocorrências</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum vínculo encontrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalCompanyWorkPlaceColaborator" tabindex="-1" aria-labelledby="modalCompanyWorkPlaceColaboratorLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalCompanyWorkPlaceColaboratorLabel">{{companyWorkplaceTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- contract -->
                  <div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-workplace_id">Contrato</label>
											<v-select :options="contracts" label="corporate_name" v-model="contract_id" :reduce="corporate_name => corporate_name.id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.contract_id">
												<p>{{ errors.contract_id[0] }}</p>
											</div>
										</div>
									</div>

                  <!-- workplace -->
                  <div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-workplace_id">Local de Trabalho</label>
											<v-select :options="workplaces" label="corporate_name" v-model="workplace_id" :reduce="corporate_name => corporate_name.id" @input="validateWorkplace()"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.workplace_id">
												<p>{{ errors.workplace_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- sublocal -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-sublocal_id">SubLocal de Trabalho</label>
											<v-select :options="sublocal" label="corporate_name" v-model="sublocal_id" :reduce="corporate_name => corporate_name.id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.sublocal_id">
												<p>{{ errors.sublocal_id[0] }}</p>
											</div>
										</div>
									</div>

                  <!-- colaborator -->
                  <div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-colaborator_id">Colaborador</label>
											<v-select :options="colaborators" v-model="colaborator_id" :reduce="label => label.value"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.colaborator_id">
												<p>{{ errors.colaborator_id[0] }}</p>
											</div>
										</div>
									</div>

                  <!-- colaborator groups -->
                  <div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-colaborator_group_id">Grupo de Colaboradores</label>
											<v-select :options="colaboratorGroups" label="name" v-model="colaborator_group_id" :reduce="name => name.id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.colaborator_group_id">
												<p>{{ errors.colaborator_group_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- source -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-source">Tipo de Vínculo</label>
											<v-select :options="optionsSource" v-model="source" :reduce="label => label.value"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.source">
												<p>{{ errors.source[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['companies'],
		data() {
			return {
        colaborator_id: '',
        colaborators: [],
        colaboratorGroups: [],
        colaborator_group_id: '',
				companyWorkplaceTitle: "Vincular",
				colaboratorgroup: {
					name: '',
				},
				companyID: '',
				contracts: [],
				contract_id: '',
				isEditCompanyWorkplaces: false,
				companyWorkPlaceID: "",
				companyworkplacesItems: [],
				sublocal: [],
				sublocal_id: '',
				fullPage: true,
				errors: [],
				loading: false,
				optionsSource: [{
					label: 'Todos',
					value: 1
				},{
					label: 'Ocorrências',
					value: 2
				}],
				source: '',
        workplaces: [],
        workplace_id: '',
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
				{
						label: 'Contrato',
						field: 'contracts.corporate_name',
					},{
						label: 'Local de Trabalho',
						field: 'work_places.corporate_name',
					},{
						label: 'Sublocal de Trabalho',
						field: 'sublocal.corporate_name',
					},{
						label: 'Grupo',
						field: 'colaborator_groups.name',
					},{
						label: 'Colaborador',
						field: 'colaborators.users.profile.name',
					},{
						label: 'Tipo',
						field: '_source',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				]
			}
		},
		mounted() {
			this.companyID = (JSON.parse(this.companies)).id;
      this.loadColaborators();
      this.loadColaboratorsGroups();
			this.loadContracts();
      this.loadWorkPlaces();
      this.loadItems();
		},
		methods: {
      loadColaborators() {
				let _this = this;
				_this.loading = true;
				_this.colaborators = [];
				axios.get("/admin/companycolaborators/"+_this.companyID+"/search").then((res) => {
          res.data.map((row) => {
            _this.colaborators = _this.colaborators.concat({
              label: row.colaborators.users.profile.name,
              value: row.colaborators.id
            });
          });
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      loadColaboratorsGroups() {
				let _this = this;
				_this.loading = true;
				_this.colaboratorGroups = [];
				axios.get("/admin/colaboratorgroups/"+_this.companyID+"/search").then((res) => {
					_this.colaboratorGroups = _this.colaboratorGroups.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.contracts = [];
				axios.get("/admin/companycontracts/"+this.companyID+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.contracts.push(rowContract.contracts);
					});
          //_this.contracts = _this.contracts.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadWorkPlaces() {
				let _this = this;
				_this.loading = true;
				_this.workplaces = [];
				axios.get("/admin/companyworkplaces/"+this.companyID+"/search").then((res) => {
					res.data.map((rowWorkplace) => {
						_this.workplaces.push(rowWorkplace.work_places);
					});
          //_this.workplaces = _this.workplaces.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				_this.companyworkplacesItems = [];
				axios.get("/admin/companyworkplacecolaborators/"+this.companyID+"/search").then((res) => {
					_this.companyworkplacesItems = _this.companyworkplacesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;
				
				if (_this.isEditCompanyWorkplaces == true){

					let dataInfo = {
						"company": _this.companyID,
						"contract": _this.contract_id,
            "colaborator": _this.colaborator_id,
            "group": _this.colaborator_group_id,
            "workplace": _this.workplace_id,
						"sublocal": _this.sublocal_id,
						"source": _this.source,
						"_method": "PATCH"
					};

					axios.post("/admin/companyworkplacecolaborators/"+_this.companyWorkPlaceID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalCompanyWorkPlaceColaborator').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}
						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"company": _this.companyID,
            "colaborator": _this.colaborator_id,
						"contract": _this.contract_id,
            "group": _this.colaborator_group_id,
            "workplace": _this.workplace_id,
						"sublocal": _this.sublocal_id,
						"source": _this.source,
						"last": ""
					};
					axios.post("/admin/companyworkplacecolaborators", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.companyworkplacesItems.push(res.data.data);
						$('#modalCompanyWorkPlaceColaborator').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Vínculo",
					text: "Tem certeza que deseja excluir esse vínculo?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/companyworkplacecolaborators/"+ID).then((res) => {

							_this.companyworkplacesItems.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,false);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.colaborator_id = item.colaborator_id;
				this.colaborator_group_id = item.colaborator_group_id;
        this.workplace_id = item.work_place_id;
				this.source = item.source;

				this.isEditCompanyWorkplaces = true;
				this.companyWorkPlaceID = item.id;
				this.companyWorkplaceTitle = "Editar Vínculo";

				$("#modalCompanyWorkPlaceColaborator").modal('show');

			},
			resetForm(){

				this.colaborator_id = "";
				this.colaborator_group_id = "";
        this.workplace_id = "";
				this.sublocal_id = "";

				this.loadColaborators();
				this.loadColaboratorsGroups();
				this.loadWorkPlaces();

			},
			validateWorkplace(){

				let _this = this;
				_this.loading = true;
				_this.sublocal = [];
				axios.get("/admin/workplacesublocal/"+this.workplace_id+"/search").then((res) => {
					res.data.map((rowSublocal) => {
						_this.sublocal.push({
							corporate_name: rowSublocal.sublocal.corporate_name,
							id: rowSublocal.sublocal_id
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});

			}
		}
	}
</script>
