<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Configurações de Notiticação cadastradas</p>
				<a href="/admin/configurationnotifications/create" class="btn btn-outline-success float-right"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<a class="btn btn-outline-warning float-right" data-toggle="collapse" href="#panelFilter" role="button" aria-expanded="false" aria-controls="panelFilter"><i class="fas fa-filter"></i>&nbsp;Filtrar</a>
			</div>
		</div>


		<div class="row">
			<div class="col-md-12">
				<div class="collapse" id="panelFilter">
					<div class="card card-body">

						<div class="row">

							<!-- name -->
							<div class="col-md-4">
								<label class="form-control-label" for="input-name">Nome</label>
								<input type="text" id="input-name"   class="form-control" placeholder="Nome" v-model="filterItems.name" required="false" >
							</div>	

							<!-- type -->
							<div class="col-md-4">
								<label class="form-control-label" for="input-type">Tipo</label>
								<v-select :options="optionsType" :reduce="label => label.value" v-model="filterItems.type"></v-select>
							</div>

							<!-- subject -->
							<div class="col-md-4">
								<label class="form-control-label" for="input-subject">Assunto</label>
								<input type="text" id="input-subject"   class="form-control" placeholder="Assunto" v-model="filterItems.subject" required="false" >
							</div>

							<!-- to -->
							<div class="col-md-4 mt-2">
								<label class="form-control-label" for="input-to">Para</label>
								<input type="text" id="input-to"   class="form-control" placeholder="Para" v-model="filterItems.to" required="false" >
							</div>
							
							<!-- cc -->
							<div class="col-md-4 mt-2">
								<label class="form-control-label" for="input-cc">Cópia</label>
								<input type="text" id="input-cc"   class="form-control" placeholder="Cópia" v-model="filterItems.cc" required="false" >
							</div>

							<!-- cco -->
							<div class="col-md-4 mt-2">
								<label class="form-control-label" for="input-cco">Cópia Oculta</label>
								<input type="text" id="input-cco"   class="form-control" placeholder="Cópia Oculta" v-model="filterItems.cco" required="false" >
							</div>
							
							<div class="col-md-12 text-right mt-2">
								<button class="btn btn-success" @click="filter()">FILTRAR</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="configurationnotificationsItems.length"
						:columns="tableColumns"
						:rows="configurationnotificationsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_type'">
								<p v-if="props.row.type == 'day'">Dia</p>
								<p v-if="props.row.type == 'month'">Mês</p>
								<p v-if="props.row.type == 'year'">Ano</p>
								<p v-if="props.row.type == 'scale'">Escala</p>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum configuração de notificação cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>
		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				configurationnotificationsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				optionsType: [{
					label: 'Dia',
					value: 'day'
				},{
					label: 'Mês',
					value: 'month'
				},{
					label: 'Ano',
					value: 'year'
				},{
					label: 'Escala',
					value: 'scale'
				}],
				optionsSource: [{
					label: 'E-mail',
					value: 'email'
				}],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'name',
					},
					{
						label: 'Tipo',
						field: '_type',
					},
					{
						label: 'Assunto',
						field: 'subject',
					},
					{
						label: 'Para',
						field: 'to',
					},
					{
						label: 'CC',
						field: 'cc',
					},
					{
						label: 'CCO',
						field: 'cco',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				filterItems: {},
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/configurationnotifications").then((res) => {
					_this.configurationnotificationsItems = _this.configurationnotificationsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir configurationnotifications",
					text: "Tem certeza que deseja excluir esse(a) configurationnotifications?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/configurationnotifications/"+ID).then((res) => {

							//_this.configurationnotifications.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			filter(){
				let _this = this;
				_this.loading = true;
				let dataFilter = {
					"name" : _this.filterItems.name,
					"type" : _this.filterItems.type,
					"subject" : _this.filterItems.subject,
					"to" : _this.filterItems.to,
					"cc" : _this.filterItems.cc,
					"cco" : _this.filterItems.cco,
					"last": ""
				};
				axios.post("/admin/configurationnotifications/filter", dataFilter).then((res) => {

					_this.loading = false;

					_this.configurationnotificationsItems = [];
					_this.configurationnotificationsItems.push(res.data.data);

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			editData(item){
				location.href="/admin/configurationnotifications/"+item.id+"/edit";
			},
			resetForm(){

				this.configurationnotification = {
					name: '',
					type: '',
					subject: '',
					content: '',
					to: '',
					cc: '',
					cco: '',
					source: '',
					time: '',
				};

			}
		}
	}
</script>
