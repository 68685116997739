<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Tipos de EPI cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalEpiTypes"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<div class="dropdown float-right">
					<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-download"></i>
						Exportar
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#" @click="exportData('pdf')">PDF</a>
						<a class="dropdown-item" href="#" @click="exportData('csv')">Excel</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="#" @click="$refs.gridfullexport.showModal('epi_types','epi_types')">Completo</a>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="epitypesItems.length"
						:columns="tableColumns"
						:rows="epitypesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_icon'">
								<img :src="'https://mvtecnologia.s3.us-east-1.amazonaws.com/epis/'+props.row.icon" width="50"/>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum tipos de epi cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalEpiTypes" tabindex="-1" aria-labelledby="modalEpiTypesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalEpiTypesLabel">{{epitypesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

							<!-- name -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-name">Nome</label>
									<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" maxlength="60" class="form-control" placeholder="Nome" v-model="epitype.name">
									<div class="invalid-feedback d-block" v-if="errors.name">
										<p>{{ errors.name[0] }}</p>
									</div>
								</div>
							</div>
							

							<!-- description -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-description">Descrição</label>
									<textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" class="form-control" placeholder="Descrição" v-model="epitype.description" rows="8" maxlength="250"></textarea>
									<div class="invalid-feedback d-block" v-if="errors.description">
										<p>{{ errors.description[0] }}</p>
									</div>
								</div>
							</div>
							

							<!-- icon -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-icon">Ícone</label>
									<div class="custom-file">
										<input type="file" class="custom-file-input" v-bind:class="{'is-invalid': errors.icon}" id="icon" name="icon" accept="image/*" ref="icon" lang="pt" v-on:change="handleFileIconUpload()">
										<label class="custom-file-label" for="icon">{{fileIconName}}</label>
									</div>
									<div class="invalid-feedback d-block" v-if="errors.icon">
										<p>{{ errors.icon[0] }}</p>
									</div>
								</div>
							</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<gridfullexport-component ref="gridfullexport"></gridfullexport-component>
			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		props: ['permissions'],
		data() {
			return {
				fileIconName: "Selecione o arquivo",
				epitypesTitle: "Novo Tipo de EPI",
				epitype: {
					name: '',
					description: '',
					icon: '',
				},
				isEditEpiTypes: false,
				epitypesID: "",
				epitypesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Ícone',
						field: '_icon',
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Descrição',
						field: 'description',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: '',
				tempIcon: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			handleFileIconUpload(){
				this.epitype.icon = this.$refs.icon.files[0];
				this.fileIconName = this.$refs.icon.files[0].name;
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/epitypes").then((res) => {
					_this.epitypesItems = _this.epitypesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			exportData(type){
        var tempForm = $('#vgt-table');
				if(type == 'pdf'){
					this._exportPDF('Tipos de EPI', [5], tempForm, ['ID','Ícone','Nome','Descrição','Data e Hora']);
				} else {
					this._exportExcel('Tipos de EPI', [5], tempForm, ['ID','Ícone','Nome','Descrição','Data e Hora']);
				}
    	},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditEpiTypes == true){

					let formData = new FormData();
					formData.append("name", _this.epitype.name);
					formData.append("description", _this.epitype.description);
					formData.append("icon", _this.epitype.icon);
					formData.append("_method", "PATCH");

					axios.post("/admin/epitypes/"+_this.epitypesID, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						//search to update
						_this.epitypesItems.map((row) => {
							if (row.id == _this.epitypesID){
								row.icon = res.data.data.icon;
							}
						});

						_this.loading = false;
						_this.resetForm();

						$('#modalEpiTypes').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let formData = new FormData();
					formData.append("name", _this.epitype.name);
					formData.append("description", _this.epitype.description);
					formData.append("icon", _this.epitype.icon);

					axios.post("/admin/epitypes", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.epitypesItems.push(res.data.data);
						$('#modalEpiTypes').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Tipo de EPI",
					text: "Tem certeza que deseja excluir esse Tipo de EPI?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/epitypes/"+ID).then((res) => {

							//_this.epitypes.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;
							

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.epitype = item;
				this.tempIcon = item.icon;
				this.isEditEpiTypes = true;
				this.epitypesID = item.id;
				this.epitypesTitle = "Editar Tipo de EPI";

				$("#modalEpiTypes").modal('show');

			},
			resetForm(){

				this.epitype = {
					name: '',
					description: '',
					icon: '',
				};
				this.fileIconName = "";
				this.errors = [];

				this.isEditEpiTypes = false;
				this.fileIconName = "Selecione o arquivo";
				this.epitypesTitle = "Novo Tipo de EPI";
				this.epitypesID = "";

			}
		}
	}
</script>
