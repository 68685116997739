<template>
	<div class="tab-pane fade" id="tab-templateepis" role="tabpanel" aria-labelledby="templateepis-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos EPIs cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalTemplateEpis"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="templateepisItems.length"
						:columns="tableColumns"
						:rows="templateepisItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum EPI cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalTemplateEpis" tabindex="-1" aria-labelledby="modalTemplateEpisLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalTemplateEpisLabel">{{templateepisTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

							<!-- epi_type_id -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-epi_type_id">EPI</label>
									<v-select :options="epistypes" v-bind:class="{'is-invalid': errors.epi_type}" :reduce="name => name.id" label="name" v-model="templateepi.epi_type_id"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.epi_type">
										<p>{{ errors.epi_type[0] }}</p>
									</div>
								</div>
							</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions','templates'],
		data() {
			return {
				epistypes: [],
				templateepisTitle: "Novo Vínculo de EPI",
				templateepi: {
					epi_type_id: '',
				},
				isEditTemplateEpis: false,
				templateepisID: "",
				templateepisItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'epis_types.name',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadEpisTypes();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/templateepis/"+JSON.parse(_this.templates).id+'/search').then((res) => {
					_this.templateepisItems = _this.templateepisItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditTemplateEpis == true){

					let dataInfo = {
						"epi_type" : _this.templateepi.epi_type_id,
						"template": JSON.parse(_this.templates).id,
						"_method": "PATCH"
					};
					axios.post("/admin/templateepis/"+_this.templateepisID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalTemplateEpis').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"epi_type" : _this.templateepi.epi_type_id,
						"template": JSON.parse(_this.templates).id,
						"last": ""
					};
					axios.post("/admin/templateepis", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.templateepisItems.push(res.data.data);
						$('#modalTemplateEpis').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Vínculo de EPI",
					text: "Tem certeza que deseja excluir esse vínculo?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/templateepis/"+ID).then((res) => {

							//_this.templateepis.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.templateepi = item;
				this.isEditTemplateEpis = true;
				this.templateepisID = item.id;
				this.templateepisTitle = "Editar Vínculo de EPI";

				$("#modalTemplateEpis").modal('show');

			},
			loadEpisTypes() {
				let _this = this;
				axios.get("/admin/epitypes").then((res) => {
					_this.epistypes = _this.epistypes.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.templateepi = {
					epi_type_id: '',
				};

				this.errors = [];
				this.isEditTemplateEpis = false;
				this.templateepisID = "";
				this.templateepisTitle = "Novo Vínculo de EPI";

			}
		}
	}
</script>
