<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Subcategorias cadastradas</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalSubCategories"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<div class="dropdown float-right">
					<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-download"></i>
						Exportar
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#" @click="exportData('pdf')">PDF</a>
						<a class="dropdown-item" href="#" @click="exportData('csv')">Excel</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="#" @click="$refs.gridfullexport.showModal('auxiliares','subcategories')">Completo</a>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="subcategoriesItems.length"
						:columns="tableColumns"
						:rows="subcategoriesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma subcategoria cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalSubCategories" tabindex="-1" aria-labelledby="modalSubCategoriesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalSubCategoriesLabel">{{subcategoriesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"  maxlength="60" class="form-control" placeholder="Nome" v-model="subcategory.name" >
											<div class="invalid-feedback d-block" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>

									<!-- category_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-category_id">Categoria</label>
											<v-select :options="categories" label="name" v-bind:class="{'is-invalid': errors.category_id}" :reduce="name => name.id" v-model="subcategory.category_id"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.category_id">
												<p>{{ errors.category_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- description -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-description">Descrição</label>
											<textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" class="form-control" placeholder="Descrição" v-model="subcategory.description" rows="8" maxlength="250"></textarea>
											<div class="invalid-feedback d-block" v-if="errors.description">
												<p>{{ errors.description[0] }}</p>
											</div>
										</div>
									</div>

								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<gridfullexport-component ref="gridfullexport"></gridfullexport-component>
			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		props: ['permissions'],
		data() {
			return {
				categories: [],
				subcategoriesTitle: "Nova Subcategoria",
				subcategory: {
					name: '',
					description: '',
					category_id: '',
				},
				isEditSubCategories: false,
				subcategoriesID: "",
				subcategoriesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Categoria',
						field: 'categories.name',
					},{
						label: 'Descrição',
						field: 'description',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadCategories();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/subcategories").then((res) => {
					_this.subcategoriesItems = _this.subcategoriesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			exportData(type){
				var tempForm = $('#vgt-table');
				if(type == 'pdf'){
					this._exportPDF('Subcategorias', [5], tempForm, ['ID','Nome','Categoria','Descrição','Data e Hora']);
				} else {
					this._exportExcel('Subcategorias', [5], tempForm, ['ID','Nome','Categoria','Descrição','Data e Hora']);
				}
    	},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditSubCategories == true){

					let dataInfo = {
						"name" : _this.subcategory.name,
						"description" : _this.subcategory.description,
						"category_id" : _this.subcategory.category_id,
						"_method": "PATCH"
					};
					axios.post("/admin/subcategories/"+_this.subcategoriesID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalSubCategories').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.subcategory.name,
						"description" : _this.subcategory.description,
						"category_id" : _this.subcategory.category_id,
						"last": ""
					};
					axios.post("/admin/subcategories", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.subcategoriesItems.push(res.data.data);
						$('#modalSubCategories').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Subcategoria",
					text: "Tem certeza que deseja excluir essa subcategoria?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/subcategories/"+ID).then((res) => {

							//_this.subcategories.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.subcategory = item;
				this.isEditSubCategories = true;
				this.subcategoriesID = item.id;
				this.subcategoriesTitle = "Editar Subcategoria";

				$("#modalSubCategories").modal('show');

			},
			loadCategories() {
				let _this = this;
				axios.get("/admin/categories").then((res) => {
					_this.categories = _this.categories.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.subcategory = {
					name: '',
					description: '',
					category_id: '',
				};
				this.errors = [];

				this.isEditSubCategories = false;
				this.subcategoriesID = "";
				this.subcategoriesTitle = "Nova Subcategoria";

			}
		}
	}
</script>
