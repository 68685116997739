<template>
	<div class="tab-pane fade show active" id="tab-geral" role="tabpanel" aria-labelledby="geral-tab">
		<div class="row">
			<div class="col-12">
				<div class="card">

					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>
					<!-- end card header -->

					<!-- card body -->
					<div class="card-body">

						<!-- form -->
						<h6 class="heading-small text-muted mb-4">Dados</h6>
						<div class="">
							<div class="row">

								<!-- corporate_name -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-corporate_name">Razão Social</label>
										<input type="text" id="input-corporate_name" v-bind:class="{'is-invalid': errors.corporate_name}"   class="form-control" placeholder="Razão Social" v-model="corporate_name" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.corporate_name">
											<p>{{ errors.corporate_name[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end corporate_name -->

								<!-- cnpj -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-cnpj">CNPJ</label>
										<input type="tel" id="input-cnpj" v-bind:class="{'is-invalid': errors.cnpj}"  maxlength="30" class="form-control" placeholder="CNPJ" v-model="cnpj" required="false" v-mask="'##.###.###/####-##'">
										<div class="invalid-feedback d-block" v-if="errors.cnpj">
											<p>{{ errors.cnpj[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-3 -->
								<!-- end cnpj -->

								<!-- company_type_id -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-company_type_id">Tipo de Empresa</label>
										<select type="text" id="input-company_type_id" v-bind:class="{'is-invalid': errors.company_type_id}" class="form-control" placeholder="Tipo de Empresa" v-model="company_type_id" required="true" data-toggle="select">
											<option v-for="item, index in companytypes" :key="index" :value="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback d-block" v-if="errors.company_type_id">
											<p>{{ errors.company_type_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-3 -->
								<!-- end company_type_id -->

								<!-- cep -->
								<div class="col-md-2">
									<div class="form-group input-group-merge">
										<label class="form-control-label" for="input-cep">CEP</label>
										<div class="input-group">
											<input type="text" v-bind:class="{'is-invalid': errors.cep}"  maxlength="20" class="form-control" placeholder="CEP" v-model="cep" required="true">
											<div class="input-group-append">
												<button class="btn btn-outline-secondary" @click="searchCEP()" type="button" id="button-addon2"><i class="fas fa-search"></i></button>
											</div>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-2 -->
								<!-- end cep -->

								<!-- address -->
								<div class="col-md-5">
									<div class="form-group">
										<label class="form-control-label" for="input-address">Endereço</label>
										<input type="text" id="input-address" v-bind:class="{'is-invalid': errors.address}"   class="form-control" placeholder="Endereço" v-model="address" required="true" >
										<div class="invalid-feedback d-block" v-if="errors.address">
											<p>{{ errors.address[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-4 -->
								<!-- end address -->

								<!-- number -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-number">Nº</label>
										<input type="text" id="input-number" v-bind:class="{'is-invalid': errors.number}"  maxlength="100" class="form-control" placeholder="Nº" v-model="number" required="true" >
										<div class="invalid-feedback d-block" v-if="errors.number">
											<p>{{ errors.number[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-2 -->
								<!-- end number -->

								<!-- complement -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-complement">Complemento</label>
										<input type="text" id="input-complement" v-bind:class="{'is-invalid': errors.complement}"   class="form-control" placeholder="Complemento" v-model="complement" required="true" >
										<div class="invalid-feedback d-block" v-if="errors.complement">
											<p>{{ errors.complement[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-3 -->
								<!-- end complement -->

								<!-- state -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-state">Estado</label>
										<v-select :options="states" label="Sigla" v-bind:class="{'is-invalid': errors.state}" v-model="state" @input="validateCities()"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.state">
											<p>{{ errors.state[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-2 -->
								<!-- end state -->

								<!-- neighborhood -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-neighborhood">Bairro</label>
										<input type="text" id="input-neighborhood" v-bind:class="{'is-invalid': errors.neighborhood}"   class="form-control" placeholder="Bairro" v-model="neighborhood" required="true" >
										<div class="invalid-feedback d-block" v-if="errors.neighborhood">
											<p>{{ errors.neighborhood[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-5 -->
								<!-- end neighborhood -->

								<!-- city -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-city">Cidade</label>
										<v-select :options="cities" label="Nome" v-bind:class="{'is-invalid': errors.city}" v-model="city"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.city">
											<p>{{ errors.city[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-4 -->
								<!-- end city -->

								<!-- country -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-country">País</label>
										<input type="text" id="input-country" v-bind:class="{'is-invalid': errors.country}"   class="form-control" placeholder="País" v-model="country" required="true" >
										<div class="invalid-feedback d-block" v-if="errors.country">
											<p>{{ errors.country[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-3 -->
								<!-- end country -->

								<span class="separator"></span>

								<!-- responsible_name -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-responsible_name">Nome do Responsável</label>
										<input type="text" id="input-responsible_name" v-bind:class="{'is-invalid': errors.responsible_name}"   class="form-control" placeholder="Nome do Responsável" v-model="responsible_name" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.responsible_name">
											<p>{{ errors.responsible_name[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-4 -->
								<!-- end responsible_name -->

								<!-- responsible_phone -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-responsible_phone">Telefone do Responsável</label>
										<input type="tel" id="input-responsible_phone" v-bind:class="{'is-invalid': errors.responsible_phone}"  maxlength="30" class="form-control" placeholder="Telefone do Responsável" v-model="responsible_phone" required="false" v-mask="'(##) #####-####'">
										<div class="invalid-feedback d-block" v-if="errors.responsible_phone">
											<p>{{ errors.responsible_phone[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-4 -->
								<!-- end responsible_phone -->

								<!-- responsible_email -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-responsible_email">E-mail do Responsável</label>
										<input type="email" id="input-responsible_email" v-bind:class="{'is-invalid': errors.responsible_email}"   class="form-control" placeholder="E-mail do Responsável" v-model="responsible_email" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.responsible_email">
											<p>{{ errors.responsible_email[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-4 -->
								<!-- end responsible_email -->

								<span class="separator"></span>

								<!-- logotipo -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-logotipo">Logotipo</label>
										<a :href="'/storage/companies/'+logotipo" v-bind:class="{'is-invalid': errors.logotipo}" target="_blank" class="btn btn-outline-primary btn-logo">Ver Logotipo</a>
									</div>
								</div>
								<div class="col-md-10">
									<div class="form-group">
										<label class="form-control-label" for="input-logotipo">Clique para selecionar um novo logotipo</label>
										<div class="custom-file">
											<input type="file" class="custom-file-input" id="logotipo" name="logotipo" accept="image/*" ref="logotipo" lang="pt" v-on:change="handleFilelogotipoUpload()">
											<label class="custom-file-label" for="logotipo">{{filelogotipoName}}</label>
										</div>
										<div class="invalid-feedback d-block" v-if="errors.logotipo">
											<p>{{ errors.logotipo[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-12 -->
								<!-- end logotipo -->

								<span class="separator"></span>

								<!-- observations -->
								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-observations">Observações</label>
										<textarea type="text" id="input-observations" v-bind:class="{'is-invalid': errors.observations}" class="form-control" placeholder="Observações" v-model="observations" rows="8" required="true"></textarea>
										<div class="invalid-feedback d-block" v-if="errors.observations">
											<p>{{ errors.observations[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-12 -->
								<!-- end observations -->

							</div>
						</div>
						<!-- end form -->

					</div><!-- card body -->
				</div><!-- card -->
			</div><!-- col-12 -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div><!-- row -->
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ["companies"],
		data() {
			return {
				corporate_name: this.getData("corporate_name"),
				cnpj: this.getData("cnpj"),
				address: this.getData("address"),
				complement: this.getData("complement"),
				neighborhood: this.getData("neighborhood"),
				state: this.getData("state"),
				country: this.getData("country"),
				number: this.getData("number"),
				cep: this.getData("cep"),
				city: this.getData("city"),
				observations: this.getData("observations"),
				responsible_name: this.getData("responsible_name"),
				responsible_phone: this.getData("responsible_phone"),
				responsible_email: this.getData("responsible_email"),
				company_type_id: this.getData("company_type_id"),
				companytypes: [],
				logotipo: this.getData("logotipo"),
				filelogotipoName: "Selecione o arquivo",
				errors: [],
				message: "",
				loading: false,
				fullPage: true,
				cities: [],
				states: [],
				tempCities: []
			}
		},
		mounted() {
			this.loadCompanyTypes();
			this.loadCities();
			this.loadStates();
		},
		methods: {
			getData(key) {
				return JSON.parse(this.companies)[key];
			},
			handleFilelogotipoUpload(){
				this.logotipo = this.$refs.logotipo.files[0];
				this.filelogotipoName = this.$refs.logotipo.files[0].name;
			},
			loadCities(){
        let _this = this;
        axios.get('/js/cities.min.json').then((res) => {
          _this.tempCities = res.data;
        }).catch((err) => {
          console.log(err);
        });
      },
      loadStates(){
        let _this = this;
        axios.get('/js/states.min.json').then((res) => {
          _this.states = res.data;
        }).catch((err) => {
          console.log(err);
        });
      },
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"corporate_name" : _this.corporate_name,
					"cnpj" : _this.cnpj,
					"address" : _this.address,
					"complement" : _this.complement,
					"neighborhood" : _this.neighborhood,
					"state" : _this.state,
					"country" : _this.country,
					"number" : _this.number,
					"cep" : _this.cep,
					"city" : _this.city,
					"observations" : _this.observations,
					"responsible_name" : _this.responsible_name,
					"responsible_phone" : _this.responsible_phone,
					"responsible_email" : _this.responsible_email,
					"company_type_id" : _this.company_type_id,
					"logotipo" : _this.logotipo,
					"_method": "PATCH"
				};
				axios.post("/admin/companies/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			searchCEP(){
				axios.get("https://viacep.com.br/ws/"+this.cep+"/json").then((res) => {
					this.address = res.logradouro;
					this.neighborhood = res.bairro;
					this.city = res.localidade;
					this.state = res.uf;
				});
			},
			validateCities(){
        let _this = this;
        _this.cities = [];
        _this.tempCities.map((row) => {
          if (row.Estado == _this.state.ID){
            _this.cities.push(row);
          }
        });
      },
			loadCompanyTypes() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/companytypes").then((res) => {
					_this.companytypes = _this.companytypes.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
